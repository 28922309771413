import EllipseButton from 'Common/EllipseButton';
import SectionTitle from 'Common/SectionTitle';

import CommonContainer from 'EDMS/CommonContainer';
import { useStyles } from "../style";
import CommonDocumentList from 'EDMS/CommonDocumentList';
import { edmsFileHelper } from 'helpers';

export default function DailyCheckReport() {
  const classes = useStyles();
  return (
    <CommonContainer>
      <SectionTitle
        title={`Recently Check Report`}
        extraComponents={
          <div className={classes.buttonsContainer}>
            {/* <div style={{ marginRight: 8 }}>
              <EllipseButton onClick={() => alert('WIP')} label="Export" />
            </div>
            <EllipseButton onClick={() => window.print()} label="Print" /> */}
          </div>
        }
      />
      <CommonDocumentList
        tableType={"edms"}
        getDataApi={edmsFileHelper.getRecentReport}
        responseKey="file_meta"
      />
    </CommonContainer>
  );
}