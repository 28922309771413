import { useState, useEffect } from 'react';
import { useLocation, useHistory, useParams, matchPath } from 'react-router-dom';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { useTheme } from "@material-ui/core/styles";
import { AppBar, Drawer, Toolbar, Divider, CssBaseline, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SearchIcon from '@material-ui/icons/Search';

import NavbarContent from 'NavbarContent';
import ProfileMenu from 'Common/ProfileMenu';
import { edmsRegisterHelper, setupHelper } from 'helpers';
import docTypeHelper from 'helpers/admin/docTypeHelper';
import FilterPage from 'Common/FilterPage';
import { constructFilterObject, convertSearchParamsToObject } from 'utils/filterHelper';

import { tableConfig } from './config';
import { useStyles } from "./style";
import arrayHelper from 'utils/arrayHelper';
import { useSelector } from 'react-redux';
import { selectContractData } from 'redux/slice/contractSlice';

export default function Home({ pageType, currentPage, children, overrideGoBack }) {
  const { contractId, docId, type } = useParams();
  const history = useHistory();
  const location = useLocation();
  const search = location.search;
  const theme = useTheme();
  const classes = useStyles(theme);
  const [open, setOpen] = useState(getNavbarDefaultState());
  const [searchOpen, setSearchOpen] = useState(false);
  const [newTableConfig, setNewTableConfig] = useState([]);
  const [fileOptions, setFileOptions] = useState([]);
  const [filter, setFilter] = useState({});
  const username = localStorage.getItem('username') || sessionStorage.getItem('username');
  const contractData = useSelector(selectContractData);
  const isOverviewPage = matchPath(location.pathname, {
    path: "/contract/:contractId/edms",
    exact: true,
    strict: true
  });

  useEffect(() => {
    async function init() {
      const result = await Promise.all([
        setupHelper.getCompanies({ contractId, orderBy: "name.asc" }),
        docTypeHelper.getdocTypes({ orderBy: "name.asc" }),
        edmsRegisterHelper.getLetterRef({ contractId, orderBy: "letterRef.asc" }),
        edmsRegisterHelper.getAllRootFolders({ contractId, orderBy: "code.asc" }),
      ]);

      if (result[0].error) throw new Error(result[0].error);
      const company = arrayHelper.shapeOptions(result[0].company, "name", "name");
      if (result[1].error) throw new Error(result[1].error);
      const docType = arrayHelper.shapeOptions(result[1].edms_document_type, "name", "name");
      if (result[2].error) throw new Error(result[2].error);
      const letterRef = arrayHelper.shapeOptions(result[2].file_meta.filter((item => item.letterRef)), "letterRef", "letterRef");
      if (result[3].error) throw new Error(result[3].error);
      const options = arrayHelper.shapeFileOptions(result[3].file);
      setFileOptions(options)
      createNewTableConfig({ company, docType, letterRef });
    }
    init();
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname === "/") setOpen(false);
    else if (location.state === "overview") setOpen(true);
  }, [location.pathname]);

  function handleDrawerOpen() {
    setOpen(true);
  }
  function handleDrawerClose() {
    setOpen(false);
  }

  function handleSearchOpen() {
    setSearchOpen(true);
  }
  function handleSearchSubmit(form) {
    setSearchOpen(false);
    const filterObject = constructFilterObject(form, true);
    const filterForm = constructFilterObject(form);
    setFilter(filterForm);
    let searchString = queryString.stringify(filterObject);
    // history.push({ pathname: `/contract/${contractId}/edms/search`, search: searchString })
    window.open(`/contract/${contractId}/edms/search?${searchString}`, "searchResult", `popup, width=${window.screen.width}, height=${window.screen.width}`)
  }
  function getCurrentFilter() {
    if (!search) return {};
    let filterObject = convertSearchParamsToObject(search);
    tableConfig.forEach((item) => {
      if (item.type === "Date") {
        let dateArr = filterObject[item.id]?.split(".");
        if (!dateArr) return;
        filterObject[item.id] = dateArr.map(item => item === "" ? null : item);
      }
    })
    return filterObject;
  }
  function createNewTableConfig(options) {
    const newList = tableConfig.map((item, idx) => {
      if (item.optionKey) return {
        ...item,
        options: options[item.optionKey]
      }
      return item
    })
    setNewTableConfig(newList);
  }
  function goBack() {
    const pathname = location.pathname
    if (overrideGoBack) {
      history.push(overrideGoBack(contractId, type));
      return;
    }
    if (docId && pageType === "document") {
      if (pathname.includes('edms')) history.push(pathname.substring(0, pathname.indexOf('/document')));
      else history.push(`/contract/${contractId}/${type}?tab=docs`);
    } else {
      history.push(`/contract/${contractId}/edms`);
    }
  }

  function getNavbarDefaultState() {
    const isSearch = matchPath(location.pathname, {
      path: "/contract/:contractId/edms/search",
      exact: true,
      strict: true
    });
    return isSearch ? false : true;
  }

  return (
    <div className={classes.root}>
      {pageType !== "document" &&
        <>
          <CssBaseline />

          <AppBar elevation={0} position="fixed" className={open ? `${classes.appBarShift} ${classes.appBar}` : `${classes.appBar}`}>
            <Toolbar className={classes.toolbar}>
              <div className={classes.rowItem}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  className={`${classes.menuButton} ${open ?? classes.hide}`}
                >
                  <MenuIcon />
                </IconButton>
                EDMS {contractData?.contractNo}
              </div>
              <div className={classes.rowItem}>
                <IconButton
                  onClick={handleSearchOpen}
                  className={classes.menuButton}
                >
                  <SearchIcon />
                </IconButton>
                <Divider className={classes.divider} orientation="vertical" flexItem />
                <ProfileMenu name={username ?? "User"} />
              </div>
            </Toolbar>
          </AppBar>

          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.drawerHeader}>
              <div>{!isOverviewPage && <div style={{ marginLeft: '5px', cursor: "pointer", fontSize: "20px" }} onClick={goBack}>Go Back</div>}</div>
              <IconButton onClick={handleDrawerClose} className={classes.icon}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </div>
            <Divider />
            <NavbarContent pageType={pageType} overrideCurrentPageName={currentPage} overrideGoBack={overrideGoBack} />
          </Drawer>
        </>
      }

      {newTableConfig && <Drawer
        anchor="right"
        open={searchOpen}
        onClose={() => setSearchOpen(false)}
        classes={{
          paperAnchorRight: classes.drawerRight
        }}
      >
        <FilterPage
          fieldsData={newTableConfig}
          setFilter={handleSearchSubmit} {...{ filter: filter, setUseFilter: setSearchOpen, setShowFilter: setSearchOpen }}
          isEdmsSearch
          fileOptions={fileOptions}
        />
      </Drawer>}
      <main className={open ? `${classes.content} ${classes.contentShift}` : classes.content}>
        {pageType !== "document" && <div className={classes.drawerHeader} />}
        {children}
      </main>
    </div>
  );
}

Home.propTypes = {
  children: PropTypes.any,
};
