import { useForm } from 'react-hook-form';

import SectionTitle from 'Common/SectionTitle';
import CommonContainer from 'EDMS/CommonContainer';
import { authHelper } from 'helpers';
import { setNotification } from 'redux/slice/notificationSlice';

import { useStyles } from "Setup/style";
import { useDispatch } from 'react-redux';
import fieldHelper from 'utils/fieldHelper';
import { Grid } from '@material-ui/core';
import RoundedButton from 'Common/RoundedButton';

export default function FoliosSetup() {
  const classes = useStyles();
  const dispatch = useDispatch()

  const { register, handleSubmit, control, formState: { errors }, clearErrors, setError, reset } = useForm({
  });

  async function onSubmit(data) {
    try {
      if (data.newPassword !== data.confirmNewPassword) setError("confirmNewPassword", { type: "custom", message: "Password do not match" })
      const result = await authHelper.changePassword({
        currPassword: data.currPassword,
        newPassword: data.newPassword
      })
      console.log(result)
      if (result.error) throw new Error(result.error)
      reset({
        currPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      })
      dispatch(setNotification({ type: "success", message: "Password Changed" }));
    } catch (e) {
      dispatch(setNotification({ type: "error", message: e.message }));
      console.log(e)
    }
  }

  function onError(data) {
    dispatch(setNotification({ type: "error", message: "Some field(s) are missing." }))
  }


  return (
    <CommonContainer>
      <SectionTitle title={`Change Password`} />
      <div className={classes.body}>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <Grid container spacing={3}>
            {fieldHelper.renderField(
              { label: "Current Password", name: "currPassword", isRequired: true, type: "string", inputType: "password" },
              0, register, control, errors, { size: 12, mdSize: 6 })}
            <Grid item size={6} />
            {fieldHelper.renderField(
              { label: "New Password", name: "newPassword", isRequired: true, type: "string", inputType: "password" },
              1, register, control, errors, { size: 12, mdSize: 6 })}
            <Grid item size={6} />
            {fieldHelper.renderField(
              { label: "Verify New Password", name: "confirmNewPassword", isRequired: true, type: "string", inputType: "password" },
              2, register, control, errors, { size: 12, mdSize: 6 })}
            <Grid container item>
              <RoundedButton backgroundColor="#70BAD3" type="submit">Save</RoundedButton>
            </Grid>
          </Grid>


        </form>
      </div>
    </CommonContainer>
  );
}