import { useState } from 'react';
import { useTheme } from "@material-ui/core";
import moment from 'moment';
import { useParams } from 'react-router-dom';
import AttachFileIcon from '@material-ui/icons/AttachFile';

import ConfirmButton from 'Common/ConfirmButton';
import RoundedButton from "Common/RoundedButton";

import { useStyles } from "./style";
import CustTextField from 'Common/TextField';
import edmsRegisterHelper from 'helpers/edmsRegisterHelper';
import CustSelect from 'Common/Select';
import { authHelper } from 'helpers';
import ReassignForm from './ReassignForm';
import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserPermission } from 'redux/slice/permissionSlice';
import InboxAttachButton from 'Common/InboxAttachButton';
import pdfHelper from 'utils/pdfHelper';
import { setLoading } from 'redux/slice/loadingSlice';


const statusList = [
  {
    label: "Outstanding",
    value: "Outstanding"
  },
  {
    label: "Processing",
    value: "Processing"
  },
  {
    label: "Finished",
    value: "Finished"
  },
]

function MessageCell({ data, reload }) {
  const classes = useStyles();
  const theme = useTheme();
  const { type } = useParams();
  const dispatch = useDispatch();
  const [isExpand, setExpand] = useState(false);
  const [showReplyBox, setShowReplyBox] = useState(false);
  const [showReassign, setShowReassign] = useState(false);
  const [message, setMessage] = useState();
  const [status, setStatus] = useState(data.status ?? "");
  const [uploadFiles, setUploadFiles] = useState();
  const userPermission = useSelector(selectUserPermission);

  function init() {
    setStatus(data.status);
    setMessage("");
    setShowReplyBox(false);
    setShowReassign(false);
    setExpand(false);
    setUploadFiles();
  }

  async function openAttachment(doc) {
    try {
      dispatch(setLoading(true));
      await pdfHelper.openDocAsDownload(doc.documentId, doc.filename);
      dispatch(setLoading(false));
    } catch (e) {
      console.log(e);
      dispatch(setLoading(false));
    }
  }

  function renderMessage(idx) {
    const currentMsg = data.messages[idx];
    let assignedToArr, assignedUsers, assignedGroups, forAction, forInfo;

    if (type === "action" || type === "all-actions") {
      assignedUsers = Array.isArray(currentMsg.forActionUsers) ? currentMsg.forActionUsers : [];
      assignedGroups = Array.isArray(currentMsg.forActionGroups) ? currentMsg.forActionGroups : [];
      assignedToArr = assignedUsers.concat(assignedGroups);
    }
    if (type === "info") {
      assignedUsers = Array.isArray(currentMsg.forInfoUsers) ? currentMsg.forInfoUsers : [];
      assignedGroups = Array.isArray(currentMsg.forInfoGroups) ? currentMsg.forInfoGroups : [];
      assignedToArr = assignedUsers.concat(assignedGroups);
    }
    if (type === "sent") {
      const infoUser = Array.isArray(currentMsg.forInfoUsers) ? currentMsg.forInfoUsers : [];
      const infoGroup = Array.isArray(currentMsg.forInfoGroups) ? currentMsg.forInfoGroups : [];
      const actionUser = Array.isArray(currentMsg.forActionUsers) ? currentMsg.forActionUsers : [];
      const actionGroup = Array.isArray(currentMsg.forActionGroups) ? currentMsg.forActionGroups : [];
      forInfo = infoUser.concat(infoGroup);
      forAction = actionUser.concat(actionGroup);
    }

    return <>
      <div className={classes.latestMessage}>
        <span style={{ marginRight: "4px", fontWeight: 600 }}>{currentMsg.onBehalfOf}</span>
        <span>{currentMsg.content}</span>
      </div>
      <div style={{ color: "gray", display: "flex" }}>
        <span>{moment(currentMsg.createdAt).format('yyyy-MM-DD HH:mm')}</span>
        {(currentMsg?.documents && currentMsg?.documents?.[0]) &&
          <div className={classes.fileInfo} style={{ color: "white", cursor: "pointer" }} onClick={() => openAttachment(currentMsg.documents[0])} >
            <AttachFileIcon style={{ width: 15, height: 15 }} />
          </div>
        }
      </div>
      <div style={{ marginBottom: 8 }}>
        {type !== "sent"
          ? <div>
            <span className={classes.bold}>Assigned to: </span>
            <span style={{ color: "gray" }}>{assignedToArr.join(', ')}</span>
          </div>
          : <div>
            {forAction.length > 0 && <><span className={classes.bold}>For Action: </span> <span style={{ color: "gray" }}>{forAction.join(', ')}</span></>}
            <div></div>
            {forInfo.length > 0 && <><span className={classes.bold}>For Info: </span> <span style={{ color: "gray" }}>{forInfo.join(', ')}</span></>}
          </div>
        }
      </div>
    </>
  }

  function renderAllMessage() {
    return data.messages.map((message, idx) => {
      if (idx === 0) return;
      return <Fragment key={idx}>{renderMessage(idx)}</Fragment>;
    })
  }

  function handleNRR() {
    updateSeenStatus();
    setShowReassign(false);
    setShowReplyBox(true);
    setStatus("Finished");
    setMessage("Finished By NRR");
  }

  function handleReply() {
    updateSeenStatus();
    setShowReassign(false);
    setShowReplyBox(true);
    setStatus("");
    setMessage("");
  }

  function handleReassign() {
    // getUserGroup();
    updateSeenStatus();
    setShowReplyBox(false);
    setShowReassign(true);
  }

  function handleCancel() {
    init();
  }

  function reloadAfterReassign() {
    reload();
    init();
  }

  async function updateSeenStatus() {
    try {
      if (!data.seen && type !== "all-actions") {
        await edmsRegisterHelper.markAsRead({ actionRequestId: Number(data.actionRequestId), seen: true });
        reload();
      }
    } catch (e) {
      console.log(e);
    }
  }

  async function handleSubmit() {
    try {
      const userInfo = await authHelper.getMyInfo();
      if (userInfo.error || userInfo?.user?.length < 1) throw new Error({ message: "User Info not Found" });
      const requestBody = {
        onBehalfOf: `${userInfo.user[0].firstName} ${userInfo.user[0].lastName}`,
        actionRequestId: data.actionRequestId,
        status: status,
        content: message,
      };
      const result = await edmsRegisterHelper.postMessage(requestBody);
      if(uploadFiles) {
        await edmsRegisterHelper.postMessageAttachment({ actionRequestMessageId: result.action_request_message.id, file: uploadFiles[0] })
      }
      init();
      reload();
    } catch (e) {
      console.log(e);
    }
  }

  function reloadAfterReassign() {
    init();
    reload();
  }

  function isRenderButtonGroup() {
    if (data?.status !== "Finished" && !showReplyBox && !showReassign) {
      if (type !== "all-actions" || (userPermission?.edms?.userType.includes("admin"))) {
        return true;
      }
    }
    return false;
  }

  return (
    <>
      {data?.messages?.[0] &&
        <div onClick={() => setExpand(!isExpand)} style={{ cursor: "pointer" }}>
          {renderMessage(0)}
        </div>
      }
      {(isExpand || showReplyBox || showReassign) && <div className={classes.oldMessage}>
        {data?.messages?.[1] && renderAllMessage()}
      </div>
      }
      {isRenderButtonGroup() &&
        <div className={classes.buttonGroup}>
          <RoundedButton width={100} margin="4px" backgroundColor={theme.palette.primaryLight} onClick={handleReassign}>Re-assign</RoundedButton>
          {(type === "action") &&
            <>
              <RoundedButton width={80} margin="4px" backgroundColor={theme.palette.primaryLight} onClick={handleNRR}>NRR</RoundedButton>
              <RoundedButton width={80} margin="4px" backgroundColor={theme.palette.primaryLight} onClick={handleReply}>Reply</RoundedButton>
            </>
          }
        </div>}

      {showReplyBox &&
        <>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <span style={{ fontWeight: 600, marginRight: "8px" }}>To:</span><CustTextField disabled value={data.messages[0].fromUser} hideLabel />
          </div>
          <div className={classes.bold}>Message:</div>
          <CustTextField hideLabel rows={3} handleChange={(e) => setMessage(e.target.value)} value={message}></CustTextField>

          <div className={classes.flexColumnStart}>
            <div style={{ minWidth: "175px", display: "flex", alignItems: "center" }}>
              <div className={classes.bold} style={{ padding: "13px 0 7px", marginRight: 4, whiteSpace: "nowrap" }}>Status:</div>
              <CustSelect value={status} options={statusList} handleChange={(e) => setStatus(e.target.value)}></CustSelect>
            </div>
            {(uploadFiles && uploadFiles?.length > 0) &&
              <div style={{ margin: "4px 0" }}>
                <div className={classes.fileInfo}>
                  <AttachFileIcon style={{ width: 15, height: 15 }} />
                  {uploadFiles[0].name}
                </div>
              </div>
            }
            <div>
              <ConfirmButton width={70} onClick={handleSubmit} backgroundColor={theme.palette.primaryLight} message={"Confirm to Submit?"}>Submit</ConfirmButton>
              <InboxAttachButton width={100} setFiles={setUploadFiles} styles={{ marginLeft: 8, display: "inline-block" }}>Attach File</InboxAttachButton>
              <RoundedButton width={70} margin={"8px"} backgroundColor={theme.palette.primaryLight} onClick={handleCancel}>Cancel</RoundedButton>
            </div>
          </div>
        </>
      }
      {showReassign && <ReassignForm type={type} reload={reloadAfterReassign} rowData={data} setShowReassign={setShowReassign} />}
    </>
  );
}

export default MessageCell;