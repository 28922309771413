import arrayHelper from "./arrayHelper";
import { edmsRegisterHelper } from "helpers";

export async function findFolderId(folderName, isRoot, fileOptions, subFolderOptions, fileNumberInput, searchingId) {
  if (!folderName) return
  const lookupOptions = isRoot ? fileOptions : subFolderOptions;
  const searchResult = lookupOptions.find(item => item.label === folderName);
  const prefix = folderName + (isRoot ? "/" : ".");
  if (searchingId === searchResult?.value) return;
  if (searchResult) return { subFolders: await getSubFoldersById(searchResult.value, prefix, fileNumberInput), fileId: searchResult.value };

}
async function getSubFoldersById(id, prefix, fileNumberInput) {
  const result = await edmsRegisterHelper.getSubFolders({ fileId: id });
  const options = arrayHelper.shapeFileOptions(result.file.children, prefix ?? fileNumberInput);
  return options;
}

export function constructActionRequestArr(data) {
  let actionRequestForArr = [];
  if (data.forAction) {
    data?.forAction.forEach(item => {
      actionRequestForArr.push({
        [`${item.type.toLowerCase()}Id`]: item.id,
        for: "Action",
        urgent: data.urgent,
      })
    })
  }
  if (data.forInfo) {
    data?.forInfo.forEach(item => {
      actionRequestForArr.push({
        [`${item.type.toLowerCase()}Id`]: item.id,
        for: "Information",
        urgent: data.urgent,
      })
    })
  }
  return actionRequestForArr;
}