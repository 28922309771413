import { useTheme, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useState } from "react";

import SectionTitle from "Common/SectionTitle";
import { useStyles } from "Document/DocumentDecrypted/style";
import MultipleSelectList from "Common/MultipleSelectList";
import { letterRefFieldConfig } from "EDMSRegisterDocument/EditDocumentForm/config";

export default function InboxRelatedDoc({ data, reload }) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [open, setOpen] = useState(true);

  async function openDoc(id) {
    try {

    } catch (e) {
      console.log(e);
    }
  }

  return (
    <Accordion className={classes.details} expanded={open}>
      <AccordionSummary
        onClick={() => setOpen(!open)}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="action-history-content"
        id="action-history-header"
      >
        <SectionTitle title={"Related Document"} fontStyle={theme.typography.h4} />
      </AccordionSummary>
      <AccordionDetails style={{ flexDirection: "column" }}>
        {data && <MultipleSelectList config={letterRefFieldConfig} data={data} readOnly />}
      </AccordionDetails>
    </Accordion>
  );
}