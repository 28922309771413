import { riscStatusOptions, sourceOptions } from "config/selectOptions";

export const riscConfig = [
  { id: "fileId", label: "File ID" },
  { id: "submissionDatetime", label: "Submission Date", isDate: true },
  { id: "updatedAt", label: "Last Updated", isDate: true },
  { id: "plannedSurveyInspectionDatetime", label: "Planned Inspection Date", isDate: true },
  { id: "actualSurveyInspectionDatetime", label: "Actual Inspection Date", isDate: true },
  {
    id: "fileStatus", label: "Status", type: "Select", options: riscStatusOptions,
  },
  { id: "fileSource", label: "Source", type: "Select", options: sourceOptions },
  // { id: "workToBeInspected", label: "Description", },
  { id: "workLocation", label: "Locations" },
  { id: null, label: "" },
  { id: null, label: "" }
];