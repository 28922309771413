export const tableConfig = [
  {
    id: "placeholder",
  },
  {
    id: "name",
    label: "Document Type",
  },
  {
    id: "code",
    label: "Code",
  },
  {
    id: "dueDays",
    label: "Action Request Due Days",
  },
  {
    id: "forAction",
    label: "For Action",
    render: (item) => {
      if (!item.forAction || item?.forAction?.length === 0) return;
      const infoNameArr = item?.forAction.map(item => item.label)
      return infoNameArr.join(", ");
    },
  },
  {
    id: "forInfo",
    label: "For Info",
    render: (item) => {
      if (!item.forInfo || item?.forInfo?.length === 0) return;
      const infoNameArr = item?.forInfo.map(item => item.label)
      return infoNameArr.join(", ");
    },
  },
  {
    id: "description",
    label: "Description",
  },
  {
    id: "placeholder",
  },
  {
    id: "placeholder",
  }
];