import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  latestMessage: {
    marginBottom: "4px",
  },
  buttonGroup: {
    display: "flex",
    flexDirection: "row",
    textAlign: "end",
  },
  oldMessage: {
    marginTop: "8px",
  },
  bold: {
    fontWeight: 600
  },
  flexColumnStart: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start  ",
  },
  mr4: {
    marginRight: 4,
  },
  fileInfo: {
    display: "flex",
    alignItems: "center",
  }
}));