import axios from './apiHelper';

const permissionHelper = {
  getUsers: async (variables) => {
    try {
      const result = await axios.get(`/users`, { params: { ...variables, status: "active" } });

      if (!result.data) {
        throw new Error(`Fail to get users`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getGroups: async (variables) => {
    try {
      const result = await axios.get(`/groups`, { params: variables });

      if (!result.data) {
        throw new Error(`Fail to get groups`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getPermissions: async (variables) => {
    try {
      const result = await axios.get(`/permissions`, { params: variables });

      if (!result.data) {
        throw new Error(`Fail to get permission`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getFileUser: async (variables) => {
    try {
      const result = await axios.get(`/file-users`, { params: variables });

      if (!result.data) {
        throw new Error(`Fail to get file-user`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getFileGroup: async (variables) => {
    try {
      const result = await axios.get(`/file-groups`, { params: variables });

      if (!result.data) {
        throw new Error(`Fail to get file-group`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  postFileUser: async (variables) => {
    try {
      const result = await axios.post(`/file-user`, variables);

      if (!result.data) {
        throw new Error(`Fail to post file-user`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  postFileGroup: async (variables) => {
    try {
      const result = await axios.post(`/file-group`, variables);

      if (!result.data) {
        throw new Error(`Fail to post file-group`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  postRiskUser: async (variables) => {
    try {
      const result = await axios.post(`/risk-user`, variables);

      if (!result.data) {
        throw new Error(`Fail to post risk-user`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  postRiskGroup: async (variables) => {
    try {
      const result = await axios.post(`/risk-group`, variables);

      if (!result.data) {
        throw new Error(`Fail to post risk-group`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  checkPermission: async (variable) => {
    try {
      const result = await axios.post(`/permission/check`, variable);

      if (!result.data) {
        throw new Error(`Fail to check permission`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400) return { error: e.response.data.message };
        if (e.response.status === 500) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  checkRiskPermission: async () => {
    try {
      const result = await axios.get(`/risk-permission`);

      if (!result.data) {
        throw new Error(`Fail to check risk permission`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400) return { error: e.response.data.message };
        if (e.response.status === 500) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getPermission: async () => {
    try {
      const result = await axios.get(`/permissions?type=file`);

      if (!result.data) {
        throw new Error(`Fail to get permission`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400) return { error: e.response.data.message };
        if (e.response.status === 500) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getFolderPermissionList: async (variables) => {
    try {
      const result = await axios.get(`/files-for-permission-list`, { params: variables });

      if (!result.data) {
        throw new Error(`Fail to get folder permission list`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400) return { error: e.response.data.message };
        if (e.response.status === 500) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  checkUserAccessPermission: async (contractId) => {
    try {
      const result = await axios.get(`/get-user-permissions`, { params: { contractId } });

      if (!result.data) {
        throw new Error(`Fail to get user access permission`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
}

export default permissionHelper;