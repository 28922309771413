import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useState, useEffect } from 'react';
import { Table, TableBody, TableRow, TableCell, TableHead, Popover } from '@material-ui/core';

import { setNotification } from 'redux/slice/notificationSlice';
import CommonContainer from 'EDMS/CommonContainer';
import EllipseButton from 'Common/EllipseButton';
import RoundedButton from 'Common/RoundedButton';
import SectionTitle from 'Common/SectionTitle';
import CustTableCell from "Common/TableCell";
import Modal from 'Common/Modal';
import { edmsRegisterHelper, setupHelper } from 'helpers';

import { titleLookUpList, defaultTableConfig, logNumberTableConfig, userDefinedFiledTableConfig } from './config';
import { useStyles } from "../style";
import DropdownEditModal from './DropdownEditModal';
import docTypeHelper from 'helpers/admin/docTypeHelper';

export default function DropdownOptionsList() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { optionType, contractId } = useParams();
  const [modalOpen, setModalOpen] = useState({ open: false, type: null, data: {} });
  const [data, setData] = useState([]);
  const [tableConfig, setTableConfig] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [target, setTarget] = useState(null);
  const [docTypeList, setDocTypeList] = useState([]);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    if (optionType) {
      determineGetApi();
      determineTableConfig();
    }
  }, [optionType])

  function determineTableConfig() {
    switch (optionType) {
      case "log-number":
        setTableConfig(logNumberTableConfig);
        break;
      case "user-defined-field":
        setTableConfig(userDefinedFiledTableConfig);
        break;
      default:
        setTableConfig(defaultTableConfig);
    }
  }

  const handlePopoverClick = (event, row) => {
    setTarget(row);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setTarget(null);
    setAnchorEl(null);
  };


  async function determineGetApi() {
    let result;
    switch (optionType) {
      case "company":
        result = await setupHelper.getCompanies({ contractId });
        setData(result?.company);
        break;
      case "person":
        result = await setupHelper.getPersons({ contractId });
        setData(result?.person);
        break;
      case "log-number":
        result = await setupHelper.getLogNumbers({ orderBy: "id.asc" });
        setData(result?.setup_log_number);
        break;
      case "user-defined-field":
        result = await setupHelper.getExtraFields({ contractId });
        setData(result?.file_meta_extra_field);
        const docTypeResult = await docTypeHelper.getdocTypes({ contractId, orderBy: "name.asc" });
        setDocTypeList(docTypeResult?.edms_document_type);
        break
      default:
        setData([]);
    }
  }
  async function handleDelete(item) {
    try {
      let result;
      switch (optionType) {
        case "company":
          result = await setupHelper.deleteCompany(item.id);
          break;
        case "person":
          result = await setupHelper.deletePerson(item.id);
          break;
        case "user-defined-field":
          result = await setupHelper.deleteExtraField(item.id);
          break;
        default:
          setData([]);
      }
      if (result.error) throw result.error;
      handlePopoverClose();
      determineGetApi();
    } catch (error) {
      console.log(error);
      handlePopoverClose();
      if (error) dispatch(setNotification({ message: error, type: "error" }));
    }
  }

  function getTitle() {
    const target = titleLookUpList[optionType];
    if (target) return target;
    return optionType;
  }
  function renderExtraButton() {
    if (optionType === "log-number") return;
    return (
      <div className={classes.buttonsContainer}>
        <EllipseButton onClick={() => setModalOpen({ open: true, type: "add", data: {} })} label="Add New" />
      </div>
    );
  }

  function renderTableHeader() {
    return (
      <TableHead>
        <TableRow>
          {tableConfig.map((column, idx) =>
            <TableCell className={classes.headerCell} key={idx}>{column.label}</TableCell>
          )}
        </TableRow>
      </TableHead>
    );
  }

  function renderTableBody() {
    return (
      <TableBody>
        {data.map((row, idx) => {
          return (
            <TableRow key={idx}>
              {tableConfig.filter(item => item.id !== "placeholder").map((col, colIdx) =>
                <CustTableCell key={colIdx} style={idx == 1 && { textTransform: "capitalize" }}>{col.render ? col.render(row) : row[col.id]}</CustTableCell>
              )}
              <CustTableCell
                icon={<EditIcon />}
                onClick={() => setModalOpen({ open: true, type: "edit", data: row })}
              />
              {optionType !== "log-number" &&
                <CustTableCell
                  icon={<DeleteIcon />}
                  onClick={(event) => handlePopoverClick(event, row)}
                />
              }
            </TableRow>
          );
        })}
      </TableBody>
    );
  }

  return (
    <CommonContainer>
      <SectionTitle
        title={`${getTitle()} Setup`}
        extraComponents={renderExtraButton()}
      />
      <div className={classes.body}>
        <Table>
          {tableConfig && renderTableHeader()}
          {data && renderTableBody()}
        </Table>
      </div>
      <Modal open={modalOpen.open} handleClose={() => setModalOpen(false)}>
        <DropdownEditModal
          handleClose={() => setModalOpen({ ...modalOpen, open: false })}
          reload={determineGetApi}
          defaultData={modalOpen.data}
          type={modalOpen.type}
          title={getTitle()}
          docTypeList={docTypeList}
        />
      </Modal>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className={classes.confirmContainer}>
          <div style={{ marginBottom: 8 }}>Confirm to Delete {target && target.name}?</div>
          <RoundedButton onClick={() => handleDelete(target)} backgroundColor="#70BAD3">Yes</RoundedButton>
        </div>
      </Popover>
    </CommonContainer>
  );
}