import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useState, useEffect } from 'react';
import { Table, TableBody, TableRow, TableCell, TableHead, TableSortLabel, Popover } from '@material-ui/core';

import { setNotification } from 'redux/slice/notificationSlice';
import { setLoading } from 'redux/slice/loadingSlice';
import docTypeHelper from 'helpers/admin/docTypeHelper';
import { permissionHelper } from 'helpers';
import EllipseButton from 'Common/EllipseButton';
import SectionTitle from 'Common/SectionTitle';
import CustTableCell from "Common/TableCell";
import Modal from 'Common/Modal';
import RoundedButton from 'Common/RoundedButton';
import CommonContainer from 'EDMS/CommonContainer';

import { tableConfig } from './config';
import { useStyles } from "../style";
import DocumentTypeSetupForm from './DocumentTypeSetupForm';
import arrayHelper from 'utils/arrayHelper';


export default function DocumentTypeSetup() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { contractId } = useParams();
  const [sort, setSort] = useState({ by: "id", direction: "asc" });
  const [data, setData] = useState([]);
  const [modal, setModal] = useState({ open: false, type: null, data: {} });
  const [anchorEl, setAnchorEl] = useState(null);
  const [target, setTarget] = useState(null);
  const [userList, setUserList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [userNGroupList, setUserNGroup] = useState([]);

  useEffect(() => {
    async function getUserGroupInfo() {
      try {
        const result = await Promise.all([
          permissionHelper.getUsers({ orderBy: "firstName.asc" }),
          permissionHelper.getGroups({ orderBy: "firstName.asc" }),
        ]);
        const UList = result[0].user.map(v => ({ id: v.id, type: "User", label: `${v.firstName} ${v.lastName}` }));
        const GList = result[1].group.map(v => ({ id: v.id, type: "Group", label: v.name }));
        setUserList(UList);
        setGroupList(GList);
        const combinedArr = UList.concat(GList);
        setUserNGroup(combinedArr);
      } catch (e) {
        console.log(e);
      }
    }
    getUserGroupInfo();
  }, []);

  useEffect(() => {
    if (userList.length > 0 && groupList.length > 0) loadData();
  }, [sort, userNGroupList])

  async function loadData() {
    try {
      dispatch(setLoading(true));
      const result = await docTypeHelper.getdocTypes({ contractId, orderBy: `${sort.by}.${sort.direction}` });
      if (result.error) throw result.error;
      const processedResult = constructActionInfoObj(result.edms_document_type);
      setData(processedResult);
      dispatch(setLoading(false));
    } catch (error) {
      console.log(error);
      dispatch(setLoading(false));
    }
  }

  function constructActionInfoObj(data) {
    let processedArr = [];
    data.forEach(item => {
      processedArr.push({
        ...item,
        forAction: arrayHelper.extractUserGroupInfo(item?.defaultRecipientList?.action, userList, groupList) ?? [],
        forInfo: arrayHelper.extractUserGroupInfo(item?.defaultRecipientList?.information, userList, groupList) ?? [],
      })
    })
    return processedArr;
  }

  function handleSort(property) {
    const isAsc = sort.by === property && sort.direction === 'asc';
    const direction = isAsc ? 'desc' : 'asc';
    setSort({ by: property, direction: direction });
  }

  const handlePopoverClick = (event, row) => {
    setTarget(row);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setTarget(null);
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  async function handleDelete(item) {
    try {
      const result = await docTypeHelper.deletedocType(item.id);
      if (result.error) throw result.error;
      handlePopoverClose();
      loadData();
    } catch (error) {
      console.log(error);
      handlePopoverClose();
      if (error) dispatch(setNotification({ message: error, type: "error" }));
    }
  }

  function renderTableHeader() {
    return (
      <TableHead>
        <TableRow>
          {tableConfig.map((column, idx) =>
            column.id !== "placeholder" ?
              <TableCell className={classes.headerCell} key={idx}>
                <TableSortLabel
                  active={sort.by === column.id}
                  direction={sort.by === column.id ? sort.direction : 'asc'}
                  onClick={() => handleSort(column.id)}
                >
                  {column.label}
                </TableSortLabel>
              </TableCell>
              : <TableCell key={idx} />
          )}
        </TableRow>
      </TableHead>
    );
  }
  function renderTableBody() {
    return (
      <TableBody>
        {data.map((row, idx) => {
          return (
            <TableRow key={idx}>
              <CustTableCell>{idx + 1}</CustTableCell>
              {tableConfig.filter(item => item.id !== "placeholder").map((col, colIdx) =>
                <CustTableCell key={colIdx}>{col.render ? col.render(row) : row[col.id]}</CustTableCell>
              )}
              <CustTableCell
                icon={<EditIcon />}
                onClick={() => setModal({ open: true, type: "edit", data: constructRowData(row) })}
              />
              <CustTableCell
                icon={<DeleteIcon />}
                onClick={(event) => handlePopoverClick(event, row)}
              />
            </TableRow>
          );
        })}
      </TableBody>
    );
  }

  function constructRowData(row) {
    let rowObject = { ...row };

    return row;
  }

  return (
    <CommonContainer>
      <SectionTitle
        title={`Document Type Setup`}
        extraComponents={
          <div className={classes.buttonsContainer}>
            <EllipseButton onClick={() => setModal({ open: true, type: "add", data: null })} label="Add New" />
          </div>
        }
      />
      <div className={classes.body}>
        <Table>
          {renderTableHeader()}
          {renderTableBody()}
        </Table>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className={classes.confirmContainer}>
          <div style={{ marginBottom: 8 }}>Confirm to Delete {target && target.name}?</div>
          <RoundedButton onClick={() => handleDelete(target)} backgroundColor="#70BAD3">Yes</RoundedButton>
        </div>
      </Popover>
      <Modal open={modal.open} handleClose={() => setModal(false)}>
        <DocumentTypeSetupForm userNGroup={userNGroupList} handleClose={() => setModal({ ...modal, open: false })} reload={loadData} data={modal.data} type={modal.type} />
      </Modal>
    </CommonContainer>
  );
}