export const documentLinkList = [
  {
    name: "Basic Information",
    to: (pathname) => {
      return `${pathname}?tab=basic-info`
    },
    level: 1,
    path: "basic-info"
  },
  {
    name: "History Log",
    to: (pathname) => {
      return `${pathname}?tab=history-log`
    },
    level: 4,
    path: "history-log"
  },
  {
    name: "Attachments",
    to: (pathname) => {
      return `${pathname}?tab=attachments`
    },
    level: 3,
    path: "attachments"
  },
  {
    name: "Related Documents",
    to: (pathname) => {
      return `${pathname}?tab=related-documents`
    },
    level: 2,
    path: "related-documents"
  }
]

const edmsDocTypeOptions = [
  {
    name: "Audit",
    key: "audit",
    type: "search",
    linkTo: (contractId) => {
      return `/contract/${contractId}/edms/audit`
    }
  },
  {
    name: "CE",
    key: "ce",
    type: "search",
    linkTo: (contractId) => {
      return `/contract/${contractId}/edms/ce`
    }
  },
  {
    name: "CP",
    key: "cp",
    type: "search",
    linkTo: (contractId) => {
      return `/contract/${contractId}/edms/cp`
    },
  },
  {
    name: "CSF",
    key: "csf",
    type: "search",
    linkTo: (contractId) => {
      return `/contract/${contractId}/edms/csf`
    }
  },
  {
    name: "EWN",
    key: "ewn",
    type: "search",
    linkTo: (contractId) => {
      return `/contract/${contractId}/edms/ewn`
    },
  },
  {
    name: "MSF",
    key: "msf",
    type: "search",
    linkTo: (contractId) => {
      return `/contract/${contractId}/edms/msf`
    },
  },
  {
    name: "PMI",
    key: "pmi",
    type: "search",
    linkTo: (contractId) => {
      return `/contract/${contractId}/edms/pmi`
    },
  },
  {
    name: "Quotation",
    key: "quotation",
    type: "search",
    linkTo: (contractId) => {
      return `/contract/${contractId}/edms/quotation`
    },
  },
  {
    name: "RFI",
    key: "rfi",
    type: "search",
    linkTo: (contractId) => {
      return `/contract/${contractId}/edms/rfi`
    },
  },
  {
    name: "RR",
    key: "rr",
    type: "search",
    linkTo: (contractId) => {
      return `/contract/${contractId}/edms/rr`
    },
  },
];

const administrationOptions = [
  {
    name: "User Management",
    key: "userManagementUrl",
    type: "external",
    linkTo: (link) => {
      return link;
    }
  },
  {
    name: "Group Management",
    key: "groupManagementUrl",
    type: "external",
    linkTo: (link) => {
      return link;
    }
  },
  {
    name: "Document Type Setup",
    key: "document-type",
    linkTo: (contractId) => {
      return `/contract/${contractId}/setup/document-type`
    },
  },
  {
    name: "Dropdown Option Setup",
    key: "dropdown-option",
    linkTo: (contractId) => {
      return `/contract/${contractId}/setup/dropdown-option`
    },
  },
  {
    name: "Log Number Setup",
    key: "log-number",
    linkTo: (contractId) => {
      return `/contract/${contractId}/setup/log-number`
    },
  },
  {
    name: "User Defined Field Setup",
    key: "user-defined-field",
    linkTo: (contractId) => {
      return `/contract/${contractId}/setup/user-defined-field`
    },
  },
  {
    name: "Filing List",
    key: "folder-list",
    linkTo: (contractId) => {
      return `/contract/${contractId}/edms/folder-list`
    },
  },
  {
    name: "Folder Security Report",
    key: "folder-security-report",
    type: "blank",
    linkTo: (contractId) => {
      return `/contract/${contractId}/edms/folder-security-report`
    },
  },
]

const settingsOptions = [
  {
    name: "Change Password",
    key: "changePassword",
    linkTo: (contractId) => {
      return `/contract/${contractId}/settings/change-password`
    },
  },
]

const edmsModuleOptions = [
  {
    name: "New In Document",
    isSub: 1,
    linkTo: (contractId) => {
      return `/contract/${contractId}/edms/register-document/in`
    },
  },
  {
    name: "New Out Document",
    isSub: 1,
    linkTo: (contractId) => {
      return `/contract/${contractId}/edms/register-document/out`
    },
  },
  {
    name: "Update In Document",
    isSub: 1,
    linkTo: (contractId) => {
      return `/contract/${contractId}/edms/register-document/in/update`
    },
  },
  {
    name: "Update Out Document",
    isSub: 1,
    linkTo: (contractId) => {
      return `/contract/${contractId}/edms/register-document/out/update`
    },
  },
  {
    name: "View In Document",
    isSub: 1,
    linkTo: (contractId) => {
      return `/contract/${contractId}/edms/register-document/in/view`
    },
  },
  {
    name: "View Out Document",
    isSub: 1,
    linkTo: (contractId) => {
      return `/contract/${contractId}/edms/register-document/out/view`
    },
  },
  {
    name: "Unscanned Report",
    isSub: 1,
    linkTo: (contractId) => {
      return `/contract/${contractId}/report/unscanned-report`
    },
  },
  {
    name: "Recently Check Report",
    isSub: 1,
    linkTo: (contractId) => {
      return `/contract/${contractId}/report/recently-check-report`
    },

  },
  // {
  //   name: "Acknowledge Receive",
  //   isSub: 1,
  //   linkTo: (contractId) => {
  //     return `/contract/${contractId}/report/acknowledge-receive`
  //   },
  // },
];


export const navOptions = [
  {
    name: "Overview",
    key: "overview",
    linkTo: (contractId) => {
      return `/contract/${contractId}/edms`
    },
  },
  {
    name: "For Action",
    key: "action",
    renderExtra: (item) => {
      return `(${item?.unseenCount ?? "-"})(${item?.unRepliedCount ?? "-"})(${item?.overdueCount ?? "-"})`
    },
    linkTo: (contractId) => {
      return `/contract/${contractId}/edms/inbox/action`
    },
  },
  {
    name: "For Info",
    key: "info",
    renderExtra: (item) => {
      return `(${item?.unseenCount ?? "-"})`
    },
    linkTo: (contractId) => {
      return `/contract/${contractId}/edms/inbox/info`
    },
  },
  {
    name: "Sent",
    linkTo: (contractId) => {
      return `/contract/${contractId}/edms/inbox/sent`
    },
  },
  {
    name: "All Actions",
    key: "allActions",
    renderExtra: (item) => {
      return `(${item?.unRepliedCount ?? "-"})(${item?.overdueCount ?? "-"})`
    },
    linkTo: (contractId) => {
      return `/contract/${contractId}/edms/inbox/all-actions`
    },
    permission: "admin",
  },
  {
    name: "Document Registration",
    key: "document registration",
    type: "group",
    subGroup: edmsModuleOptions,
    permissionKey: 'edms'
  },
  {
    name: "Site Progress Photo",
    key: "photos",
    linkTo: (contractId) => {
      return `/contract/${contractId}/photo`
    },
  },
  {
    name: "Document Library",
    key: "library",
    type: "docLib",
    subGroup: [
      {
        name: "Document Library",
        key: "library",
        linkTo: (contractId, id, code) => {
          return `/contract/${contractId}/edms/folder/${id}-${code}`
        },
      },
    ],
    permissionKey: 'edms'
  },
  {
    name: "Administration",
    key: "admin",
    type: "group",
    subGroup: administrationOptions,
  },
  {
    name: "Settings",
    key: "settings",
    type: "group",
    subGroup: settingsOptions
  }
];

export const keyList = [
  ...navOptions,
  ...edmsDocTypeOptions,
  ...administrationOptions
]