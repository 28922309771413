import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: props => ({
    width: "100%",
    color: "#fff",
    padding: props.padding,
    margin: props.margin,
    borderBottom: "1px solid #CACACA",
  }),
  row: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: "16px",
  },
  pdfWrapper: {
  },
  details: {
    display: "flex",
    flexDirection: "column",
    lineHeight: 3,
    wordWrap: "break-word",
    [theme.breakpoints.up('sm')]: {
    },
    overflow: "auto",
  },
  headerCell: {
    color: theme.palette.primaryPurple,
    ...theme.typography.tableHeadCell,
  },
  divider: {
    background: "white",
    height: "0.8px"
  },
  notFound: {
    textAlign: 'center',
    color: "white",
  },
  ellipsis: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },

  expandToggle: {
    cursor: "pointer",
    minWidth: "20px",
    userSelect: "none",
    textAlign: "center",
  },
  row: {
    flexDirection: "row",
    display: "flex",
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
  }
}))