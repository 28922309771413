import { Grid } from "@material-ui/core";
import fieldHelper from "utils/fieldHelper";

const viewOptions = [
  {
    label: "Full View",
    value: "full",
  },
  {
    label: "Partial View",
    value: "partial",
  }
]

export default function ActionForm({
  control, register, errors,
  userNGroup,
  userList
}) {
  return (
    <>
      {fieldHelper.renderField(
        { label: "For Action", name: "forAction", isRequired: false, type: "AutoComplete", options: userNGroup, multiple: true, },
        50, register, control, errors, { size: 8 })
      }
      <Grid item xs={4} />
      {fieldHelper.renderField(
        { label: "", name: "forActionView", isRequired: false, type: "Radio", options: viewOptions, },
        51, register, control, errors, { size: 12 })
      }
      {fieldHelper.renderField(
        { label: "For Information", name: "forInfo", isRequired: false, type: "AutoComplete", options: userNGroup, multiple: true, },
        52, register, control, errors, { size: 8 })
      }
      <Grid item xs={4} />
      {fieldHelper.renderField(
        { label: "", name: "forActionView", isRequired: false, type: "Radio", options: viewOptions, },
        53, register, control, errors, { size: 12 })
      }
      {fieldHelper.renderField(
        { label: "Due Date", name: "dueDate", isRequired: false, type: "Date", },
        54, register, control, errors)
      }
      {fieldHelper.renderField(
        { label: "Message", name: "message", isRequired: false, rows: 4 },
        55, register, control, errors, { size: 12 })
      }
      {fieldHelper.renderField(
        { label: "On Behalf Of", name: "onBehalfOf", isRequired: false, type: "AutoComplete", options: userList, },
        56, register, control, errors, { size: 4 })
      }
      {fieldHelper.renderField(
        { label: <span style={{ color: "red" }}>Urgent</span>, name: "urgent", isRequired: false, type: "Checkbox", },
        57, register, control, errors, { size: 12 })
      }
    </>
  );
}