import axios from '../apiHelper';

const docTypeHelper = {
  getdocTypes: async (variables) => {
    try {
      const result = await axios.get(`/edms-doc-types`, { params: variables });

      if (!result.data) {
        throw new Error("Fail to get document types");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  deletedocType: async (id) => {
    try {
      const result = await axios.delete(`/edms-doc-type/${id}`);

      if (!result.data) {
        throw new Error("Fail to delete document type");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  createdocType: async (variables) => {
    try {
      const result = await axios.post(`/edms-doc-type`, variables);

      if (!result.data) {
        throw new Error("Fail to create document type");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  editdocType: async (variables, id) => {
    try {
      const result = await axios.patch(`/edms-doc-type/${id}`, variables);

      if (!result.data) {
        throw new Error("Fail to edit document type");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
}

export default docTypeHelper;