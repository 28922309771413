export const optionGroup = [
  {
    name: "Company",
    value: "company",
  },
  {
    name: "Person",
    value: "person",
  },
];

export const titleLookUpList = {
  company: "Company",
  person: "Person",
  "log-number": "Log Number",
  "user-defined-field": "User Defined Field"
};



export const defaultTableConfig = [
  {
    label: "Name",
    id: "name"
  },
  {
    id: "placeholder"
  },
  {
    id: "placeholder"
  },
];

export const logNumberTableConfig = [
  {
    label: "",
    id: "id",
  },
  {
    label: "Name",
    id: "name",
    render: (item) => {
      return (item.prefix === "B" || item.prefix === "I") ? "Incoming Document" : "Outgoing Document";
    }
  },
  {
    label: "Next Log Number",
    id: "logNumber",
    render: (item) => {
      const logNum = `${item.prefix}${String(item.logNumber).padStart(6, '0')}`;
      return logNum;
    }
  },
  {
    id: "placeholder"
  },
];

export const userDefinedFiledTableConfig = [
  {
    label: "Name",
    id: "name"
  },
  {
    label: "Type",
    id: "type",
  },
  {
    label: "Sequence",
    id: "order",
  },
  {
    label: "Required",
    id: "required",
    render: (item) => {
      return item.required ? "Yes" : "No";
    }
  },
  {
    id: "placeholder"
  },
  {
    id: "placeholder"
  },
];


export const dummyData = [
  {
    name: "AECOM",
  }
];

export const defaultFieldConfig = [
  {
    label: "Name",
    name: "name",
    isRequired: true,
  },
];

export const logNumberFieldConfig = [
  {
    label: "Next Log Number",
    name: "logNumber",
    isRequired: true,
    prefix: (item) => {
      return `${item?.prefix} `;
    }
  }
];

export const userDefinedFieldConfig = [
  {
    label: "Name",
    name: "name",
    isRequired: true,
    size: 12,
    mdSize: 4,
  },
  {
    type: "placeholder",
    size: 12,
    mdSize: 8,
  },
  {
    label: "Field Type",
    name: "type",
    type: "Select",
    isRequired: true,
    default: undefined,
    options: [
      {
        label: 'Text',
        value: "string"
      },
      {
        label: 'Number',
        value: "number",
      },
      {
        label: 'Datetime',
        value: "datetime"
      },
      {
        label: 'Checkbox',
        value: "boolean"
      },
    ],
    size: 12,
    mdSize: 4,
  },
  {
    type: "placeholder",
    size: 12,
    mdSize: 8,
  },
  {
    label: "Incoming",
    name: "incoming",
    type: "Checkbox",
    size: 6,
    mdSize: 4,
  },
  {
    label: "Outgoing",
    name: "outgoing",
    type: "Checkbox",
    size: 6,
    mdSize: 4,
  },
  {
    type: "placeholder",
    size: 12,
    mdSize: 4,
  },
  {
    label: "Required",
    name: "required",
    type: "Checkbox",
    size: 6,
    mdSize: 4,
  },
  {
    type: "placeholder",
    size: 6,
    mdSize: 12,
  },
  {
    label: "Sequence",
    name: "order",
    isRequired: true,
    type: "Number",
    size: 12,
    mdSize: 4,
  },
  {
    type: "placeholder",
    size: 12,
    mdSize: 8,
  },
]
