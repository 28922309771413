import { Table, TableBody, TableRow, useTheme } from "@material-ui/core";

import CustTableCell from "Common/TableCell";
import TableHeader from "Common/TableHeader";
import decryptHelper from "utils/decryptHelper";

export default function ViewTable({ data, config, getDataApi, }) {
  const theme = useTheme();
  return (
    <div>
      <Table>
        <TableHeader fields={config} />
        <TableBody>
          {data.map((item, idx) =>
            <TableRow key={idx}>
              {config.map((column, colIdx) =>
                <CustTableCell key={colIdx}>
                  <span>
                    {decryptHelper.renderLabel(column, item)}
                  </span>
                </CustTableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div >
  );
}
