import { useParams, useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useEffect, useState, useRef } from 'react';
import { useForm, } from 'react-hook-form';
import { Grid } from '@material-ui/core';
import moment from 'moment';

import Button from "Common/RoundedButton";
import EllipseButton from 'Common/EllipseButton';
import { setNotification } from 'redux/slice/notificationSlice';
import SectionTitle from 'Common/SectionTitle';
import { setLoading } from 'redux/slice/loadingSlice';
import { constructActionRequestArr, findFolderId } from 'utils/dataUtils';

import { QRCodeSVG } from 'qrcode.react';
import EditDocumentMainForm from './editDocumentMainForm';
import BrowseDocument from 'EDMSRegisterDocument/BrowseDocument';
import edmsRegisterHelper from 'helpers/edmsRegisterHelper';
import arrayHelper from 'utils/arrayHelper';
import ActionForm from './ActionForm';

import { useStyles } from "../style";

export default function FormContainer({ defaultData, fileOptions, companyOptions, personOptions, docTypeOptions, userNGroup, userList, userStringList, groupList, letterRefList }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { contractId, direction, id } = useParams();
  const fileNumberRef = useRef()
  const { watch, register, handleSubmit, control, formState: { errors }, setValue, setError } = useForm({
    defaultValues: {
      ...defaultData,
      docType: defaultData.docType,
      dueDate: getDueDate(defaultData?.docType),
      file: null,
      replyTo: initRelatedDocuments('replyTo'),
      fromCompany: getAutoCompleteDefaultValue('fromCompany', true),
      fromPerson: getAutoCompleteDefaultValue('fromPerson', true),
      toCompany: getAutoCompleteDefaultValue('toCompany', true),
      toPerson: getAutoCompleteDefaultValue('toPerson', true),
      ccToCompany: getAutoCompleteDefaultValue('ccToCompany', true),
      repliedBy: getAutoCompleteDefaultValue('repliedBy', false),
      relatedDocuments: initRelatedDocuments("relatedDocuments"),
    }
  });
  const [subFolderOptions, setSubFolderOptions] = useState([]);
  const [searchingId, setSearchingId] = useState();
  const watchFile = watch('file');
  const fileNumberInput = watch('fileNumber');
  const watchDocType = watch('docType');
  const receiveDateInput = watch("receiveDate");
  const scrollToFileNumber = () => fileNumberRef.current.scrollIntoView();

  useEffect(() => {
    async function initFileNumber() {
      const result = await edmsRegisterHelper.getSubFolders({ fileId: defaultData.fileId });
      setValue("fileNumber", getDefaultFileNumber(result?.file?.dueDays));
    }
    initFileNumber();
  }, [])
  useEffect(() => {
    if (watchDocType) setActionFormDefaultValue(watchDocType);
  }, [watchDocType])

  function initRelatedDocuments(keyname) {
    const value = defaultData?.[keyname].map(item => {
      return {
        letterRef: {
          label: item.letterRef,
          value: item.letterRef,
          id: item.id,
          letterDate: item.letterDate,
          fileNumber: item.fileNumber,
          logNumber: item.logNumber
        }
      }
    });
    return value
  }

  useEffect(() => {
    if (typeof fileNumberInput === 'string') {
      findSubFolders();
    }
    if (typeof fileNumberInput === 'object') {
      setValue("dueDate", getDueDate(watchDocType, fileNumberInput, receiveDateInput));
    }
  }, [fileNumberInput]);

  useEffect(() => {
    if (receiveDateInput) {
      setValue("dueDate", getDueDate(watchDocType, fileNumberInput, receiveDateInput));
    }
  }, [receiveDateInput])

  async function findSubFolders() {
    const layer2IndicatorCount = (fileNumberInput.match(/\//g) || []).length;
    const layer3Indicator = (fileNumberInput.match(/\./g) || []).length === 1;
    if (fileNumberInput[fileNumberInput.length - 1] === "/") {
      const result = await findFolderId(fileNumberInput.substring(0, fileNumberInput.indexOf('/')), layer2IndicatorCount === 1, fileOptions, subFolderOptions, fileNumberInput, searchingId);
      if (result?.subFolders) {
        setSubFolderOptions(result.subFolders);
        setSearchingId(result.fileId);
      }
      return;
    }
    if (fileNumberInput[fileNumberInput.length - 1] === "." && layer3Indicator) {
      if (!layer2IndicatorCount) return;
      const result = await findFolderId(fileNumberInput.substring(0, fileNumberInput.indexOf('.')), false, fileOptions, subFolderOptions, fileNumberInput, searchingId);
      if (result?.subFolders) {
        setSubFolderOptions(result.subFolders);
        setSearchingId(result.fileId);
      }
      return
    }
    switch (layer2IndicatorCount) {
      case 0:
        setSubFolderOptions([]);
        setSearchingId();
        break;
      case 1:
        if (layer3Indicator) {
          findFolderId(fileNumberInput.substring(0, fileNumberInput.indexOf('/')), true, fileOptions, subFolderOptions, fileNumberInput, searchingId).then(v1 =>
            findFolderId(fileNumberInput.substring(0, fileNumberInput.indexOf('.')), false, fileOptions, v1.subFolders, fileNumberInput, v1.fileId).then(v2 => {
              if (v2?.subFolders) {
                setSubFolderOptions(v2.subFolders);
                setSearchingId(v2.fileId);
                return;
              }
            })
          )
        }
        else {
          const result = await findFolderId(fileNumberInput.substring(0, fileNumberInput.indexOf('/')), true, fileOptions, subFolderOptions, fileNumberInput, searchingId);
          if (result?.subFolders) {
            setSubFolderOptions(result.subFolders);
            setSearchingId(result.fileId);
          }
        }
    }
  }


  function getDueDate(docType, fileNumber, letterDate) {
    if (!letterDate) return null;
    if (fileNumber && fileNumber.dueDays) return moment(letterDate).add(fileNumber.dueDays, "day");
    if (!docType) return moment(letterDate).add(7, "day");
    const targetDocTypeObj = docTypeOptions.find(item => item.value.toLowerCase() === docType.toLowerCase());
    if (!targetDocTypeObj) return moment(letterDate).add(7, "day");
    return moment(letterDate).add(targetDocTypeObj.dueDays ?? 7, "day")
  }
  function getSendTo(docType, sendType) {
    if (!docType) return [];
    const targetDocTypeObj = docTypeOptions.find(item => item.value === docType);
    return arrayHelper.extractUserGroupInfo(targetDocTypeObj?.defaultRecipientList?.[sendType], userList, groupList) ?? [];
  }

  function setActionFormDefaultValue(docType) {
    setValue('dueDate', getDueDate(docType, fileNumberInput, receiveDateInput));
    setValue('forAction', getSendTo(docType, "action"));
    setValue('forInfo', getSendTo(docType, "information"));
  }

  function getAutoCompleteDefaultValue(keyName, isMultiple) {
    if (isMultiple) {
      if (defaultData?.[keyName]) {
        return defaultData?.[keyName].split(",").map(item => {
          return {
            label: item,
            value: item
          }
        })
      }
      return [];
    }
    if (defaultData?.[keyName]) {
      return {
        label: defaultData?.[keyName],
        value: defaultData?.[keyName],
      }
    }
    return "";
  }

  function getDefaultFileNumber(dueDays) {
    if (!defaultData.fileId || !defaultData.filePath?.length) return;
    return { label: defaultData.fileNumber, value: defaultData.fileId, dueDays: dueDays };
  }

  async function onSubmit(data) {
    try {
      dispatch(setLoading(true));
      // if ((data?.forAction?.length > 0 || data?.forInfo?.length > 0) && !data.fileNumber) {
      //   setError("fileNumber", { type: "custom", message: "When has action request, File Number is required" });
      //   scrollToFileNumber();
      //   return;
      // }
      let replyTo = Array.isArray(data.replyTo) && data.replyTo.map(item => item?.letterRef?.id);
      let fromCompany = Array.isArray(data.fromCompany) && data.fromCompany.map(item => { return item.label ?? item });
      let toCompany = Array.isArray(data.toCompany) && data.toCompany.map(item => { return item.label ?? item });
      let fromPerson = Array.isArray(data.fromPerson) && data.fromPerson.map(item => { return item.label ?? item });
      let toPerson = Array.isArray(data.toPerson) && data.toPerson.map(item => { return item.label ?? item });
      let ccToCompany = Array.isArray(data.ccToCompany) && data.ccToCompany.map(item => { return item.label ?? item });
      let relatedDocuments = Array.isArray(data.relatedDocuments) && data.relatedDocuments.map(item => item?.letterRef?.id);

      const request = {
        ...data,
        fileId: data.fileNumber?.value ?? null,
        fileNumber: data.fileNumber?.label ?? null,
        replyTo: replyTo,
        // repliedBy: repliedBy,
        relatedDocuments: relatedDocuments,
        fromCompany: fromCompany && fromCompany.join(","),
        toCompany: toCompany && toCompany.join(","),
        fromPerson: fromPerson && fromPerson.join(","),
        toPerson: toPerson && toPerson.join(","),
        ccToCompany: ccToCompany && ccToCompany.join(","),
      }
      // console.log(request)
      if (data?.forAction?.length > 0 || data?.forInfo?.length > 0) {
        const actionRequest = constructActionRequest(request);
        const actionResult = await edmsRegisterHelper.postActionRequest(actionRequest);
        if (actionResult.error) throw new Error(actionResult.error);
      }

      const result = await edmsRegisterHelper.updateEDMSDocumentMeta(id, request);
      if (result.error) throw new Error(result.error);
      if (data.file?.[0]) {
        const uploadResult = await edmsRegisterHelper.uploadDocumentFile(id, { file: data.file[0] });
        if (uploadResult.error) throw new Error(result.error);
        dispatch(setNotification({ type: "success", message: "Success" }));
        setTimeout(() => {
          dispatch(setLoading(false));
          window.close();
        }, 1000);
      } else {
        dispatch(setNotification({ type: "success", message: "Success" }));
        setTimeout(() => {
          dispatch(setLoading(false));
          window.close();
        }, 1000);
      }
      if (history?.length && history.length > 1) history.goBack();
      else history.push(`/contract/${contractId}/edms/register-document/${direction}/update`);

    } catch (e) {
      dispatch(setLoading(false));
      dispatch(setNotification({ type: "error", message: e.message }));
      setLoading(false);
      console.log(e)
    }
  }

  function constructActionRequest(data) {
    const actionRequestForArr = constructActionRequestArr(data);

    const request = {
      fileMetaId: id,
      dueDate: data.dueDate,
      actionRequestMessage: [
        {
          onBehalfOf: data?.onBehalfOf?.label,
          content: data.message,
          isDraft: false
        }
      ],
      actionRequestFor: actionRequestForArr,
    };
    return request;
  }

  function onError(data) {
    dispatch(setNotification({ type: "error", message: "Some field(s) are missing." }))
  }

  function copyLogNumber() {
    navigator.clipboard.writeText(defaultData.logNumber)
    dispatch(setNotification({ type: "success", message: "Log Number is copied" }))
  }

  function printBarCode() {
    const numberOfLabel = 1;
    const container = document.getElementById("barcode");
    const width = "1024px";
    const height = "800px";
    const printWindow = window.open('', 'PrintMap',
      'width=' + width + ',height=' + height);
    for (let i = 0; i < numberOfLabel; i++) {
      printWindow.document.writeln('<div style="width:128px">' + container.innerHTML + `<div style="text-align: center; margin-top: 8px">${defaultData.logNumber}</div></div>`);
    }
    printWindow.document.close();
    printWindow.print();
  }
  const checkKeyDown = (e) => {
    if (e.code === 'Enter') e.preventDefault();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)} onKeyDown={(e) => checkKeyDown(e)}>
      <Grid container spacing={3}>

        <Grid item xs={12}>
          <SectionTitle title={`Update ${direction === "in" ? "Incoming" : "Outgoing"} Document`}
            extraComponents={
              <div className={classes.buttonsContainer}>
                {<>
                  <div style={{ marginRight: 8 }}><EllipseButton onClick={copyLogNumber} label="Copy Log Number" /></div>
                  <EllipseButton onClick={printBarCode} label="Print QR Code" />
                </>}
              </div>
            }
          />
        </Grid>
        {defaultData?.logNumber &&
          <div id="barcode" style={{ position: "absolute", opacity: 0 }}>
            <QRCodeSVG value={`${document.location.origin}/contract/${contractId}/edms/register-document/${direction}/view/document/${id}`} />
          </div>
        }

        <EditDocumentMainForm
          docTypeOptions={docTypeOptions}
          companyOptions={companyOptions}
          personOptions={personOptions}
          control={control} defaultValue={defaultData} register={register} errors={errors}
          direction={direction}
          fileOptions={fileOptions}
          subFolderOptions={subFolderOptions}
          letterRefList={letterRefList}
          userList={userStringList}
          fileNumberRef={fileNumberRef}
          setValue={setValue}
          repliedBy={defaultData?.repliedBy}
        />

        <Grid item xs={12} sm={6}></Grid>

        <Grid item xs={12}>
          <SectionTitle title={`Action Request(Optional)`} />
        </Grid>
        <ActionForm
          control={control} defaultValue={defaultData} register={register} errors={errors}
          direction={direction}
          userNGroup={userNGroup}
          userList={userStringList}
        />
        <Grid item xs={12} sm={6}></Grid>

        <Grid item xs={12}>
          <SectionTitle title={`Browse Main Document from PC`} />
        </Grid>
        <BrowseDocument required={false} control={control} register={register} errors={errors} name={"file"} watchFile={watchFile}></BrowseDocument>
        <Grid item xs={12} sm={6}></Grid>

        <Grid item xs={12} sm={12}></Grid>

        <Grid container item justify="flex-end">
          <Button children="Save" backgroundColor="#70BAD3" type="submit" />
        </Grid>

      </Grid>
    </form>
  );
}