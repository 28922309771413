import { Checkbox } from "@material-ui/core";

import { useStyles } from "./style";

function CustCheckbox(props) {
  const classes = useStyles();
  function onChange(e, value) {
    if (props.customOnChange) {
      props.customOnChange(e, value);
      return;
    }
    if (props.field) props.field.onChange(value);
    else props.onChange(value);
  }

  function determineDefaultChecked() {
    if (props.defaultChecked) {
      return props.defaultChecked;
    }
    if (props.field) return props.field.value
    return false;
  }

  return (
    <Checkbox
      {...props}
      className={classes.Checkbox}
      onChange={onChange}
      // defaultChecked={(props.field && props.field.value) ?? false}
      defaultChecked={determineDefaultChecked()}
      classes={{
        checked: classes.Checked,
        indeterminate: classes.indeterminate
      }}
    />
  );
}

export default CustCheckbox;