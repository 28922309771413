import moment from "moment";
import MarkunreadIcon from '@material-ui/icons/Markunread';
import DraftsIcon from '@material-ui/icons/Drafts';
import MessageCell from "EDMSRegisterDocument/MessageCell";
import { permissionHelper } from "helpers";
import pdfHelper from "utils/pdfHelper";

const linkStyle = { cursor: "pointer", textDecoration: "underline", wordBreak: "break-word" };
const refStyle = { cursor: "pointer", textDecoration: "underline", wordBreak: "break-word" };

const seenOptions = [
  {
    label: "All",
    value: "",
  },
  {
    label: "Seen",
    value: 1,
  },
  {
    label: "Unread",
    value: 0,
  }
];
const inOutOptions = [
  {
    label: "All",
    value: "",
  },
  {
    label: "In",
    value: "in",
  },
  {
    label: "Out",
    value: "out",
  }
];
const inboxStatusOptions = [
  {
    label: "All",
    value: "",
  },
  {
    label: "Outstanding",
    value: "Outstanding",
  },
  {
    label: "Processing",
    value: "Processing",
  },
  {
    label: "Finished",
    value: "Finished",
  },
];

export const tableConfig = [
  {
    id: "logNumber", label: "Log No.", width: 90, render: (item) => {
      const pathname = `${window.location.pathname}/document/${item.id}`;
      return <div style={linkStyle} onClick={() => pdfHelper.openPageInWindow(pathname, item.id)}>{item.logNumber}</div>;
    }
  },
  { id: "letterDate", label: "Letter Date", type: "Date", width: 90 },
  { id: "receiveDate", label: "Receive/ Dispatch", type: "Date", width: 90, },
  { id: "subject", label: "Subject", size: 12 },
  { id: "fileNumber", type: "fileNumber", label: "File No.", width: 90 },
  { id: "docType", label: "Document Type", optionKey: "docType", type: "Select" },
  { id: "fromCompany", label: "From Company", optionKey: "company", type: "Autocomplete", },
  { id: "toCompany", label: "To Company", optionKey: "company", type: "Autocomplete" },
  {
    id: "letterRef", label: "Letter Ref.", render: (item) => {
      if (!item.documents || item.documents?.length === 0) return item.letterRef;
      const targetDoc = item.documents?.[item.documents.length - 1];
      const docId = targetDoc.documentId;
      const noPermission = !docId && targetDoc.view === false;
      return <div style={refStyle} onClick={() => pdfHelper.openDocInBlankPage(noPermission ? false : docId)}>{item.letterRef}</div>;
    },
  },
  {
    label: "Replied By", optionKey: "letterRef", type: "Autocomplete",
    id: "repliedBy",
    render: (row) => {
      if (!row.repliedBy || row.repliedBy?.length === 0) return "-";
      return pdfHelper.renderLetterRefLinkList(row.repliedBy);
    },
  },
  {
    id: "replyTo", label: "Reply To", optionKey: "letterRef", type: "Autocomplete",
    render: (row) => {
      if (!row.replyTo || row.replyTo?.length === 0) return "-";
      return pdfHelper.renderLetterRefLinkList(row.replyTo);
    },
  },
  {
    id: "a1Drawing", label: "Sketch / Drawing No.", render: (item) => {
      if (!item.a1Drawing) return "-";
      if (!item.documents || item.documents?.length === 0) return item.a1Drawing;
      const targetDoc = item.documents?.[item.documents.length - 1];
      const docId = targetDoc.documentId;
      const noPermission = !docId && targetDoc.view === false;
      return <div style={refStyle} onClick={() => pdfHelper.openDocInBlankPage(noPermission ? false : docId)}>{item.a1Drawing}</div>;
    }
  },
  { label: "" },
];

export const riskRegisterTableConfig = [
  { id: "riskIdReference", label: "Risk ID Ref" },
  { id: "issueDate", label: "Date of Issue / Receipt of EW", type: "Date" },
  { id: "issuedBy", label: "Issued by DSD / AECOM / KLCWJV (Ref No.)", },
  { id: "incidentNumber", label: "Incident No." },
  { id: "description", label: "Description", type: "NoFilter" },
  { id: "potentialRisks", label: "Potential Problem(s) and the Risk(s)", type: "NoFilter" },
  { id: "impact", label: "Impact", },
  { id: "affectedWorkSection", label: "Affected Work Section", },
  { id: "affectedKeyDate", label: "Affected Key Date" },
  {
    id: "riskRegisterStatus", label: "Status", type: "Select",
    options: [
      {
        label: 'Open',
        value: "Open"
      },
      {
        label: 'Closed',
        value: "Closed"
      },
    ]
  },
  { id: "riskCommon", label: "Description / Potential Problem(s) and the Risk(s) / Action to be taken to avoid/reduce the risk(s)", hidden: true, size: 12 },
  { label: "" },
];

export const riskReductionMeetingTableConfig = [
  { id: "date", label: "Meeting Date", type: "Date" },
  { id: "riskIdReference", label: "Risk ID Ref.", hidden: true },
  { id: "incidentNumber", label: "Incident No.", hidden: true },
  {
    id: "startTime", label: "Time",
    render: (item) => {
      const start = moment(item.startTime, 'H:mm:ss').format('H:mm');
      const end = moment(item.endTime, 'H:mm:ss').format('H:mm');
      return `${start} - ${end}`;
    }
  },
  { id: "venue", label: "Venue", },
  { id: "riskMeetingReferenceNumber", label: "Meeting No.", },
  { id: "riskCommon", label: "Description / Potential Problem(s) and the Risk(s) / Action to be taken to avoid/reduce the risk(s)", hidden: true, size: 12 },
  // { id: "letterRef", label: "Letter Ref.", },
  { label: "" },
];

export const updateDocumentListConfig = [
  {
    id: "logNumber", label: "Log No.", render: (item) => {
      const pathname = `${window.location.pathname}/${item.id}`;
      return <div style={linkStyle} onClick={() => pdfHelper.openPageInWindow(pathname, item.id)}>{item.logNumber}</div>;
    }
  },
  {
    id: "letterRef", label: "Letter Ref.", width: "35%",
    render: (item) => {
      if (!item.documents || item.documents?.length === 0) return item.letterRef;
      const targetDoc = item.documents?.[item.documents.length - 1];
      const docId = targetDoc.documentId;
      const noPermission = !docId && targetDoc.view === false;
      return <div style={refStyle} onClick={() => pdfHelper.openDocInBlankPage(noPermission ? false : docId)}>{item.letterRef}</div>;
    }
  },
  { id: "subject", label: "Subject", },
  { id: "registerBy", label: "Register By", },
  { label: "" },
  { label: "" },
];

export const viewDocumentListInConfig = [
  {
    id: "logNumber", label: "Log No.", render: (item) => {
      const pathname = `${window.location.pathname}/document/${item.id}`;
      return <div style={linkStyle} onClick={() => pdfHelper.openPageInWindow(pathname, item.id)}>{item.logNumber}</div>;
    }
  },
  {
    id: "letterRef", label: "Letter Ref.", width: "35%",
    render: (item) => {
      if (!item.documents || item.documents?.length === 0) return item.letterRef;
      const targetDoc = item.documents?.[item.documents.length - 1];
      const docId = targetDoc.documentId;
      const noPermission = !docId && targetDoc.view === false;
      return <div style={refStyle} onClick={() => pdfHelper.openDocInBlankPage(noPermission ? false : docId)}>{item.letterRef}</div>;
    }
  },
  { id: "subject", label: "Subject", },
  { id: "letterDate", label: "Date", type: "Date" },
  { id: "docType", label: "Type" },
  { id: "receiveDate", label: "Receive Date", type: "Date" },
  { label: "" },
];
export const viewDocumentListOutConfig = [
  {
    id: "logNumber", label: "Log No.", render: (item) => {
      const pathname = `${window.location.pathname}/document/${item.id}`;
      return <div style={linkStyle} onClick={() => pdfHelper.openPageInWindow(pathname, item.id)}>{item.logNumber}</div>;
    }
  },
  {
    id: "letterRef", label: "Letter Ref.", width: "35%",
    render: (item) => {
      if (!item.documents || item.documents?.length === 0) return item.letterRef;
      const targetDoc = item.documents?.[item.documents.length - 1];
      const docId = targetDoc.documentId;
      const noPermission = !docId && targetDoc.view === false;
      return <div style={refStyle} onClick={() => pdfHelper.openDocInBlankPage(noPermission ? false : docId)}>{item.letterRef}</div>;
    }
  },
  { id: "subject", label: "Subject", },
  { id: "letterDate", label: "Date", type: "Date" },
  { id: "docType", label: "Type" },
  { id: "receiveDate", label: "Dispatch Date", type: "Date" },
  { label: "" },
];

export const actionReportConfig = [
  { id: "actionDate", label: "Action Date", type: "Date" },
  { id: "logNumber", label: "Log No." },
  { id: "subject", label: "Subject", },
  { id: "docType", label: "Doc Type" },
  { id: "dueDate", label: "Due Date", type: "Date" },
  { id: "requestedBy", label: "Requested By" },
  { id: "toBeDoneBy", label: "To Be Done By" },
];

export const incidentConfig = [
  { id: "incidentNumber", label: "Incident No." },
  { id: "issueDate", label: "Issue Date", type: "Date" },
  { id: "subject", label: "Subject", },
  { id: "location", label: "Location", },
  { id: "createdAt", label: "Created Date", type: "Date" },
  { id: "createdBy", label: "Created By" },
  { label: "" },
  { label: "" },
];

export const safetyPerformanceConfig = [
  { id: "reportPeriodFrom", label: "Reporting Month", type: "Month", isDate: true },
  {
    id: "revisionNumber", label: "Revision No.", render: (item) => {
      return item.revisionNumber.toString();
    }
  },
  { id: "createdAt", label: "Created On", type: "Date" },
  { id: "createdBy", label: "Created By", },
  {
    id: "status", label: "Status", type: "Select",
    options: [
      {
        label: "",
        value: null,
      },
      {
        label: 'Reported',
        value: "Reported"
      },
      {
        label: 'Endorsed',
        value: "Endorsed"
      },
      {
        label: 'Supervisor Verified',
        value: "Supervisor Verified"
      },
      {
        label: 'Supervisor counter-verified',
        value: "Supervisor counter-verified"
      },
      {
        label: 'Closed - PM verified',
        value: "Closed"
      },
    ]
  },
  { id: "assignedTo", label: "Assigned To", },
  { id: "comment", label: "Comments", },
  { id: "updatedAt", label: "Updated On", type: "Date" },
  { id: "updatedBy", label: "Updated By" },
  { label: "" },
  { label: "" },
];

export const siConfig = [
  { id: "incidentNumber", label: "Incident No." },
  { id: "siNumber", label: "NCE/Comm/SI No." },
  {
    id: "type", label: "Document Type", type: "Select",
    options: [
      {
        label: "NCE",
        value: "NCE"
      },
      {
        label: "Communication",
        value: "Communication",
      },
      {
        label: "SI",
        value: "SI"
      },
    ]
  },
  {
    id: "contractCovered", label: "Contract Covered",
    isBoolean: true,
    type: "Select",
    options: [
      {
        label: "Yes",
        value: "1"
      },
      {
        label: "No",
        value: "0",
      }
    ]
  },
  { id: "issueDate", label: "Issue Date", type: "Date" },
  { id: "createdAt", label: "Create Date", type: "Date" },
  { id: "subject", label: "Subject", },
  { id: "location", label: "Location", },
  { id: "commerceDate", label: "Commerce Date", type: "Date" },
  { id: "completionDate", label: "Completion Date", type: "Date" },
  { id: "reasonForSi", label: "Reason for SI" },
  { id: "createdBy", label: "Created By" },
  { id: "documentCheckedBy", label: "Checked By" },
  { label: "" },
  { label: "" },
];

export const bimProjectStatusConfig = [
  { id: "displayName", label: "Model" },
  { id: "createTime", label: "Started at", type: "Datetime" },
  { id: "createUserName", label: "Author", },
  { id: "extractionState", label: "Publishing State", },
  { id: "lastModifiedTime", label: "Completed at", type: "Datetime" },
  { id: "path", label: "Path", },
];

export const bimFolderConfig = [
  { id: "displayName", label: "Name" },
  { id: "versionNumber", label: "Version", },
  { id: "lastModifiedTime", label: "Last Updated", type: "Datetime" },
  { id: "lastModifiedUserName", label: "Updated By", },
];

export const inboxActionConfig = [
  {
    id: "seen", label: "Seen", hideLabel: true, type: "Read",
    render: (item) => {
      return item.seen ? <DraftsIcon /> : <MarkunreadIcon />
    },
    options: seenOptions
  },
  {
    id: "logNumber", label: "Log No.", render: (item) => {
      const pathname = `${window.location.pathname.replace("all-actions", "action")}/document/${item.id}`;
      return <div style={linkStyle} onClick={() => pdfHelper.openPageInWindow(pathname, item.id)}>{item.logNumber}</div>;
    }
  },
  { id: "fromCompany", label: "Company", },
  { id: "dueDays", label: "Due in", type: "Number", hideInTable: true, trailingText: "Days" },
  { id: "letterDate", label: "Doc Date", type: "Date" },
  { id: "receiveDate", label: "Receive", type: "Date" },
  {
    id: "inOut", label: "In/Out", type: "Select",
    options: inOutOptions
  },
  { id: "subject", label: "Subject", },
  { id: "dueDate", label: "Due Date", type: "Date" },
  {
    id: "status", label: "Status", type: "Select",
    options: inboxStatusOptions,
    width: 120
  },
  {
    id: "content", label: "Message", width: "max-content",
    type: "NoFilter",
    render: (item, reload) => {
      return <MessageCell data={item} reload={reload} />
    }
  },
  {
    id: "replied", label: "Reply", hideInTable: true, type: "Select",
    options: [
      {
        label: "All",
        value: "",
      },
      {
        label: "Unreplied",
        value: "false",
      },
      {
        label: "Replied",
        value: "true",
      },
    ],
  },
  { label: "" },
];

export const inboxInfoConfig = [
  {
    id: "seen", label: "Seen", hideLabel: true, type: "Read",
    render: (item) => {
      return item.seen ? <DraftsIcon /> : <MarkunreadIcon />
    },
    options: seenOptions
  },
  {
    id: "logNumber", label: "Log No.", render: (item) => {
      const pathname = `${window.location.pathname}/document/${item.id}`;
      return <div style={linkStyle} onClick={() => pdfHelper.openPageInWindow(pathname, item.id)}>{item.logNumber}</div>;
    }
  },
  { id: "fromCompany", label: "Company", },
  { id: "letterDate", label: "Doc Date", type: "Date" },
  { id: "receiveDate", label: "Receive", type: "Date" },
  {
    id: "inOut", label: "In/Out", type: "Select",
    options: inOutOptions
  },
  { id: "subject", label: "Subject", },
  { id: "createdAt", label: "Received", type: "Date" },
  {
    id: "content", label: "Message", width: "450px",
    type: "NoFilter",
    render: (item, reload) => {
      return <MessageCell data={item} reload={reload} />
    }
  },
  { label: "" },
];

export const inboxSentConfig = [
  {
    id: "seen", label: "Seen", hideLabel: true, type: "Read",
    render: (item) => {
      return item.seen ? <DraftsIcon /> : <MarkunreadIcon />
    },
    options: seenOptions
  },
  {
    id: "logNumber", label: "Log No.", render: (item) => {
      const pathname = `${window.location.pathname}/document/${item.actionRequestForId}`;
      return <div style={linkStyle} onClick={() => pdfHelper.openPageInWindow(pathname, item.actionRequestForId)}>{item.logNumber}</div>;
    }
  },
  { id: "fromCompany", label: "Company", },
  { id: "letterDate", label: "Doc Date", type: "Date" },
  { id: "receiveDate", label: "Receive", type: "Date" },
  {
    id: "inOut", label: "In/Out", type: "Select",
    options: inOutOptions
  },
  { id: "subject", label: "Subject", },
  {
    id: "status", label: "Status", type: "Select",
    options: inboxStatusOptions
  },
  { id: "dueDate", label: "Due Date", type: "Date" },
  {
    id: "content", label: "Message", width: "max-content",
    type: "NoFilter",
    render: (item, reload) => {
      return <MessageCell data={item} reload={reload} type="sent" />
    }
  },
  { label: "" },
];

export const inboxAllActionsConfig = [
  {
    id: "seen", label: "Seen", hideLabel: true, type: "Read",
    render: (item) => {
      return item.seen ? <DraftsIcon /> : <MarkunreadIcon />
    },
    options: [
      {
        label: "All",
        value: "",
      },
      {
        label: "Seen",
        value: 1,
      },
      {
        label: "Unread",
        value: 0,
      }
    ]
  },
  { id: "logNumber", label: "Log Number" },
  { id: "fromCompany", label: "Company", },
  { id: "dueDays", label: "Due in", type: "Number", hideInTable: true, trailingText: "Days" },
  { id: "letterDate", label: "Doc Date", type: "Date" },
  { id: "receiveDate", label: "Receive", type: "Date" },
  {
    id: "inOut", label: "In/Out", type: "Select",
    options: inOutOptions
  },
  { id: "subject", label: "Subject", },
  { id: "dueDate", label: "Due Date", type: "Date" },
  {
    id: "status", label: "Status", type: "Select",
    options: inboxStatusOptions,
    width: 120
  },
  {
    id: "content", label: "Message", width: "max-content",
    type: "NoFilter",
    render: (item, reload) => {
      return <MessageCell data={item} reload={reload} />
    }
  },
  {
    id: "userId", label: "For Action", type: "Select", hideInTable: true, insertBlank: true,
    options: [],
    getOptions: async () => {
      const result = await permissionHelper.getUsers({ orderBy: "firstName.asc" });
      const userList = result.user.map(v => ({ value: v.id, label: `${v.firstName} ${v.lastName}` }));
      return userList;
    }
  },
  { label: "" },
];