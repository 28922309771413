import PropTypes from "prop-types";
import { Button, useTheme } from "@material-ui/core";

import { useStyles } from "./style";

function RoundedButton({
  children,
  startIcon,
  onClick,
  backgroundColor,
  width,
  margin,
  disabled,
  type,
  ...props
}) {
  const theme = useTheme();
  const styleProps = { theme, width };
  const classes = useStyles(styleProps);

  return (
    <Button
      disabled={disabled}
      startIcon={startIcon}
      onClick={onClick}
      style={{
        backgroundColor: backgroundColor,
        margin: margin,
      }}
      className={classes.button}
      {...props}
      type={type}
    >
      {children}
    </Button>
  );
}

RoundedButton.propTypes = {
  label: PropTypes.any,
  startIcon: PropTypes.node,
  onClick: PropTypes.func,
  backgroundColor: PropTypes.string
};

export default RoundedButton;