import PropTypes from "prop-types";
import { Select, MenuItem, Checkbox, ListItemText } from "@material-ui/core";
import { useState, useEffect } from "react";

import { useStyles } from "./style";

function CustSelect({
  value,
  options,
  getOptions,
  handleChange,
  label,
  dense,
  field,
  labelKey,
  valueKey,
  renderMultipleValue,
  checkboxKey,
  multiple = false,
  insertBlank = false,
  defaultValue,
  disabled,
  hideLabel,
}) {
  const classes = useStyles();
  const [newOptions, setNewOptions] = useState();
  const [isMounted, setIsMounted] = useState(false);
  function formOnChange(e) {
    field.onChange(e);
  }

  function renderOptions(list, multiple) {
    if (multiple) {
      return list.map((option, idx) => (
        <MenuItem key={idx} value={valueKey ? option[valueKey] : option}>
          <Checkbox
            checked={field.value.indexOf(valueKey ? option[valueKey] : option) > -1}
          />
          <ListItemText primary={labelKey ? option[labelKey] : option.label} />
        </MenuItem>
      ))
    }
    return list.map((option, idx) => (<MenuItem value={valueKey ? option[valueKey] : option.value} key={idx}>{labelKey ? option[labelKey] : option.label}</MenuItem>));
  }

  useEffect(() => {
    if (getOptions) {
      getOptions().then((v) => {
        setNewOptions(v);
        setIsMounted(true);
      })
    } else {
      setIsMounted(true);
    }
  }, [])
  if (!isMounted) {

  }
  if (multiple) {
    return (
      <div className={classes.fullWidth}>
        {hideLabel
          ? <></>
          : <div className={dense ? classes.denseLabel : classes.label}>{label}</div>
        }
        <Select
          {...field}
          displayEmpty
          onChange={(e) => formOnChange(e.target.value)}
          className={classes.select}
          multiple
          renderValue={(selected) => renderMultipleValue ? renderMultipleValue(selected) : selected.join(', ')}
          value={field.value}
          defaultValue={defaultValue}
          disabled={disabled}
        >
          {renderOptions(newOptions ?? options, true)}
        </Select>
      </div >
    );
  } else if (handleChange) {
    return (
      <div className={classes.fullWidth}>
        {hideLabel
          ? <></>
          : <div className={dense ? classes.denseLabel : classes.label}>{label}</div>
        }
        <Select
          displayEmpty
          defaultValue={defaultValue}
          value={value}
          onChange={handleChange}
          className={classes.select}
          multiple={multiple}
          disabled={disabled}
        >
          {insertBlank &&
            <MenuItem value={""}>{insertBlank}</MenuItem>
          }
          {renderOptions(newOptions ?? options)}

        </Select>
      </div>
    );
  }
  return (
    <div>
      {hideLabel
        ? <></>
        : <div className={dense ? classes.denseLabel : classes.label}>{label}</div>
      }
      <Select
        {...field}
        displayEmpty
        onChange={(e) => formOnChange(e.target.value)}
        className={classes.select}
        defaultValue={defaultValue}
        multiple={multiple}
        disabled={disabled}
      >
        {insertBlank &&
          <MenuItem value={""}></MenuItem>
        }
        {renderOptions(newOptions ?? options)}

      </Select>
    </div>
  );
}

CustSelect.propTypes = {
  label: PropTypes.any,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  options: PropTypes.array,
  handleChange: PropTypes.func
};

export default CustSelect;