import Button from "Common/RoundedButton";

import { useStyles } from "./style";

function InboxAttachButton({ children, setFiles, multiple, width, styles }) {
  const classes = useStyles();

  function onChange(e) {
    if (e?.target?.files) {
      setFiles(e.target.files);
    }
  }
  return (
    <div style={styles}>
      <Button
        varient="contained"
        component="label"
        width={width}
        backgroundColor="#70BAD3"
      >
        {children}
        <input
          multiple={multiple}
          type="file"
          hidden
          onChange={onChange}
        />
      </Button>
      {/* {(files && files[0]) &&
        <div className={classes.fileInfo}>
          <AttachFileIcon />
          {files[0].name}
        </div>
      } */}
    </div>
  );
}

export default InboxAttachButton;