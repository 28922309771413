import { useTheme, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useState } from "react";
import SectionTitle from "Common/SectionTitle";
import ViewTable from "Common/ViewTable";

import { useStyles } from "Document/DocumentDecrypted/style";
import edmsRegisterHelper from "helpers/edmsRegisterHelper";
import { useParams } from "react-router-dom";
import { actionHistoryConfig } from "./config";
import ReassignForm from "EDMSRegisterDocument/MessageCell/ReassignForm";
import RoundedButton from "Common/RoundedButton";

export default function InboxActionHistory({ data, reload, actionRequestId, fileMetaId, enableReassign, urgent }) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { type } = useParams();
  const [open, setOpen] = useState(true);
  const [showReassign, setShowReassign] = useState();
  const reassignData = { actionRequestId, fileMetaId, urgent };

  async function handleDelete(id) {
    try {
      const result = await edmsRegisterHelper.deleteMessage(id);
      if (result.error) throw new Error(result.error);
      reload();
    } catch (e) {
      console.log(e);
    }
  }

  function reloadAfterReassign() {
    reload();
    setShowReassign(false);
  }

  function toggleOpen(e) {
    setOpen(!open)
  }

  function handleShowReassign(e) {
    e.stopPropagation();
    setShowReassign(true);
  }

  return (
    data && <Accordion className={classes.details} expanded={open}>
      <AccordionSummary
        onClick={toggleOpen}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="action-history-content"
        id="action-history-header"
        classes={{
          content: classes.spaceBetween
        }}
      >
        <SectionTitle title={"Action History"} fontStyle={theme.typography.h4} />
        {(!showReassign && enableReassign) &&
          <RoundedButton width={100} margin="8px 4px" backgroundColor={theme.palette.primaryLight} onClick={handleShowReassign}>
            Re-assign
          </RoundedButton>
        }

      </AccordionSummary>
      <AccordionDetails style={{ flexDirection: "column", overflow: "auto" }}>
        <ViewTable data={data ?? []} config={actionHistoryConfig(handleDelete, type)} />
        {showReassign && <ReassignForm type={type} reload={reloadAfterReassign} rowData={reassignData} setShowReassign={setShowReassign} />}
      </AccordionDetails>
    </Accordion>
  );
}