import { Switch, Route, useHistory, Redirect } from "react-router-dom";
import PrivateRoute from "router/PrivateRoute";
import { useSelector, useDispatch } from "react-redux";

import { selectIsLoading } from 'redux/slice/loadingSlice';
import Loading from 'Common/Loading';
import { selectNotification } from "redux/slice/notificationSlice";
import NotificationPopup from "Common/NotificationPopup";
import MainLayout from "./MainLayout";
import Login from "Login";
import AddRelatedDoc from "Document/DocumentAddRelatedDocument";
import ResetPassword from "Login/ResetPassword";
import Verification from "Login/Verification";
import EDMS from "EDMS";
import EDMSDetailsView from "EDMS/EDMSDetailsView";
import EDMSFolder from "EDMS/EDMSFolder";
import EDMSFolderList from "EDMS/EDMSFolderList";
import EDMSFolderCreate from "EDMS/EDMSFolderCreate";
import EDMSHome from "EDMS/EDMSHome";
import EDMSFolderProperties from "EDMS/EDMSFolderProperties";
import EDMSFolderSummary from "EDMS/EDMSFolderSummary";
import EDMSRegisterDocument from "EDMSRegisterDocument";
import EditDocumentForm from "EDMSRegisterDocument/EditDocumentForm";
import UpdateDocumentList from "EDMSRegisterDocument/UpdateDocumentList";
import ViewDocumentList from "EDMSRegisterDocument/ViewDocumentList";
import DailyCheckReport from "Report/DailyCheckReport";
import UnscannedReport from "Report/UnscannedReport";
import Photos from "Photos";
import DocumentTypeSetup from "Setup/DocumentTypeSetup";
import DropdownOptionSetup from "Setup/DropdownOptionSetup";
import DocumentTypeSetupForm from "Setup/DocumentTypeSetup/DocumentTypeSetupForm";
import DropdownOptionsList from "Setup/DropdownOptionSetup/DropdownOptionsList";
import SIDetails from "Incident/SIDetails";
import Inbox from "EDMSRegisterDocument/Inbox";
import InboxDocumentDetails from "EDMSRegisterDocument/Inbox/InboxDocumentDetails";
import EDMSSearch from "EDMS/EDMSSearch";
import PhotoDocument from "Photos/PhotoDocument";
import { fetchConstVar, selectConstVar } from "redux/slice/permissionSlice";
import { useEffect } from "react";
import PhotoGalleryMonth from "Photos/PhotoGalleryMonth";
import PhotoGallerySearch from "Photos/PhotoGalleryMonth/PhotoGallerySearch";
import ChangePassword from "Settings/ChangePassword";

function Layout() {
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector(selectIsLoading);
  const notification = useSelector(selectNotification);
  const constVar = useSelector(selectConstVar);

  useEffect(() => {
    async function getConstVar() {
      dispatch(fetchConstVar());
    }
    if (!constVar?.id) getConstVar();
  }, []);

  return (
    <>
      {notification
        && <NotificationPopup type={notification.type} message={notification.message} />
      }
      <Loading isLoading={isLoading} />
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/reset-password">
          <ResetPassword />
        </Route>
        <Route path="/user-activation">
          <Verification />
        </Route>
        <Route path="/contract/:contractId/:type/document/:docId/related-documents">
          <div style={{ color: "white", overflow: "auto", boxSizing: "border-box" }}>
            <AddRelatedDoc isExternal={true} handleClose={() => console.log('confirm')} />
          </div>
        </Route>
        <PrivateRoute exact path="/contract/:contractId/:type(photo)">
          <MainLayout currentPage="Site Progress Photo">
            <Photos />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/:type(photo)/Search">
          <div style={{ padding: "24px", color: "white", display: "flex" }}>
            <PhotoGallerySearch />
          </div>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/:type(photo)/:dateString">
          <MainLayout currentPage="Site Progress Photo" overrideGoBack={(contractId, type) => `/contract/${contractId}/${type}`}>
            <PhotoGalleryMonth />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/:type(photo)/document/:docId">
          <MainLayout pageType={"document"}>
            <PhotoDocument />
          </MainLayout>
        </PrivateRoute>

        <PrivateRoute exact path="/contract/:contractId/:type(edms)/document/:docId">
          <MainLayout pageType={"document"}>
            <EDMSDetailsView />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/edms/:type/document/:docId">
          <MainLayout pageType={"document"}>
            <EDMSDetailsView />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/edms/:type(audit|ce|cp|csf|ewn|msf|pmi|quotation|rfi|rr)">
          <MainLayout>
            <EDMS />
          </MainLayout>
        </PrivateRoute>

        <PrivateRoute exact path="/contract/:contractId/edms/folder-list">
          <MainLayout>
            <EDMSFolderList />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/edms/folder/:folderId-:code">
          <MainLayout>
            <EDMSFolder />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/edms/folder/:folderId">
          <MainLayout>
            <EDMSFolder />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/edms/folder/:folderId/properties">
          <MainLayout>
            <EDMSFolderProperties />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/edms/folder-security-report">
          <EDMSFolderSummary />
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/edms/inbox/:type(action)">
          <MainLayout currentPage={"For Action"}>
            <Inbox />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/edms/inbox/:type(info)">
          <MainLayout currentPage={"For Info"}>
            <Inbox />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/edms/inbox/:type(sent)">
          <MainLayout currentPage={"Sent"}>
            <Inbox />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/edms/inbox/:type(action|info|sent)/document/:docId">
          <MainLayout pageType={"document"}>
            <InboxDocumentDetails currentPage={"EDMS"} />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/edms/inbox/:type(all-actions)">
          <MainLayout currentPage={"All Actions"}>
            <Inbox isAllAction={true} />
          </MainLayout>
        </PrivateRoute>

        <PrivateRoute exact path="/contract/:contractId/edms/register-document/:direction(in|out)">
          <MainLayout currentPage={"Document Registration"}>
            <EDMSRegisterDocument />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/edms/register-document/:direction(in|out)/update">
          <MainLayout currentPage={"Document Registration"}>
            <UpdateDocumentList />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/edms/register-document/:direction(in|out)/update/:id">
          <div style={{ padding: "24px", background: "#181935" }}>
            <EditDocumentForm />
          </div>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/edms/register-document/:direction(in|out)/view">
          <MainLayout currentPage={"Document Registration"}>
            <ViewDocumentList />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/:type(edms)/register-document/:direction(in|out)/view/document/:docId">
          <MainLayout currentPage={"EDMS"} pageType="document">
            <EDMSDetailsView />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/:type(report)/unscanned-report">
          <MainLayout currentPage={"Document Registration"}>
            <UnscannedReport />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/:type(report)/recently-check-report">
          <MainLayout currentPage={"Document Registration"}>
            <DailyCheckReport />
          </MainLayout>
        </PrivateRoute>

        <PrivateRoute exact path="/contract/:contractId/:type(report)/unscanned-report/document/:docId">
          <MainLayout currentPage={"EDMS"} pageType="document" overrideGoBack={(contractId, type) => `/contract/${contractId}/${type}/unscanned-report`}>
            <EDMSDetailsView />
          </MainLayout>
        </PrivateRoute>

        <PrivateRoute exact path="/contract/:contractId/:type(report)/recently-check-report/document/:docId">
          <MainLayout currentPage={"EDMS"} pageType="document" overrideGoBack={(contractId, type) => `/contract/${contractId}/${type}/recently-check-report`}>
            <EDMSDetailsView />
          </MainLayout>
        </PrivateRoute>

        <PrivateRoute exact path="/contract/:contractId/:type(edms)/folder/:folderId/document/:docId">
          <MainLayout pageType={"document"}>
            <EDMSDetailsView />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/edms/folder-create">
          <MainLayout currentPage={"Document Library"}>
            <EDMSFolderCreate mode="Add" />
          </MainLayout>
        </PrivateRoute>

        <PrivateRoute exact path="/contract/:contractId/:type(setup)/document-type">
          <MainLayout currentPage={"Administration"}>
            <DocumentTypeSetup />
          </MainLayout>
        </PrivateRoute>

        <PrivateRoute exact path="/contract/:contractId/:type(setup)/document-type/add">
          <MainLayout currentPage={"Administration"}>
            <DocumentTypeSetupForm type="add" />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/:type(setup)/document-type/edit/:id">
          <MainLayout currentPage={"Administration"}>
            <DocumentTypeSetupForm type="edit" />
          </MainLayout>
        </PrivateRoute>

        <PrivateRoute exact path="/contract/:contractId/:type(setup)/dropdown-option">
          <MainLayout currentPage={"Administration"}>
            <DropdownOptionSetup />
          </MainLayout>
        </PrivateRoute>

        <PrivateRoute exact path="/contract/:contractId/:type(setup)/dropdown-option/:optionType">
          <MainLayout currentPage={"Administration"} overrideGoBack={(contractId, type) => `/contract/${contractId}/setup/dropdown-option`}>
            <DropdownOptionsList />
          </MainLayout>
        </PrivateRoute>

        <PrivateRoute exact path="/contract/:contractId/:type(setup)/:optionType">
          <MainLayout currentPage={"Administration"}>
            <DropdownOptionsList />
          </MainLayout>
        </PrivateRoute>

        <PrivateRoute exact path="/contract/:contractId/:type(main-contractor)/document/:docId">
          <MainLayout pageType={"document"}>
            <SIDetails />
          </MainLayout>
        </PrivateRoute>

        <PrivateRoute exact path="/contract/:contractId/edms">
          <MainLayout currentPage={"Overview"}>
            <EDMSHome />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute path="/contract/:contractId/edms/search">
          <div style={{ padding: "24px", background: "#181935" }}>
            <EDMSSearch />
          </div>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/settings/change-password">
          <MainLayout currentPage={"Settings"}>
            <ChangePassword />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/">
          <Redirect to={{
            pathname: '/contract/1/edms',
          }} />
        </PrivateRoute>
        <PrivateRoute>
          <h1>Page Not Found</h1>
          <div style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => history.goBack()}>Go Back</div>
        </PrivateRoute>
      </Switch >
    </>
  );
}

export default Layout;