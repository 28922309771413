import { useTheme } from '@material-ui/core/styles';
import { Divider, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import decryptHelper from 'utils/decryptHelper';
import SectionTitle from 'Common/SectionTitle';
import InfoItem from 'Common/InfoItem';

import { useStyles } from "./style";
import React from 'react';
import tableHelper from 'utils/tableHelper';

export default function PhotoDecrypted({ data, padding = "unset", margin = "unset", photo }) {
  const theme = useTheme();
  const props = { theme, padding, margin };
  const classes = useStyles(props);
  if (!data.id) return "Loading"
  return (
    <div className={classes.container}>
      <SectionTitle title={"Details"} fontStyle={theme.typography.h4} />
      <Grid container spacing={5} className={classes.row}>
        <Grid item xs={12} md={8} className={classes.pdfWrapper}>
          {photo
            ? <img
              style={{ maxWidth: "100%" }}
              aria-label={"decrpytedDoc"}
              src={photo}
            />
            : <div style={{ textAlign: "center" }}></div>
          }
        </Grid>
        <Grid item sm={12} md={4} className={classes.details}>
          {decryptHelper.selectdecryptedRows("photos").map((row, idx) =>
            <React.Fragment key={idx}>
              <InfoItem title={row.name} content={tableHelper.renderContent(data[row.key], row)} flexDirection={"row"} />
              <Divider className={classes.divider} />
            </React.Fragment>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

PhotoDecrypted.propTypes = {
  data: PropTypes.object.isRequired,
  padding: PropTypes.string,
  margin: PropTypes.string,
  pdf: PropTypes.any,
};