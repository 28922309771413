import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  box: props => ({
    width: props.width ?? "unset",
    minWidth: 200,
    height: "100%",
    background: props.background ?? props.theme.palette.secondaryDark,
    borderRadius: "5px",
    padding: props.padding,
    margin: props.margin,
    border: props.isSelected ? `1px ${props.theme.palette.primaryLight} solid` : '1px solid transparent',
    boxShadow: props.isSelected ? "0px 0px 4px 5px rgba(47, 162, 200, 0.5)" : '0px 0px 4px rgba(0, 0, 0, 0.25)',
    '&:hover': {
      border: props.hover ? `1px ${props.theme.palette.primaryLight} solid` : '1px solid transparent',
      boxShadow: props.hover ? "0px 0px 4px 5px rgba(47, 162, 200, 0.5)" : '0px 0px 4px rgba(0, 0, 0, 0.25)',
    }
  }),
});
