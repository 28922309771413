import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { authHelper } from "helpers";

export const fetchConstVar = createAsyncThunk(
  'permission/fetchConstVar',
  async () => {
    const response = await authHelper.getConstantVar();
    return response?.constant_variable;
  }
)

const permissionSlice = createSlice({
  name: "permission",
  initialState: {
    userPermission: {},
    constVar: {},
  },
  reducers: {
    setPermission: (state, action) => {
      state.userPermission = action.payload;
    },
    setConstVar: (state, action) => {
      state.constVar = action.payload;
    }
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchConstVar.fulfilled, (state, action) => {
      // Add to the state array
      state.constVar = action.payload;
    })
  },
});

export const { setPermission, setConstVar, extra } = permissionSlice.actions;

export const selectUserPermission = (state => state.permission.userPermission);
export const selectConstVar = (state => state.permission.constVar);

export default permissionSlice.reducer;