import pdfHelper from "utils/pdfHelper";

export const photoDecryptedRows = [
  {
    name: "File Name",
    key: "photoDocument",
    render(item) {
      if (item && item.length > 0) {
        return item[0].filename;
      }
    }
  },
  {
    name: "Description",
    key: "photoDesc",
  },
  {
    name: "Location",
    key: "location1",
  },
  {
    name: "Taken by",
    key: "takenBy",
  },
  {
    name: "Taken at",
    key: "takenDatetime",
    type: "Datetime",
  },
  {
    name: "Upload by",
    key: "uploadBy",
  },
  {
    name: "Upload on",
    key: "uploadDatetime",
    type: "Datetime",
  },
]

export const incomingEdmsRows = [
  {
    name: "Log No.",
    key: "logNumber",
  },
  {
    name: "File Reference",
    key: "fileReference",
  },
  {
    name: "Doc Type",
    key: "docType",
  },
  {
    name: "In/Out",
    key: "inOut",
  },
  {
    name: "Letter Date",
    key: "letterDate",
    type: "Date",
  },
  {
    name: "Receive Date",
    key: "receiveDate",
    type: "Date",
  },
  {
    name: "Filing Date",
    key: "filingDate",
    type: "Date",
  },
  {
    name: "From Company",
    key: "fromCompany",
  },
  {
    name: "From Person",
    key: "fromPerson",
  },
  {
    name: "To Company",
    key: "toCompany",
  },
  {
    name: "To Person",
    key: "toPerson",
  },
  {
    name: "CC To Company",
    key: "ccToCompany",
  },
  {
    name: "Subject",
    key: "subject",
  },
  {
    name: "Action Required",
    key: "actionRequired",
    type: "Boolean"
  },
  {
    name: "Exclude Outstanding List",
    key: "excludeOutstandingList",
    type: "Boolean",
  },
  {
    name: "Letter Ref.",
    key: "letterRef",
  },
  {
    name: "File No.",
    key: "fileNumber",
  },
  {
    name: "Folio",
    key: "folio",
  },
  {
    name: "CC To File",
    key: "ccToFile",
  },
  {
    name: "Enclosure",
    key: "enclosure",
    type: "Boolean"
  },
  {
    name: "CD/DVD",
    key: "cdOrDvd",
  },
  {
    name: "Sketch / Drawing No.",
    key: "a1Drawing",
  },
  {
    name: "Others",
    key: "others",
  },
  {
    name: "Confidential",
    key: "confidential",
    type: "Boolean"
  },
  {
    name: "Prepared By",
    key: "preparedBy",
  },
  {
    name: "Drafter",
    key: "drafter",
  },
  {
    name: "remarks",
    key: "remarks",
  },
  // {
  //   name: "Replied By",
  //   key: "repliedBy",
  //   render: (repliedBy) => {
  //     console.log(repliedBy)
  //     if (!repliedBy || repliedBy?.length === 0) return "-";
  //     return pdfHelper.renderLetterRefLinkList(repliedBy);
  //   },
  // },
  {
    name: "Circulation",
    key: "circulation",
  },

  {
    name: "File Name",
    key: "fileName",
  },
  {
    name: "Size",
    key: "size",
  },
  {
    name: "Version",
    key: "version",
  },
  {
    name: "Last Update",
    key: "lastUpdate",
    type: "Date",
  },
  {
    name: "Edit History",
    key: "editHistory",
  },
];
export const outgoingEdmsRows = [
  {
    name: "Log No.",
    key: "logNumber",
  },
  {
    name: "File Reference",
    key: "fileReference",
  },
  {
    name: "Doc Type",
    key: "docType",
  },
  {
    name: "In/Out",
    key: "inOut",
  },
  {
    name: "Letter Date",
    key: "letterDate",
    type: "Date",
  },
  {
    name: "Dispatch Date",
    key: "receiveDate",
    type: "Date",
  },
  {
    name: "Filing Date",
    key: "filingDate",
    type: "Date",
  },
  {
    name: "From Company",
    key: "fromCompany",
  },
  {
    name: "From Person",
    key: "fromPerson",
  },
  {
    name: "To Company",
    key: "toCompany",
  },
  {
    name: "To Person",
    key: "toPerson",
  },
  {
    name: "CC To Company",
    key: "ccToCompany",
  },
  {
    name: "Subject",
    key: "subject",
  },
  {
    name: "Action Required",
    key: "actionRequired",
    type: "Boolean"
  },
  {
    name: "Exclude Outstanding List",
    key: "excludeOutstandingList",
    type: "Boolean",
  },
  {
    name: "Letter Ref.",
    key: "letterRef",
  },
  {
    name: "File No.",
    key: "fileNumber",
  },
  {
    name: "Folio",
    key: "folio",
  },
  {
    name: "CC To File",
    key: "ccToFile",
  },
  {
    name: "Enclosure",
    key: "enclosure",
    type: "Boolean"
  },
  {
    name: "CD/DVD",
    key: "cdOrDvd",
  },
  {
    name: "Sketch / Drawing No.",
    key: "a1Drawing",
  },
  {
    name: "Others",
    key: "others",
  },
  {
    name: "Confidential",
    key: "confidential",
    type: "Boolean"
  },
  {
    name: "Prepared By",
    key: "preparedBy",
  },
  {
    name: "Drafter",
    key: "drafter",
  },
  {
    name: "remarks",
    key: "remarks",
  },
  {
    name: "Circulation",
    key: "circulation",
  },
  {
    name: "File Name",
    key: "fileName",
  },
  {
    name: "Size",
    key: "size",
  },
  {
    name: "Version",
    key: "version",
  },
  {
    name: "Last Update",
    key: "lastUpdate",
    type: "Date",
  },
  {
    name: "Edit History",
    key: "editHistory",
  },
];