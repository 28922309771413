import { useParams } from 'react-router';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { DialogTitle, DialogContent, IconButton, Grid } from "@material-ui/core";
import CloseIcon from "static/icons/close.svg";

import { setNotification } from 'redux/slice/notificationSlice';
import RoundedButton from 'Common/RoundedButton';
import SectionTitle from 'Common/SectionTitle';
import fieldHelper from 'utils/fieldHelper';
import { setupHelper } from 'helpers';
import { useStyles } from "../style";
import { defaultFieldConfig, logNumberFieldConfig, userDefinedFieldConfig } from './config';
import CustCheckbox from 'Common/Checkbox';

export default function DropdownEditModal({ type, handleClose, reload, defaultData, title = "Option", docTypeList, }) {
  const classes = useStyles();
  const { optionType } = useParams();
  const [fieldConfig, setFieldConfig] = useState();
  const [defaultValues, setDefaultValues] = useState();

  useEffect(() => {
    assignDefaultValue();
    determineTableConfig();
  }, [])

  function assignDefaultValue() {
    if (optionType === "log-number") {
      setDefaultValues({
        ...defaultData,
        logNumber: padLeadingZero(defaultData.logNumber, 6),
      })
    } else {
      setDefaultValues(defaultData);
    }
  }

  function determineTableConfig() {
    switch (optionType) {
      case "log-number":
        setFieldConfig(logNumberFieldConfig);
        break;
      case "user-defined-field":
        setFieldConfig(userDefinedFieldConfig);
        break;
      default:
        setFieldConfig(defaultFieldConfig);
    }
  }

  function padLeadingZero(num, max) {
    return String(num).padStart(max, '0');
  }

  return (
    <>
      <DialogTitle>
        <SectionTitle
          title={`${type === "add" ? "Add" : `Edit`} ${title}`}
          extraComponents={
            <IconButton onClick={handleClose}>
              <img src={CloseIcon} className={classes.Icon} alt={"document"} />
            </IconButton>
          }
        />
      </DialogTitle>
      <DialogContent>
        {fieldConfig &&
          <FormContent
            docTypeList={docTypeList}
            fieldConfig={fieldConfig}
            defaultData={defaultValues}
            reload={reload}
            handleClose={handleClose}
            type={type}
          />
        }
      </DialogContent>
    </>
  );
}


function FormContent({ fieldConfig, defaultData, reload, handleClose, type, docTypeList }) {
  const dispatch = useDispatch();
  const { contractId, optionType } = useParams();
  const { register, handleSubmit, control, formState: { errors }, setValue, watch, setError, clearErrors } = useForm({
    defaultValues: defaultData ?? {}
  });

  async function createApi(data) {
    try {
      let result;
      switch (optionType) {
        case "company":
          result = await setupHelper.postCompany({ contractId, ...data });
          break;
        case "person":
          result = await setupHelper.postPerson({ contractId, ...data });
          break;
        case "user-defined-field":
          result = await setupHelper.postExtraField({ contractId, ...data });
          break;
        default:
          console.log("WIP", data);
          return false;
      }
      if (result.error) throw new Error(result.error);
      return true;
    } catch (e) {
      dispatch(setNotification({ type: "error", message: e.message ?? "Unknown error" }));
      console.log(e);
    }
  }

  async function patchApi(data) {
    try {
      let result;
      switch (optionType) {
        case "company":
          result = await setupHelper.patchCompany({ contractId, ...data });
          break;
        case "person":
          result = await setupHelper.patchPerson({ contractId, ...data, id: defaultData.id });
          break;
        case "log-number":
          result = await setupHelper.postLogNumber({ contractId, ...data });
          break;
        case "user-defined-field":
          result = await setupHelper.patchExtraField({ contractId, ...data, id: defaultData.id });
          break;
        default:
          console.log("WIP", data);
          return false;
      }
      if (result.error) throw new Error(result.error);
      return true;
    } catch (e) {
      dispatch(setNotification({ type: "error", message: e.message ?? "Unknown error" }));
      console.log(e);
    }
  }

  async function onSubmit(data) {
    try {
      let success;
      clearErrors();
      const isValid = manualValidation(data);
      if (!isValid) return;
      if (type === "add") success = await createApi(data);
      else success = await patchApi(data);
      if (reload && success) reload();
      if (success) handleClose();
    } catch (e) {
      dispatch(setNotification({ type: "error", message: e.message }));
      console.log(e)
    }
  }

  function onError(data) {
    dispatch(setNotification({ type: "error", message: "Some field(s) are missing." }))
  }

  function manualValidation(data) {
    if (optionType === "log-number") {
      if (!(/^[0-9]+$/.test(data.logNumber))) {
        setError("logNumber", { type: 'custom', message: 'Only number is allowed' });
        return false;
      }
    }
    return true;
  }

  function onChangeCheckbox(e, newValue, field, name, code) {
    try {
      let value = field.value ?? [];
      if (!newValue) {
        value = value.filter(p => p.toString() !== code);
        setValue(name, value);
      } else {
        value.push(code);
        setValue(name, value);
      }
    } catch (e) {
      console.log(e);
    }
  }

  function renderDocTypeCheckboxGroup() {
    return <Grid item xs={12} md={4}>
      <div style={{ color: "#B9B9B9", marginBottom: "8px" }}>
        {`Available in Doc Type(s)`}
      </div>

      <Controller
        name={"availableIn"}
        control={control}
        render={({ field }) =>
          <div style={{ height: 250, overflowY: "scroll" }}>
            {docTypeList.map((item, j) =>
              <div style={{ display: "flex", alignItems: "center" }} key={j}>
                <CustCheckbox
                  {...field}
                  defaultChecked={defaultData.availableIn && defaultData.availableIn.includes(item.code)}
                  customOnChange={(e, newValue) => onChangeCheckbox(e, newValue, field, `availableIn`, item.code)}
                />
                <div style={{ color: "#fff" }}>{item.name}</div>
              </div>
            )}
          </div>
        }
      />
    </Grid>
  }

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <Grid container spacing={3}>
        {fieldConfig.map((item, idx) =>
          fieldHelper.renderField(
            { label: item.label, name: item.name, isRequired: item.isRequired, prefix: item.prefix && item?.prefix(defaultData), type: item.type, options: item.options },
            idx, register, control, errors, { size: item.size, mdSize: item.mdSize })
        )}
        {optionType === "user-defined-field" &&
          renderDocTypeCheckboxGroup()
        }
        <Grid item sm={12} />
      </Grid>

      <Grid container item justify="flex-end">
        <RoundedButton backgroundColor="#70BAD3" type="submit">{type === "add" ? "Submit" : "Save"}</RoundedButton>
      </Grid>
    </form>
  );
}