import { useParams } from 'react-router';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { Grid, useTheme, useMediaQuery } from '@material-ui/core';

import { permissionHelper } from 'helpers';
import edmsRegisterHelper from 'helpers/edmsRegisterHelper';

import InboxInfoDetails from './InboxInfoDetails';
import InboxActionHistory from './InboxActionHistory';
import InboxReply from './InboxReply';
import InboxRelatedDoc from './InboxRelatedDoc';
import PDFViewer from 'Common/PDFViewer';

export default function InboxDocumentDetails() {
  const theme = useTheme();
  const { type, docId, contractId } = useParams();
  const { search } = useLocation();
  const searchParam = search.split("?tab=")[1];
  const [data, setData] = useState({});
  const [pdf, setPdf] = useState();
  const [error, setError] = useState();
  const [permission, setPermission] = useState();
  const smallerThanMD = !useMediaQuery(theme.breakpoints.up('md'));
  const pdfId = data?.fileMeta?.documents?.[data?.fileMeta?.documents?.length - 1]?.documentId;

  useEffect(() => {
    async function updateSeenStatus() {
      try {
        if (data.fileMeta) document.title = `${data.fileMeta.logNumber} | ${data.fileMeta.letterRef}`;
        if (data.actionRequestFor) {
          await edmsRegisterHelper.markAsRead({ actionRequestId: Number(data.actionRequestFor.actionRequestId), seen: true });
        }
      } catch (e) {
        console.log(e);
      }
    }
    updateSeenStatus();
  }, [data])

  useEffect(() => {
    try {
      initData();
    } catch (e) {
      console.log(e)
    }
  }, [type, docId])

  async function initData() {
    try {
      const result = await Promise.all([
        edmsRegisterHelper.getActionRequestDetails(docId),
        permissionHelper.checkUserAccessPermission(contractId),
      ])
      if (result[0].error) throw new Error(result[0].error);
      if (!result[0].details) throw new Error("No Data")

      setPermission(result[1]?.user_permissions)
      setData(result[0].details);
    } catch (e) {
      if (e.message) setError(e.message);
      console.log(e);
    }
  }

  function checkReassignPermission() {
    if (permission?.edms?.userType.includes("admin") || permission?.edms?.userId === data?.actionHistory?.[0]?.fromuserId) {
      return true;
    }
  }

  const returnDocumentContent = () => {
    switch (searchParam) {
      case "attachments":
        return "empty";
      case "history-log":
        return "empty";
      case "related-documents":
        return "empty";
      default:
        return (
          <div>
            <Grid container spacing={5} style={{ marginTop: "16px" }}>
              <Grid item xs={12} md={7} style={!smallerThanMD ? { height: "95vh" } : {}}>
                <PDFViewer id={pdfId} filename={data?.fileMeta?.logNumber} setPdf={setPdf} />
              </Grid>
              <Grid item xs={12} md={5} style={{height: "95vh", overflow: "auto"}}>
                {data?.fileMeta && <InboxInfoDetails data={data.fileMeta} permission={permission?.edms} pdf={pdf} />}
                {data?.actionHistory && <InboxActionHistory
                  data={data.actionHistory}
                  reload={initData}
                  fileMetaId={data?.fileMeta?.id}
                  actionRequestId={data?.actionRequestFor?.actionRequestId}
                  enableReassign={checkReassignPermission()}
                  urgent={data?.actionRequestFor?.urgent}
                />}
                {(data?.replyTree?.displayName) && <InboxReply data={data.replyTree} currentId={data?.fileMeta?.id} />}
                <InboxRelatedDoc data={data?.fileMeta?.relatedDocuments} />
              </Grid>
            </Grid>
          </div>
        );
    }
  }

  return (
    <div>
      {error
        ? <div style={{ color: "white" }}>{error}</div>
        : returnDocumentContent()
      }
    </div>
  );
}