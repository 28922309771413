import React, { useState, useEffect } from "react";
import { Grid, TextField } from "@material-ui/core";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { useTheme } from '@material-ui/core/styles';

import CustAutoComplete from "Common/CustAutoComplete";
import Select from "Common/Select";
import SectionTitle from "Common/SectionTitle";
import CustTextField from "Common/TextField";
import Button from "Common/RoundedButton";
import FilterDatePicker from "Common/FilterDatePicker";
import { useStyles } from "./style";
import { findFolderId } from "utils/dataUtils";

const filterAutocomplete = createFilterOptions();

function FilterPage({
  fieldsData,
  filter,
  setFilter,
  setUseFilter,
  setShowFilter,
  isEdmsSearch,
  fileOptions
}) {
  const filterItems = fieldsData.filter(item => !!item.id);
  const classes = useStyles();
  const theme = useTheme();
  const [form, setForm] = useState(filter);
  const [fileNumberInput, setFileNumberInput] = useState("");
  const [subFolderOptions, setSubFolderOptions] = useState([]);
  const [fileNoOptions, setFileNoOptions] = useState([]);
  const [searchingId, setSearchingId] = useState();

  // -- File Number -- //
  useEffect(() => {
    if (subFolderOptions.length > 0) setFileNoOptions(subFolderOptions)
    else setFileNoOptions(fileOptions)
  }, [fileOptions, subFolderOptions])
  useEffect(() => {
    if (typeof fileNumberInput === 'string') {
      findSubFolders();
    }
  }, [fileNumberInput]);

  async function findSubFolders() {
    const layer2IndicatorCount = (fileNumberInput.match(/\//g) || []).length;
    const layer3Indicator = (fileNumberInput.match(/\./g) || []).length === 1;
    if (fileNumberInput[fileNumberInput.length - 1] === "/") {
      const result = await findFolderId(fileNumberInput.substring(0, fileNumberInput.indexOf('/')), layer2IndicatorCount === 1, fileOptions, subFolderOptions, fileNumberInput, searchingId);
      if (result?.subFolders) {
        setSubFolderOptions(result.subFolders);
        setSearchingId(result.fileId);
      }
      return;
    }
    if (fileNumberInput[fileNumberInput.length - 1] === "." && layer3Indicator) {
      if (!layer2IndicatorCount) return;
      const result = await findFolderId(fileNumberInput.substring(0, fileNumberInput.indexOf('.')), false, fileOptions, subFolderOptions, fileNumberInput, searchingId);
      if (result?.subFolders) {
        setSubFolderOptions(result.subFolders);
        setSearchingId(result.fileId);
      }
      return
    }
    switch (layer2IndicatorCount) {
      case 0:
        setSubFolderOptions([]);
        setSearchingId();
        break;
      case 1:
        if (layer3Indicator) {
          findFolderId(fileNumberInput.substring(0, fileNumberInput.indexOf('/')), true, fileOptions, subFolderOptions, fileNumberInput, searchingId).then(v1 =>
            findFolderId(fileNumberInput.substring(0, fileNumberInput.indexOf('.')), false, fileOptions, v1.subFolders, fileNumberInput, v1.fileId).then(v2 => {
              if (v2?.subFolders) {
                setSubFolderOptions(v2.subFolders);
                setSearchingId(v2.fileId);
                return;
              }
            })
          )
        }
        else {
          const result = await findFolderId(fileNumberInput.substring(0, fileNumberInput.indexOf('/')), true, fileOptions, subFolderOptions, fileNumberInput, searchingId);
          if (result?.subFolders) {
            setSubFolderOptions(result.subFolders);
            setSearchingId(result.fileId);
          }
        }
    }
  }

  function handleFileNoOnChange(e) {
    setFileNumberInput(e ?? "")
    setForm({
      ...form,
      fileId: e?.value,
    })
  }
  // -- End of File Number -- //

  const handleChange = (e, id) => {
    setForm({
      ...form,
      [id]: e.target.value,
    })
  };
  const handleAutocompleteChange = (value, id) => {
    setForm({
      ...form,
      [id]: value,
    })
  }

  const handleDateChange = (e, id) => {
    let newForm = { ...form };
    const splitId = id.split(";");
    const isStart = splitId[1] === "start";
    if (!newForm[splitId[0]] && !newForm[splitId[1]]) newForm[splitId[0]] = [null, null];
    const targetField = newForm[splitId[0]];
    if (isStart) targetField[0] = e;
    else targetField[1] = e;
    setForm(newForm);
  }

  const handleApplyFilter = (e) => {
    e.preventDefault();
    setFilter(form);
    setUseFilter(true);
    setShowFilter(false);
  };

  const handleResetFilter = () => {
    let obj = { ...form };
    filterItems.forEach(item => {
      if (item.isDate || item.type === "Date") obj[item.id] = null
      else obj[item.id] = "";
    });
    setForm(obj);
  };

  function renderInputField(item) {
    if (item.type === "Select" || item.type === "Read") {
      return <Select
        label={item.label}
        options={item.options}
        getOptions={item.getOptions}
        value={form[item.id] ?? ""}
        insertBlank={item.insertBlank}
        handleChange={(e) => handleChange(e, item.id)} />
    }
    if (item.type === "Autocomplete") {
      return <Autocomplete
        id="combo-box-demo"
        options={item.options}
        getOptions={item.getOptions}
        value={form[item.id] ?? ""}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          if (option.label) {
            return option.label;
          }
          return option.label;
        }}
        disableClearable
        filterOptions={(options, params) => {
          const filtered = filterAutocomplete(options, params);
          if (filtered.length > 20) return filtered.slice(0, 20);
          return filtered;
        }}
        selectOnFocus
        blurOnSelect
        freeSolo
        onChange={(e, newValue) => {
          if (!newValue) handleAutocompleteChange("", item.id);
          else if (typeof newValue === 'string') {
            handleAutocompleteChange(newValue, item.id);
          } else if (newValue.value && newValue.inputValue) {
            // Create a new value from the user input
            handleAutocompleteChange(newValue, item.id);
          } else {
            handleAutocompleteChange(newValue.value, item.id);
          }
        }}
        renderInput={(params) =>
          <div style={{ height: "100%" }}>
            <div className={classes.label}>{item.label}</div>
            <div style={{ display: "flex" }}>
              <TextField
                onBlur={(e) => handleChange(e, item.id)}
                {...params}
                className={classes.textField}
              />
            </div>
          </div>
        }
      />
    }
    if (item.type === "fileNumber") {
      const field = { onChange: handleFileNoOnChange }
      return <CustAutoComplete
        multiple={false}
        label="File No."
        options={fileNoOptions}
        field={field}
        textSearch={true}
        isUppercase={true}
      />
    }

    return <div style={{
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    }}>
      <CustTextField
        label={item.label}
        handleChange={(e) => handleChange(e, item.id)}
        value={form[item.id]}
        trailingText={item.trailingText}
      />
    </div>
  }

  return (
    <form className={classes.root} onSubmit={handleApplyFilter}>
      <div>
        <SectionTitle title="Filter" />
        <Grid container spacing={3} className={classes.gridContainer}>
          {fieldsData.map((item, idx) => {
            if (item.type === "NoFilter") return null
            if (item.type === "placeholder") return <Grid item xs={4} key={idx} />
            if (item.id) {
              if (item.isDate || item.type === "Datetime" || item.type === "Date") {
                const startValue = form[item.id] && form[item.id][0] !== "" ? form[item.id][0] : null;
                const endValue = form[item.id] && form[item.id][1] !== "" ? form[item.id][1] : null;
                return (
                  <React.Fragment key={idx}>
                    <Grid item xs={12} style={{ padding: 0 }} />
                    {idx % 2 ? <div style={{ width: "100%" }}></div> : null}
                    <Grid item xs={6}>
                      <div className={classes.label}>From: {`${item.label}`}</div>
                      <FilterDatePicker
                        value={startValue}
                        id={`${item.id};start`}
                        handleChange={handleDateChange}
                        disableError={isEdmsSearch}
                      />
                    </Grid>
                    <Grid item xs={6}>

                      <div className={classes.label}>To: {`${item.label}`}</div>
                      <FilterDatePicker
                        value={endValue}
                        id={`${item.id};end`}
                        handleChange={handleDateChange}
                        disableError={isEdmsSearch}
                      />
                    </Grid>
                  </React.Fragment>
                );
              }
              return (
                <Grid key={idx} item xs={item.size ? item.size : 6}>
                  {renderInputField(item)}
                </Grid>
              );
            }
          })}
        </Grid>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          children="Reset"
          backgroundColor={theme.palette.primaryLight}
          onClick={handleResetFilter}
          style={{}}
        />
        <Button
          type="submit"
          children="Filter"
          backgroundColor={theme.palette.primaryLight}
          onClick={handleApplyFilter}
        />
      </div>
    </form>
  )
}

export default FilterPage;