import { useTheme } from '@material-ui/core/styles';
import MomentUtils from "@date-io/moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import PropTypes from 'prop-types';

import { useStyles } from "./style";

export default function DateRangeSelect({ handleChange, value, id, disableError }) {
  const theme = useTheme();
  const props = { theme };
  const classes = useStyles(props);
  return (
    <div className={classes.root}>
      <div className={classes.column}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <span>
            {disableError
              ? <DatePicker
                autoOk
                value={value}
                format="yyyy-MM-DD"
                className={classes.datePicker}
                disableToolbar
                variant="inline"
                onChange={date => handleChange(date, id)}
                error={false}
                helperText={null}
              />
              : <DatePicker
                autoOk
                value={value}
                format="yyyy-MM-DD"
                className={classes.datePicker}
                disableToolbar
                variant="inline"
                onChange={date => handleChange(date, id)}
              />
            }
          </span>
        </MuiPickersUtilsProvider>
      </div>
    </div>
  );
}

DateRangeSelect.propTypes = {
  startDate: PropTypes.any,
  endDate: PropTypes.any,
  handleOnChange: PropTypes.func,
};