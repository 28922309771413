import { useParams, useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Grid } from '@material-ui/core';

import fieldHelper from 'utils/fieldHelper';
import CommonContainer from 'EDMS/CommonContainer';
import SectionTitle from 'Common/SectionTitle';
import Button from "Common/RoundedButton";
import { setNotification } from 'redux/slice/notificationSlice';
import { setLoading } from 'redux/slice/loadingSlice';

import BrowseDocument from './BrowseDocument';
import edmsRegisterHelper from 'helpers/edmsRegisterHelper';

export default function EDMSRegisterDocument() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { direction, contractId } = useParams();
  const [isDuplicated, setIsDuplicated] = useState(false)
  const { watch, register, handleSubmit, control, formState: { errors }, reset } = useForm({
    defaultValues: {
      file: null,
      logNumber: null,
      subject: null,
    }
  });

  useEffect(() => {
    async function init() {
      const result = await edmsRegisterHelper.generateLogNumber({ contractId, inOut: direction });
      reset({
        logNumber: result.logNumber,
        subject: "",
        file: null,
        letterRef: direction === "in" ? "CWFWSJV/1086/" : "MW/(IS/2022/02)/"
      });
    }
    init();
  }, [direction, contractId, reset]);

  async function onSubmit(data) {
    try {
      const request = constructRegisterRequest(data);
      const result = await edmsRegisterHelper.createEDMSDocuments(request);
      if (result.error) throw new Error(result.error);
      if (result?.file_meta?.id) {
        if (data.file?.[0]) {
          await edmsRegisterHelper.uploadDocumentFile(result.file_meta.id, { file: data.file[0] });
        }
        history.push(`/contract/${contractId}/edms/register-document/${direction}/update`);
      }

    } catch (e) {
      dispatch(setNotification({ type: "error", message: e.message }));
      setLoading(false);
      console.log(e)
    }
  }

  function constructRegisterRequest(formData) {
    const request = {
      contractId,
      fileMeta: {
        ...formData,
        inOut: direction.toUpperCase(),
      }
    }
    return request;
  }

  function onError(data) {
    dispatch(setNotification({ type: "error", message: "Some field(s) are missing." }))
  }
  async function checkDuplicate(e) {
    try {
      const inputtedLetterRef = e.target.value
      const result = await edmsRegisterHelper.checkLetterRefDuplicate({ letterRef: inputtedLetterRef })
      if (result.duplicated) setIsDuplicated(result)
      else setIsDuplicated(false)
    } catch (error) {

    }
  }
  const watchFile = watch('file');


  return (
    <CommonContainer withInfoHeader={true}>

      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SectionTitle title={`New ${direction === "in" ? "Incoming" : "Outgoing"} Document`} />
          </Grid>
          {fieldHelper.renderField(
            { label: "Log Number", name: "logNumber", isRequired: true, },
            0, register, control, errors)
          }
          <Grid item xs={12} sm={6}></Grid>
          {fieldHelper.renderField(
            { label: "Letter Ref.", name: "letterRef", isRequired: true },
            1, register, control, errors, { size: 6, onBlur: checkDuplicate })
          }
          {isDuplicated &&
            <><Grid item xs={12} sm={6} style={{ color: "red", display: "flex", alignItems: "end" }}>Duplicated: {isDuplicated.data}</Grid></>
          }
          <Grid item xs={12} sm={6}></Grid>
          {fieldHelper.renderField(
            { label: "Subject", name: "subject" },
            2, register, control, errors, { size: 12 })
          }

          <Grid item xs={12}>
            <SectionTitle title={`Browse Main Document from PC`} />
          </Grid>

          <BrowseDocument control={control} register={register} errors={errors} name={"file"} watchFile={watchFile} required={false}></BrowseDocument>

          <Grid item xs={12} sm={12}></Grid>

          <Grid container item justify="flex-end">
            <Button children="Save" backgroundColor="#70BAD3" type="submit" />
          </Grid>

        </Grid>
      </form>
    </CommonContainer>
  );
}