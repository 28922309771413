import tableHelper from "./tableHelper";

export function convertSearchParamsToObject(sp) {
  if (!sp) return;
  var search = sp.substring(1);
  let tempResult = JSON.parse(
    '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
    function (key, value) {
      return key === "" ? value : decodeURIComponent(value)
    }
  )
  const keys = Object.keys(tempResult).filter(i => i.toLowerCase().includes("date"));
  keys.forEach((key, i) => {
    tempResult[key] = tempResult[key].split(".");
  })
  const result = { ...tempResult };
  return result;
}

export function constructFilterObject(variables, formatDateArray) {
  if (typeof variables !== 'object' || variables === null) return;

  let filterVariable = { ...variables };
  const filterKeys = Object.keys(variables);
  filterKeys.forEach((key) => {
    if (filterVariable[key] === null || filterVariable[key] === "") {
      delete filterVariable[key];
    }
    if (formatDateArray) {
      if (Array.isArray(variables[key])) {
        console.log(variables[key])
        filterVariable[key] = tableHelper.handleDateTimeFilter(filterVariable[key])
      }
    }
  })
  return filterVariable;
}