import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { setNotification } from 'redux/slice/notificationSlice';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function NotificationPopup({
  type,
  message,
  position = { vertical: "top", horizontal: "center" },
}) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(!!type);

  const { vertical, horizontal } = position;

  useEffect(() => {
    if (!!type && type !== "") {
      setOpen(true)
    }
  }, [type])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    setTimeout(() => {
      dispatch(setNotification({ type: undefined, message: "" }));
    }, 1000)
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: position.vertical, horizontal: position.horizontal }}
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
      key={position.vertical + position.horizontal}
    >
      <Alert onClose={handleClose} severity={type}>
        {message}
      </Alert>
    </Snackbar>
  );
}

export default NotificationPopup;
