import { Drawer, useTheme } from "@material-ui/core";
import { useEffect, useState } from 'react';
import { photoHelper } from 'helpers';
import { Link, useParams } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import FilterListIcon from '@material-ui/icons/FilterList';
import queryString from 'query-string';

import Button from "Common/EllipseButton";
import IconButton from "Common/IconButton";
import FilterPage from "Common/FilterPage";
import Modal from "Common/Modal";
import PhotoAdd from "./PhotoAdd";
import CommonContainer from 'EDMS/CommonContainer';
import SectionTitle from 'Common/SectionTitle';
import folderLogo from 'static/icons/folder.png';
import { photoConfig } from "./PhotoGalleryMonth/config";
import { useStyles } from "./style";
import { constructFilterObject } from 'utils/filterHelper';
import { selectUserPermission } from 'redux/slice/permissionSlice';
import moment from "moment";
import { useSelector } from "react-redux";
import permissionUtils from "utils/permissionUtils";

export default function Photos() {
  const { contractId } = useParams();
  const accessPermission = useSelector(selectUserPermission);
  const hasEditPermission = permissionUtils.checkPermission(accessPermission, "photo", "edit");
  const theme = useTheme();
  const classes = useStyles(theme);

  const [data, setData] = useState([]);
  const [filter, setFilter] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [useFilter, setUseFilter] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [count, setCount] = useState(12);

  useEffect(() => {
    init();
  }, [])

  async function init() {
    try {
      let result = await photoHelper.getPhotoMonths({ contractId, orderBy: "yyyyMm.desc" });
      if (result.error) throw new Error(result.error);
      if (result?.data?.length === 0) {
        result.data.push(moment().format("YYYY-MM"));
      }
      setData(result?.data);
    } catch (e) {
      console.log(e);
    }
  }

  function handleFilterChange(e) {
    const filtered = constructFilterObject(e);
    setFilter(filtered);
    const filteredSubmit = constructFilterObject(e, true);
    let searchString = queryString.stringify(filteredSubmit);
    window.open(`/contract/${contractId}/photo/Search?${searchString}`, "photoSearchResult", `popup, width=${window.screen.width}, height=${window.screen.width}`)
  }

  const handleOpenFilter = () => {
    setShowFilter(!showFilter);
  };

  function handleAddForm() {
    setModalOpen(true);
  };

  function reload() {
    init();
  }

  return (
    <CommonContainer>
      <SectionTitle title="Site Progress Photo"
        extraComponents={<div className={classes.buttonsContainer}>
          <div style={{ marginRight: "16px" }}>
            {!hasEditPermission
              ? <></>
              : <Button
                width="125px"
                label="Add Photo"
                startIcon={<AddIcon />}
                onClick={handleAddForm}
              />
            }
          </div>
          <IconButton
            icon={<FilterListIcon />}
            onClick={handleOpenFilter}
          />
        </div>
        }
      />
      <div className={classes.body}>
        {(data && data.length) && data.map((item, idx) => <FolderRow date={item} key={idx} />)}
      </div>
      <Drawer
        anchor="right"
        open={showFilter}
        onClose={() => setShowFilter(false)}
        classes={{
          paperAnchorRight: classes.drawer
        }}
      >
        <FilterPage fieldsData={photoConfig()} setFilter={handleFilterChange} {...{ filter, setUseFilter, setShowFilter }} />
      </Drawer>
      <Modal open={modalOpen} handleClose={() => setModalOpen(false)}>
        <PhotoAdd handleClose={() => setModalOpen(false)} reload={reload} />
      </Modal>
    </CommonContainer>
  );
};

function FolderRow({ date }) {
  const classes = useStyles();
  const { contractId } = useParams();

  return (
    <Link className={classes.folderRow} to={`/contract/${contractId}/photo/${date}`}>
      <img className={classes.icon} src={folderLogo} alt={"folder"} />
      <span>{date}</span>
    </Link>
  );
}