import axios from './apiHelper';

const dashboardHelper = {
  countEdmsDashboard: async (variable) => {
    try {
      const result = await axios.get(`/dashboard/file-meta/total`, { params: variable });

      if (!result.data) {
        throw new Error("Fail to get edms dashboard data");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 404)
          console.log(e.response)
        return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  edmsPieChartDashboard: async (variable) => {
    try {
      const result = await axios.get(`/dashboard/file-meta/pie-chart`, { params: variable });

      if (!result.data) {
        throw new Error("Fail to get edms dashboard data");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 404)
          console.log(e.response)
        return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
}

export default dashboardHelper;