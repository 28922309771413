import { makeStyles } from '@material-ui/core/styles';
const drawerWidth = 320;
export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    background: theme.palette.primaryDark,
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    color: theme.palette.text.primary,
  },
  appBar: {
    display: "flex",
    justifyContent: "space-between",
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: theme.palette.primaryDark,
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  divider: {
    background: 'white',
    marginRight: "15px",
  },
  rowItem: {
    display: "flex",
    alignItems: "center",
  },
  logo: {
    width: "36px",
    height: "36px",
    cursor: "pointer",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerRight: {
    backgroundColor: theme.palette.secondaryDark,
    overflowX: "hidden"
  },
  drawerPaper: {
    width: drawerWidth,
    background: theme.palette.sidebarBg,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'space-between',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    width: "100%",
  },
  contentShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    background: theme.palette.primaryDark,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  icon: {
    color: "white",
  }
}));
