
import { Fragment } from "react";
import { useParams } from "react-router-dom";
import { useFieldArray, Controller, useWatch } from "react-hook-form";
import clsx from "clsx";
import { ErrorMessage } from '@hookform/error-message';
import { Grid, Divider, IconButton, TextField } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

import { useStyles } from "./style";

export default function MultipleSelectList({
	name, options, control, errors, label, config, setValue, // for edit
	readOnly, data, // For display
}) {
	const { contractId } = useParams();
	const classes = useStyles();
	if (readOnly) {
		return <div style={{ width: '100%' }}>
			<Grid container item xs={12}>
				{label && <Grid item xs={12} className={classes.label}>{label}</Grid>}
				{config.map((col, idx) =>
					<Grid item xs={col?.xs ?? 4} className={clsx(classes.label, classes.rowBetween)} key={idx}>
						{col.label}
					</Grid>
				)}
				<Grid item xs={2} sm={2} />
				<Grid item xs={12}><Divider className={classes.divider} /></Grid>

				{data.length > 0 &&
					data.map((item, rowIdx) => {
						return (
							<Fragment key={rowIdx}>
								{config.map((col, colIdx) =>
									<Grid item key={colIdx} className={clsx(classes.displayLabel, classes.rowBetween)} xs={col?.xs ?? 4}>
										{col.render ? col.render(item, contractId) : (item?.[col.id] ?? "")}
									</Grid>
								)}

								<Grid item xs={12}><Divider className={classes.divider} /></Grid>
							</Fragment>
						)
					})}
			</Grid>
		</div >
	}
	return (
		<MultipleSelectListForm
			name={name}
			options={options}
			control={control}
			errors={errors}
			label={label}
			config={config}
			setValue={setValue}
		/>
	);

};


function MultipleSelectListForm({ name, options, control, errors, label, config, setValue }) {
	const classes = useStyles();
	const { fields, remove, append } = useFieldArray({
		control,
		name: `${name}`,
		keyName: "_id",
	});
	const fieldWatch = useWatch({
		control,
		name: name,
	});

	const appendRowShape = createAppendRow();

	function createAppendRow() {
		let row = {};
		config.forEach((item, i) => {
			if (item.isInput) {
				row[item.id] = undefined;
			}
		})
		return row;
	}

	function onChange(e, newValue, field, name) {
		if (!newValue) {
			setValue(`${name}`, null)
		} else {
			setValue(`${name}`, newValue)
		}
	}
	function renderRow(col, colIdx, rowIdx) {
		if (colIdx === 0) {
			return <Grid item xs={col?.xs ?? 4} key={colIdx}>
				<Controller
					name={`${name}[${rowIdx}].${col.id}`}
					control={control}
					rules={{ required: `${config[colIdx].label} is Required` }}
					defaultValue={(fieldWatch && fieldWatch[rowIdx]) ? fieldWatch[rowIdx][col.id] : undefined}
					render={({ field }) =>
						<Autocomplete
							id="combo-box-demo"
							defaultValue={(fieldWatch && fieldWatch[rowIdx]) ? fieldWatch[rowIdx][col.id] : undefined}
							options={options ?? []}
							// groupBy={(option) => option.type}
							getOptionLabel={(option) => option.label}
							getOptionSelected={(option, value) => option.label === value.label}
							onChange={(e, newValue) => onChange(e, newValue, field, `${name}[${rowIdx}].${col.id}`)}
							renderInput={(params) =>
								<div style={{ display: "flex" }}>
									<TextField
										{...params}
										className={classes.textField}
									/>
								</div>}
						/>}
				/>
				<ErrorMessage
					errors={errors}
					name={`${name}[${rowIdx}].${col.id}`}
					render={({ message }) => <p style={{ color: 'red' }}>{message}</p>}
				/>
			</Grid>
		} else {
			return <Grid item key={colIdx} className={clsx(classes.label, classes.rowBetween)} xs={col?.xs ?? 4}>
				{fieldWatch?.[rowIdx]?.[col.lookupKey]?.[col.id] ?? ""}
			</Grid>
		}
	}

	return (
		<div style={{ width: '100%', marginTop: 12 }}>
			<Grid container item xs={12}>
				<Grid item xs={12} className={classes.label}>{label}</Grid>
				{config.map((col, idx) =>
					<Grid item xs={col?.xs ?? 4} className={clsx(classes.label, classes.rowBetween)} key={idx}>
						{col.label}
					</Grid>
				)}
				<Grid item xs={2} sm={2}>
					<IconButton
						color="default"
						component="span"
						onClick={() => {
							append(appendRowShape);
						}}>
						<AddCircleRoundedIcon />
					</IconButton>
				</Grid>

				<Grid item xs={12}><Divider className={classes.divider} /></Grid>

				{fields.length > 0 &&
					fields.map((item, i) => {
						return (
							<Fragment key={item._id}>
								{config.map((col, colIdx) => renderRow(col, colIdx, i))}
								<Grid item xs={2} className={classes.rowBetween}>
									<div style={{ height: "100%" }}>
										<IconButton
											color="default"
											component="span"
											onClick={() => {
												remove(i);
											}}>
											<RemoveCircleOutlineIcon />
										</IconButton>
									</div>
								</Grid>
								<Grid item xs={12}><Divider className={classes.divider} /></Grid>
							</Fragment>
						)
					})}
			</Grid>
		</div >
	);
}