import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: props => ({
    width: "100%",
    color: "#fff",
    padding: props.padding,
    margin: props.margin,
    borderBottom: "1px solid #CACACA",
  }),
  body: {
    backgoround: theme.palette.primaryDark,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    position: 'relative',
  },
  drawer: {
    backgroundColor: theme.palette.secondaryDark,
    overflowX: "hidden"
  },
  buttonsContainer: {
    marginTop: 16,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  notFound: {
    color: "white",
  }
}));
