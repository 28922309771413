import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useForm } from 'react-hook-form';
import { useTheme } from "@material-ui/core";

import { setNotification } from "redux/slice/notificationSlice";
import { permissionHelper, edmsRegisterHelper, authHelper } from "helpers";
import fieldHelper from "utils/fieldHelper";
import ConfirmButton from "Common/ConfirmButton";
import RoundedButton from "Common/RoundedButton";
import { constructActionRequestArr } from "utils/dataUtils";


export default function ReassignForm({ type, reload, rowData, setShowReassign }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [userNGroup, setUserNGroup] = useState([]);
  const { register, handleSubmit, control, formState: { errors }, } = useForm({
    defaultValues: {
      message: "",
      forAction: []
    }
  });

  useEffect(() => {
    async function getUserGroupInfo() {
      try {
        const result = await Promise.all([
          permissionHelper.getUsers({ orderBy: "firstName.asc" }),
          permissionHelper.getGroups({ orderBy: "firstName.asc" }),
        ]);
        const combinedArr = result[0].user.map(v => ({ id: v.id, type: "User", label: `${v.firstName} ${v.lastName}` })).concat(result[1].group.map(v => ({ id: v.id, type: "Group", label: v.name })));
        setUserNGroup(combinedArr);
      } catch (e) {
        console.log(e);
      }
    }
    getUserGroupInfo();
  }, []);

  function constructRequest(data, userInfo) {
    const actionRequestForArr = constructActionRequestArr(data);
    const request = {
      actionRequestId: rowData.actionRequestId,
      fileMetaId: rowData.fileMetaId,
      message: data.message,
      actionRequestMessage: [
        {
          onBehalfOf: `${userInfo.user[0].firstName} ${userInfo.user[0].lastName}`,
          content: data.message,
          isDraft: false
        }
      ],
      actionRequestFor: actionRequestForArr,
    };
    return request;
  }

  async function onSubmit(data) {
    try {
      if (data?.forAction?.length > 0 || data?.forInfo?.length > 0) {
        const userInfo = await authHelper.getMyInfo();
        let actionResult;
        if (userInfo.error || userInfo?.user?.length < 1) throw new Error({ message: "User Info not Found" });
        const actionRequest = constructRequest(data, userInfo);
        if (!rowData?.actionRequestId) actionResult = await edmsRegisterHelper.postActionRequest(actionRequest);
        else actionResult = await edmsRegisterHelper.reassignActionRequest(actionRequest);
        if (actionResult.error) throw new Error(actionResult.error);
        reload();
      }
    } catch (e) {
      dispatch(setNotification({ type: "error", message: e.message }));
      setLoading(false);
      console.log(e)
    }
  }

  function onError(data) {
    dispatch(setNotification({ type: "error", message: "Some field(s) are missing." }))
  }

  return (
    (userNGroup && userNGroup.length > 0) &&
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      {(type !== "info") &&
        fieldHelper.renderField(
          { label: "For Action", name: "forAction", isRequired: false, type: "AutoComplete", options: userNGroup, multiple: true, },
          0, register, control, errors, { size: 12 })
      }
      {(type !== "action") &&
        fieldHelper.renderField(
          { label: "For Info", name: "forInfo", isRequired: false, type: "AutoComplete", options: userNGroup, multiple: true, },
          1, register, control, errors, { size: 12 })
      }
      {fieldHelper.renderField(
        { label: "Message", name: "message", isRequired: false, row: 4, },
        2, register, control, errors, { size: 12 })
      }
      <div style={{ marginTop: 8 }}>
        <ConfirmButton width={80} onClick={handleSubmit((d) => onSubmit(d))} backgroundColor={theme.palette.primaryLight} message={"Confirm to Submit?"}>Submit</ConfirmButton>
        <RoundedButton width={80} margin={"8px"} backgroundColor={theme.palette.primaryLight} onClick={() => setShowReassign(false)}>Cancel</RoundedButton>
      </div>
    </form>
  );
}