import { configureStore, combineReducers, getDefaultMiddleware } from "@reduxjs/toolkit";
import modalViewSlice from "./slice/modalViewSlice";
import userSlice from "./slice/userSlice";
import navigationSlice from "./slice/navigationSlice";
import docDataSlice from "./slice/docDataSlice";
import loadingSlice from "./slice/loadingSlice";
import notificationSlice from './slice/notificationSlice';
import contractSlice from './slice/contractSlice';
import permissionSlice from './slice/permissionSlice';

const reducer = combineReducers({
  modalView: modalViewSlice,
  user: userSlice,
  navigation: navigationSlice,
  docData: docDataSlice,
  loading: loadingSlice,
  notification: notificationSlice,
  contract: contractSlice,
  permission: permissionSlice
});

const store = configureStore({
  reducer: reducer,
  middleware: [...getDefaultMiddleware({ thunk: true })]
});

export default store;