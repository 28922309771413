import { useParams } from 'react-router';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { Grid, useTheme, useMediaQuery } from '@material-ui/core';
import { edmsFileHelper, permissionHelper } from 'helpers';

import InboxInfoDetails from 'EDMSRegisterDocument/Inbox/InboxInfoDetails';
import InboxActionHistory from 'EDMSRegisterDocument/Inbox/InboxActionHistory';
import InboxReply from 'EDMSRegisterDocument/Inbox/InboxReply';
import InboxRelatedDoc from 'EDMSRegisterDocument/Inbox/InboxRelatedDoc';
import PDFViewer from 'Common/PDFViewer';

export default function EDMSDetailsView() {
  const { type, docId, contractId } = useParams();
  const { search } = useLocation();
  const searchParam = search.split("?tab=")[1];
  const theme = useTheme();
  const [data, setData] = useState({});
  const [error, setError] = useState();
  const [permission, setPermission] = useState();
  const [pdf, setPdf] = useState();
  const smallerThanMD = !useMediaQuery(theme.breakpoints.up('md'));
  const pdfId = data?.documents?.[data.documents.length - 1]?.documentId;

  useEffect(() => {
    try {
      initData();
    } catch (e) {
      console.log(e)
    }
  }, [type, docId])

  useEffect(() => {
    if (data?.logNumber) document.title = `${data.logNumber} | ${data.letterRef}`;
  }, [data])

  async function initData() {
    try {
      const result = await edmsFileHelper.getFileMeta(docId);
      const permissionResult = await permissionHelper.checkUserAccessPermission(contractId);
      if (result.error) throw new Error(result.error);
      setData(result);
      setPermission(permissionResult?.user_permissions);
    } catch (e) {
      if (e.message) setError(e.message);
      console.log(e);
    }
  }

  function checkReassignPermission() {
    if (permission?.edms?.userType.includes("admin") || permission?.edms?.userId === data?.actionHistory?.[0]?.fromUserId) {
      return true;
    }
  }

  const returnDocumentContent = () => {
    switch (searchParam) {
      case "attachments":
        return "empty";
      case "history-log":
        return "empty";
      case "related-documents":
        return "empty";
      default:
        return (
          <div>
            <Grid container spacing={5} style={{ marginTop: "16px" }}>
              <Grid item xs={12} md={7} style={!smallerThanMD ? { height: "94vh" } : {}}>
                <PDFViewer id={pdfId} filename={data?.logNumber} setPdf={setPdf} />
              </Grid>
              <Grid item xs={12} md={5} style={{ height: "95vh", overflow: "auto" }}>
                {data && <InboxInfoDetails data={data} permission={permission?.edms} pdf={pdf} />}
                {data?.actionHistory && <InboxActionHistory
                  data={data.actionHistory}
                  reload={initData}
                  fileMetaId={data?.id}
                  actionRequestId={data?.actionHistory?.[0]?.actionRequestId}
                  enableReassign={checkReassignPermission()}
                  urgent={data?.actionHistory?.[0]?.urgent}
                />}
                {(data?.replyTree?.displayName) && <InboxReply data={data.replyTree} currentId={data.id} />}
                <InboxRelatedDoc data={data.relatedDocuments} />
              </Grid>
            </Grid>
          </div>
        );
    }
  }

  return (
    <div>
      {error
        ? <div style={{ color: "white" }}>{error}</div>
        : returnDocumentContent()
      }
    </div>
  );
}