import axios from './apiHelper';

const setupHelper = {
  getExtraFields: async (variables) => {
    try {
      const result = await axios.get(`/file-meta-extra-fields`, { params: variables });

      if (!result.data) {
        throw new Error(`Fail to get extra fields`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  postExtraField: async (variables) => {
    try {
      const result = await axios.post(`/file-meta-extra-field`, variables);

      if (!result.data) {
        throw new Error(`Fail to post file-meta-extra-field`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  patchExtraField: async (variables) => {
    try {
      const result = await axios.patch(`/file-meta-extra-field/${variables.id}`, variables);

      if (!result.data) {
        throw new Error(`Fail to patch file-meta-extra-field`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  deleteExtraField: async (id) => {
    try {
      const result = await axios.delete(`/file-meta-extra-field/${id}`);

      if (!result.data) {
        throw new Error(`Fail to delete file-meta-extra-field`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getLogNumbers: async (variables) => {
    try {
      const result = await axios.get(`/setup-log-numbers`, { params: variables });

      if (!result.data) {
        throw new Error(`Fail to get setup log numbers`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  postLogNumber: async (variables) => {
    try {
      const result = await axios.post(`/setup-log-number`, variables);

      if (!result.data) {
        throw new Error(`Fail to post log number`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getPersons: async (variables) => {
    try {
      const result = await axios.get(`/persons`, { params: variables });

      if (!result.data) {
        throw new Error(`Fail to get persons`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  postPerson: async (variables) => {
    try {
      const result = await axios.post(`/person`, variables);

      if (!result.data) {
        throw new Error(`Fail to post person`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  patchPerson: async (variables) => {
    try {
      const result = await axios.patch(`/person/${variables.id}`, variables);

      if (!result.data) {
        throw new Error(`Fail to patch person`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  deletePerson: async (id) => {
    try {
      const result = await axios.delete(`/person/${id}`);

      if (!result.data) {
        throw new Error(`Fail to delete person`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getCompanies: async (variables) => {
    try {
      const result = await axios.get(`/companies`, { params: variables });

      if (!result.data) {
        throw new Error(`Fail to get companies`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  postCompany: async (variables) => {
    try {
      const result = await axios.post(`/company`, variables);

      if (!result.data) {
        throw new Error(`Fail to post company`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  patchCompany: async (variables) => {
    try {
      const result = await axios.patch(`/company/${variables.id}`, variables);

      if (!result.data) {
        throw new Error(`Fail to patch company`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  deleteCompany: async (id) => {
    try {
      const result = await axios.delete(`/company/${id}`);

      if (!result.data) {
        throw new Error(`Fail to delete company`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
}

export default setupHelper;