import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    alignItems: "center",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 12,
  },
  imgWrapper: {
    height: "200px",
    overflow: "hidden",
    textAlign: "center",
    marginRight: "4px",
    display: "flex",
    alignItems: "center"
  },
  img: {
    maxHeight: "100%",
    maxWidth: "100%"
  },
}));
