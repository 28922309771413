import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    backgoround: theme.palette.primaryDark,
    minHeight: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    position: 'relative',
  },
  body: {
    marginTop: "32px",
  },
  folderRow: {
    margin: "8px 0",
    fontSize: 16,
    display: "flex",
    alignItems: "center",
    color: theme.palette.primaryLight,
    fontWeight: 600,
    cursor: "pointer",
  },
  icon: {
    width: "24px",
    marginRight: "8px",
  },
  drawer: {
    backgroundColor: theme.palette.secondaryDark
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
}));
