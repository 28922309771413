import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useTheme } from '@material-ui/core/styles';
import { Grid, Divider } from '@material-ui/core';
import { useSelector } from 'react-redux';
import moment from 'moment';

import CommonContainer from 'EDMS/CommonContainer';
import ChartHeader from 'Common/ChartHeader';
import { dashboardHelper } from 'helpers';
import RISCInfoBox from "RISC/RISCInfoBox";

import DocTypeSummaryPieChart from './docTypeSummaryPieChart';
import DocTypeSummaryList from './docTypeSummaryList';
import { useStyles } from "./style";

export default function EDMSDashboard() {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { contractId } = useParams();
  const [data, setData] = useState({});
  const [pieData, setPieData] = useState([]);
  const [startDate, setStartDate] = useState(moment().startOf('year'));
  const [endDate, setEndDate] = useState(moment().endOf('year'));
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    initDate();
  }, [])

  useEffect(() => {
    if (data.oldestYear) getChartData(data.meta_data);
  }, [data, startDate, endDate]);

  async function initDate() {
    try {
      setLoading(true);
      getCountDocuments();
    } catch (e) {
      console.log(e)
      setLoading(false);
    }
  }

  async function getCountDocuments() {
    const result = await dashboardHelper.countEdmsDashboard({ contractId });
    setData(result);
    setLoading(false);
  }

  async function getChartData(records) {
    const start = startDate.clone().format("YYYY-MM-DD");
    const end = endDate.clone().format("YYYY-MM-DD");
    const chartData = await dashboardHelper.edmsPieChartDashboard({ contractId, startDate: start, endDate: end });
    setPieData(chartData);
  }

  return (
    <CommonContainer loading={isLoading} withInfoHeader={false}>
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <RISCInfoBox title={"Total Document"} value={data.total ?? 0} withDate={false} />
          </Grid>
          <Grid item xs={12}><Divider className={classes.divider} /></Grid>

          {data?.oldestYear &&
            <>
              <Grid item xs={12} md={12}>
                <div className={classes.bottomRightWrapper}>
                  <ChartHeader
                    min={data?.oldestYear && Number(data?.oldestYear)}
                    title={''}
                    filterType={"quarterRange"}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                  />
                </div>
              </Grid>
              <Grid item xs={12} lg={5} style={{ minWidth: "400px" }}>
                <DocTypeSummaryPieChart startDate={startDate} endDate={endDate} data={pieData} />
              </Grid>
              <Grid item xs={12} lg={7}>
                <DocTypeSummaryList startDate={startDate} endDate={endDate} hack hackData={pieData} />
              </Grid>
            </>
          }
        </Grid>
      </div>
    </CommonContainer>
  );
};