import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setLoading } from 'redux/slice/loadingSlice';
import ChartContainer from 'Common/ChartContainer';
import ChartHeader from 'Common/ChartHeader';
import { Pie } from 'Common/Chart';

export default function DocTypeSummaryPieChart({ startDate, endDate, data }) {
  const dispatch = useDispatch();

  useEffect(() => {
    getDashboardData();
  }, [startDate, endDate, data]);

  async function getDashboardData() {
    try {
      dispatch(setLoading(false));
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <ChartContainer elevation={0} height={`${(430 + ((data?.length ?? 0) * 20)).toString()}px`}>
      <ChartHeader
        title={'EDMS Document Type Summary'}
        filterType={"none"}
      />
      <div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
        {data?.length === 0 || !data
          ? <div>No/Insufficient data for the time period</div>
          : <Pie
            data={data}
            colors={{ "scheme": "category10" }}
            innerRadius={0.6}
            enableArcLabels={true}
            enableArcLinkLabels={false}
            enablePercentageLabel={true}
            margin={{ top: 10, right: 50, bottom: 150, left: 50 }}
            legends={[
              {
                anchor: 'bottom-left',
                direction: 'column',
                justify: false,
                translateX: 0,
                translateY: 140,
                itemsSpacing: 0,
                itemWidth: 200,
                itemHeight: 18,
                itemTextColor: '#fff',
                itemDirection: 'left-to-right',
                itemOpacity: 0.9,
                symbolSize: 18,
                symbolShape: 'circle',
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemOpacity: 1
                    }
                  }
                ]
              }
            ]}
          />
        }
      </div>
    </ChartContainer>
  );
}