import { useParams, useHistory } from 'react-router';
import { useState, useEffect } from 'react';
import { Drawer } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';

import IconButton from "Common/IconButton";
import SectionTitle from 'Common/SectionTitle';
import FilterPage from 'Common/FilterPage';

import { inboxActionConfig, inboxAllActionsConfig, inboxInfoConfig, inboxSentConfig } from 'EDMS/CommonDocumentList/config';
import CommonContainer from 'EDMS/CommonContainer';
import { useStyles } from "../style";
import CommonDocumentList from 'EDMS/CommonDocumentList';
import edmsRegisterHelper from 'helpers/edmsRegisterHelper';
import { authHelper, permissionHelper } from 'helpers';
import { constructFilterObject } from 'utils/filterHelper';

export default function Inbox({ isAllAction }) {
  const classes = useStyles();
  const history = useHistory();
  const { type, contractId } = useParams();
  const [showFilter, setShowFilter] = useState(false);
  const [useFilter, setUseFilter] = useState(true);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState({
    for: type !== "all-actions" && type
  });
  const [users, setUsers] = useState([]);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    async function getUsers() {
      try {
        const result = await Promise.all([
          permissionHelper.getUsers({ orderBy: "firstName.asc" }),
          authHelper.getMyInfo({ orderBy: "firstName.asc" }),
        ])

        const userList = result[0].user.map(v => ({ value: v.id, type: "User", label: `${v.firstName} ${v.lastName}` }));
        setUsers(userList);
      } catch (e) {
        console.log(e);
      }
    }
    getUsers();
  }, [])

  useEffect(() => {
    setIsMounted(true);
    setFilter(type === "sent" || type === "all-actions" ? {} : { for: type })
  }, [type]);

  async function handleFilterChange(e) {
    const filtered = constructFilterObject(e);
    setPage(1);
    setFilter(filtered);
  }

  const handleOpenFilter = () => {
    setShowFilter(!showFilter);
  };

  function renderTitle() {
    if (isAllAction) return "All Actions"
    switch (type) {
      case "action": return "For Action";
      case "sent": return "Sent";
      default: return "For Info";
    }
  }

  function getDataApi() {
    switch (type) {
      case "all-action": return edmsRegisterHelper.getActionRequests;
      case "sent": return edmsRegisterHelper.getActionRequestsSent;
      default: return edmsRegisterHelper.getActionRequests;
    }
  }

  function getResponseKey() {
    switch (type) {
      case "all-action": return "action_request_for";
      case "sent": return "action_request";
      default: return "action_request_for";
    }
  }

  function handleGoToDetails(rowDetails) {
    const features = `popup, width=${window.screen.width}, height=${window.screen.width}`;
    if (type === "sent") window.open(`/contract/${contractId}/edms/inbox/sent/document/${rowDetails.actionRequestForId}`, `${rowDetails.id}-inbox`, features);
    else window.open(`/contract/${contractId}/edms/inbox/action/document/${rowDetails.id}`, `${rowDetails.id}-inbox`, features)
  }

  function getFilterConfig() {
    switch(type) {
      case "action":
        return inboxActionConfig;
      case "info":
        return inboxInfoConfig;
      case "all-actions":
        return inboxAllActionsConfig;
      case "sent":
        return inboxSentConfig;
    }
  }

  return (
    isMounted &&
    <CommonContainer>
      <SectionTitle
        title={renderTitle()}
        extraComponents={
          <div className={classes.buttonsContainer}>
            <IconButton
              icon={<FilterListIcon />}
              onClick={handleOpenFilter}
            />
          </div>
        }
      />
      <CommonDocumentList
        tableType={type}
        useFilter={useFilter}
        filter={filter}
        getDataApi={getDataApi()}
        defaultOrderBy="id"
        responseKey={getResponseKey()}
        handleGoTo={handleGoToDetails}
      />
      <Drawer
        anchor="right"
        open={showFilter}
        onClose={() => setShowFilter(false)}
        classes={{
          paperAnchorRight: classes.drawer
        }}
      >
        <FilterPage fieldsData={getFilterConfig()} setFilter={handleFilterChange} {...{ filter, setUseFilter, setShowFilter, setPage }} />
      </Drawer>
    </CommonContainer>
  );
}