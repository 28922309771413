import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
	label: {
		minHeight: "25.6px",
		color: theme.palette.primaryGrey,
		...theme.typography.h6,
		marginBottom: "8px"
	},
	displayLabel: {
		minHeight: "25.6px",
		color: theme.palette.primaryGrey,
		...theme.typography.h6,
		marginTop: 4,
		marginBottom: 4,
	},
	divider: {
		background: "grey"
	},
	rowBetween: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	},
	marginLeft: {
		marginLeft: 8,
	},
}));