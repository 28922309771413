import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme, useMediaQuery } from '@material-ui/core';

import { documentHelper } from 'helpers';

const useStyles = makeStyles(() => ({
  notFound: {
    color: "white",
  }
}));

function PDFViewer({ id, filename, setPdf }) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const smallerThanMD = !useMediaQuery(theme.breakpoints.up('md'));
  const [pdfData, setPdfData] = useState(null);

  async function getPreview(id) {
    try {
      const result = await documentHelper.decryptDocument(id);
      if (result.error) {
        throw new Error({ message: result.error });
      }
      const file = new Blob(
        [result],
        { type: 'application/pdf' }
      );

      const fileURL = URL.createObjectURL(file);//Open the URL on new Window
      setPdfData(fileURL);
      if(setPdf) setPdf(fileURL);

    } catch (e) {
      setPdfData(null);
    }
  }

  useEffect(() => {
    if (id) getPreview(id);
  }, [id]);

  return (
    <>
      {pdfData ?
        <iframe
          title="pdf"
          aria-label={"decrpytedDoc"}
          type="application/pdf"
          src={pdfData + `#view=fitH?file=${filename}.pdf`}
          width="100%"
          height={smallerThanMD ? "800" : "100%"}
        />

        // <object data={pdfData + `#view=fitH?file=${filename}.pdf`} type="application/pdf" width="100%" height="100%">
        //   <p>Alternative text - include a link <a href="http://africau.edu/images/default/sample.pdf">to the PDF!</a></p>
        // </object>
        // <Document file={pdfData} filename="abc.pdf">
        //   <Page pageNumber={1} />
        // </Document>

        : <div className={classes.notFound}>PDF has not been uploaded yet</div>
      }
    </>
  );
}

export default PDFViewer;