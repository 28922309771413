import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import CommonContainer from "EDMS/CommonContainer";
import CommonDocumentList from "EDMS/CommonDocumentList";
import SectionTitle from "Common/SectionTitle";
import { edmsFileHelper, edmsRegisterHelper } from "helpers";
import { convertSearchParamsToObject } from "utils/filterHelper";
import { useDispatch } from "react-redux";
import moment from "moment";
import { setNotification } from "redux/slice/notificationSlice";
import EllipseButton from "Common/EllipseButton";

export default function EDMSSearch() {
  const { search } = useLocation();
  const { contractId } = useParams();
  const dispatch = useDispatch();
  const [useFilter, setUseFilter] = useState(true);
  const [filter, setFilter] = useState({});
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState();

  useEffect(() => {
    document.title = 'Search Result';
    const obj = convertSearchParamsToObject(search);
    setFilter({ ...obj, page: 1 });
  }, [search])

  async function getExcel() {
    try {
      setLoading(true);
      const obj = convertSearchParamsToObject(search);
      const result = await edmsRegisterHelper.exportDocExcel({ contractId, ...obj, orderBy: "logNumber.desc" });
      if (result.data?.error) throw new Error(result.data.error.message);
      let url = URL.createObjectURL(result.data);
      const link = document.createElement('a')
      link.href = url;
      link.download = `Search_Result_${moment().format('YYYYMMDDHHmm')}.xlsx`
      link.click()
      link.remove();
      dispatch(setNotification({ type: "success", message: "Successfully export" }))
      setLoading(false);
    } catch (e) {
      setLoading(false);
      dispatch(setNotification({ type: "error", message: e.message }))
    }
  }

  return (
    <CommonContainer loading={loading}>
      <SectionTitle
        title={"Search Result"}
        extraComponents={
          <div>
            {<div style={{ marginRight: 8 }}> <EllipseButton onClick={getExcel} label="Export" /></div>}
          </div>
        }
      />
      <CommonDocumentList
        tableType={"edms"}
        useFilter={useFilter}
        filter={filter}
        page={page}
        getDataApi={edmsFileHelper.getFileMetas}
        defaultOrderBy=""
        responseKey={"meta_data"}
      />
    </CommonContainer>
  );
};