import { useParams, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';

import tableHelper from 'utils/tableHelper';
import { edmsFileHelper } from 'helpers';
import DocumentTable from "Common/Documents/DocumentsTable";
import Pagination from "Common/Pagination";
import { setLoading } from 'redux/slice/loadingSlice';

import {
  tableConfig, updateDocumentListConfig, viewDocumentListInConfig, viewDocumentListOutConfig,
  actionReportConfig, inboxActionConfig, inboxInfoConfig, inboxSentConfig
} from './config';
import { useStyles } from "./style";

export default function CommonDocumentList({ useFilter, filter, selectedData, handleSelect, externalContractId,
  tableType, getDataApi, responseKey, defaultOrderBy, type, showPDFBtn, hideGoToButton, handleDelete, handleAction, actionIcon, handleGoTo,
  disablePage, noSort, extraFilter, target }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const { contractId } = useParams();
  const [size, _] = useState(30);
  const [count, setCount] = useState(0);
  const [sort, setSort] = useState({
    orderBy: defaultOrderBy ?? "logNumber",
    sortDirection: "desc"
  });
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [isMounted, setIsMounted] = useState(false);
  const sp = new URLSearchParams(location.search);
  const updated = sp.get("updated");
  const [isRestricted, setIsRestricted] = useState(null);

  const maxPage = () => {
    return Math.ceil(count / size);
  };

  useEffect(() => {
    setIsMounted(true);
  }, [])

  useEffect(() => {
    if (updated) {
      if (useFilter) getPageData(page, size, filter, sort);
      else getPageData(page, size, {}, sort);
    }
  }, [updated, page])

  useEffect(() => {
    try {
      let unmounted = false;
      if (!unmounted && isMounted) {
        dispatch(setLoading(true));
        if (useFilter) getPageData(page, size, filter, sort);
        else getPageData(page, size, {}, sort);
      }
      return () => {
        unmounted = true;
      };
    } catch (e) {
      console.log(e);
      dispatch(setLoading(false));
    }
  }, [isMounted, sort, externalContractId, type])

  useEffect(() => {
    let unmounted = false;
    if (!unmounted && isMounted) {
      dispatch(setLoading(true));
      if (page === 1) {
        if (useFilter) getPageData(page, size, filter, sort);
        else getPageData(page, size, {}, sort);
      }
      setPage(1);
    }
    return () => {
      unmounted = true;
    };
  }, [filter, useFilter])

  useEffect(() => {
    let unmounted = false;
    if (!unmounted && isMounted) {
      dispatch(setLoading(true));
      if (useFilter) getPageData(page, size, filter, sort);
      else getPageData(page, size, {}, sort);
    }
    return () => {
      unmounted = true;
    };
  }, [page])

  async function getPageData(cPage, cSize, cFilter, cSort) {
    try {
      setIsRestricted();
      let filterVariable;
      if (!tableType) filterVariable = { ...cFilter, docType: type, ...extraFilter };
      else filterVariable = { ...cFilter, ...extraFilter };
      if (!disablePage) {
        filterVariable.page = cPage;
        filterVariable.items = cSize;
      }
      if (!noSort && sort?.orderBy) filterVariable.orderBy = `${cSort.orderBy}.${cSort.sortDirection}`
      if (!extraFilter) filterVariable.contractId = externalContractId ?? contractId;

      const filterKeys = Object.keys(cFilter);
      filterKeys.forEach((key) => {
        if (Array.isArray(cFilter[key])) {
          filterVariable[key] = tableHelper.handleDateTimeFilter(filterVariable[key])
        }
      })

      if (getDataApi) {
        const result = await getDataApi(filterVariable);
        if (result.error) throw new Error(result.error);
        if (!result[responseKey]) throw new Error({ error: "data not found" });
        setData(result[responseKey]);
        setCount((result.count) ?? 0);
      } else {
        const result = await edmsFileHelper.getFileMetas(filterVariable);
        if (result.error) throw new Error(result.error);
        if (!result.meta_data) throw new Error({ error: "data not found" });
        setData(result.meta_data);
        setCount((result.count) ?? 0);
      }
      dispatch(setLoading(false));
    } catch (e) {
      dispatch(setLoading(false));
      setIsRestricted(e.message);
      console.log(e);
    }
  }

  function handleRequestSort(event, property) {
    const isAsc = sort.orderBy === property && sort.sortDirection === 'asc';
    const direction = isAsc ? 'desc' : 'asc';
    setSort({
      orderBy: property,
      sortDirection: direction,
    });
  };

  async function handlePageChange(_, target) {
    dispatch(setLoading(true));
    window.scrollTo(0, 0);
    setPage(target);
    dispatch(setLoading(false));
  };

  function reload() {
    try {
      dispatch(setLoading(true));
      if (useFilter) getPageData(page, size, filter, sort);
      else getPageData(page, size, {}, sort);
    } catch (e) {
      console.log(e);
      dispatch(setLoading(false));
    }
  }

  function selectConfig() {
    switch (tableType) {
      case "updateDocumentList":
        return updateDocumentListConfig;
      case "viewDocumentInList":
        return viewDocumentListInConfig;
      case "viewDocumentOutList":
        return viewDocumentListOutConfig;
      case "actionReport":
        return actionReportConfig;
      case "all-actions":
      case "action":
        return inboxActionConfig.filter(item => !item.hideInTable);
      case "info":
        return inboxInfoConfig.filter(item => !item.hideInTable);
      case "sent":
        return inboxSentConfig.filter(item => !item.hideInTable);
      default:
        return tableConfig;
    }
  }

  return (
    <>
      <div className={classes.tableContainer}>
        {isRestricted
          ? isRestricted
          :
          <DocumentTable
            data={data}
            handleSort={handleRequestSort}
            orderBy={sort.orderBy}
            sortDirection={sort.sortDirection}
            fieldsData={selectConfig()}
            selectedData={selectedData}
            handleSelect={handleSelect}
            tableType={tableType ? tableType : "edms"}
            hidePdfBtn={!showPDFBtn}
            handleGoTo={handleGoTo}
            hideGoToButton={hideGoToButton || handleSelect}
            handleDelete={handleDelete}
            handleAction={handleAction}
            actionIcon={actionIcon}
            reload={reload}
            target={target}
          />
        }
      </div>
      {!disablePage && <Pagination count={maxPage()} page={page} onChange={handlePageChange} justifyContent="flex-start" />}
    </>
  );
}