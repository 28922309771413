import { useSelector } from 'react-redux';

import { selectContractData } from 'redux/slice/contractSlice';

import InfoHeader from 'Common/InfoHeader';

import { useStyles } from "./style";
import Loading from 'Common/Loading';

export default function CommonContainer({ children, withInfoHeader = true, loading }) {
  const classes = useStyles();
  const contractData = useSelector(selectContractData);

  return (
    <>
      <Loading isLoading={loading} />
      {/* {withInfoHeader && <InfoHeader data={contractData} />} */}
      <div className={classes.body}></div>
      {children}
    </>
  );
}