import axios from './apiHelper';
import errorHelper from 'utils/errorHelper';

const documentHelper = {
  getDocument: async ({ type, id }) => {
    try {
      const result = await axios.get(`/${type}/${id}`);

      if (!result.data) {
        throw new Error("Fail to get doc by id");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getDocumentByQuery: async ({ type, variable }) => {
    try {
      const result = await axios.get(`/${type}`, { params: variable });

      if (!result.data) {
        throw new Error("Fail to get doc by query");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getDocumentHistory: async ({ type, id, contractId }) => {
    try {
      const result = await axios.get(`/${type}/${id}/history`, { params: { contractId } });

      if (!result.data) {
        throw new Error(`Fail to get ${type} by id`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  decryptMeta: async ({ type, id }) => {
    try {
      const result = await axios.get(`/${type}/${id}/decrypt`);

      if (!result.data) {
        throw new Error("Fail to decrypt type");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  decryptDocument: async (id) => {
    try {
      const result = await axios.get(`/document/${id}/decrypt`, { responseType: "blob" });

      if (!result.data) {
        throw new Error("Fail to decrypt doc");
      }
      return result.data;
    } catch (e) {
      const returnResult = await errorHelper.catchApiError(e);
      if(returnResult) return returnResult;
      return { error: "error" };
    }
  },
  patchRelatedDocument: async ({ type, id, variable }) => {
    try {

      const result = await axios.patch(`/related-doc/${type}/${id}`, variable);

      if (!result.data) {
        throw new Error("Fail to patch doc");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  createDocument: async ({ type, variable }) => {
    try {
      const result = await axios.post(`/${type}`, variable);

      if (!result.data) {
        throw new Error("Fail to create document");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message || e.response.data.error };
      }
      return { error: "error" };
    }
  },
  uploadDocument: async ({ type, id, variable }) => {
    try {
      let formData = new FormData();

      formData.append('description', variable.description);
      formData.append('isAttachment', variable.isAttachment ? '0' : '1');
      formData.append('file', variable.file)
      const result = await axios({
        method: 'post',
        url: `/${type}/${id}/document`,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
      })

      if (!result.data) {
        throw new Error("Fail to post doc to block");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  postDocumentToContract: async ({ type, contractId, variable }) => {
    try {
      const result = await axios.post(`/contract/${contractId}/${type}`, variable);

      if (!result.data) {
        throw new Error("Fail to create document");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  postPhotoToDocument: async ({ id, variable }) => {
    try {
      let formData = new FormData();
      formData.append('file', variable.file)
      const result = await axios({
        method: 'post',
        url: `/photo/${id}/document`,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
      })

      if (!result.data) {
        throw new Error("Fail to post photo to doc");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
};
export default documentHelper;