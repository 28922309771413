import { ThemeProvider, StylesProvider } from '@material-ui/core/styles';
import { BrowserRouter } from 'react-router-dom';
import Layout from "Layout";
import "./App.css";
import { defaultMaterialTheme } from "./AppStyle";
function App() {
  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={defaultMaterialTheme}>
        <BrowserRouter>
          <Layout />
        </BrowserRouter>
      </ThemeProvider>
    </StylesProvider>
  );
}

export default App;
