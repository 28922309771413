import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Grid, DialogTitle, DialogContent, IconButton } from '@material-ui/core';
import CloseIcon from "static/icons/close.svg";

import docTypeHelper from 'helpers/admin/docTypeHelper';
import { setNotification } from 'redux/slice/notificationSlice';
import RoundedButton from 'Common/RoundedButton';
import SectionTitle from 'Common/SectionTitle';
import fieldHelper from 'utils/fieldHelper';

import { useStyles } from "../style";

export default function DocumentTypeSetupForm({ type, handleClose, reload, data, userNGroup }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { contractId } = useParams();
  const { register, handleSubmit, control, formState: { errors }, watch } = useForm({
    defaultValues: data ?? {}
  });

  async function onSubmit(formData) {
    try {
      const request = constructActionRequest(formData);
      if (type === "add") await docTypeHelper.createdocType({ contractId, ...request });
      else await docTypeHelper.editdocType({ ...request, contractId }, data.id,);
      handleClose();
      reload();
    } catch (e) {
      dispatch(setNotification({ type: "error", message: e.message }));
      console.log(e)
    }
  }

  function onError(data) {
    dispatch(setNotification({ type: "error", message: "Some field(s) are missing." }))
  }

  function constructActionRequest(data) {
    let actionObj, infoObj;
    if (data.forAction) {
      actionObj = getRecipientObj(data.forAction);
    }
    if (data.forInfo) {
      infoObj = getRecipientObj(data.forInfo);
    }

    const request = {
      ...data,
      defaultRecipientList: {
        action: actionObj,
        information: infoObj,
      },
    };
    return request;
  }

  function getRecipientObj(data) {
    let obj = {};
    let userArr = [];
    let groupArr = [];
    data.forEach(item => {
      if (item.type === "User") {
        userArr.push(item.id);
        obj.userId = userArr;
      }
      if (item.type === "Group") {
        groupArr.push(item.id);
        obj.groupId = groupArr;
      }
    })
    return obj;
  }

  return (
    <>
      <DialogTitle>
        <SectionTitle
          title={`${type === "add" ? "Add" : `Edit`} Document Type`}
          extraComponents={
            <IconButton onClick={handleClose}>
              <img src={CloseIcon} className={classes.Icon} alt={"document"} />
            </IconButton>
          }
        />
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <Grid container spacing={3}>
            {fieldHelper.renderField(
              { label: "Document Type", name: "name", isRequired: true, default: "" },
              0, register, control, errors, { size: 12, mdSize: 4 })
            }
            {fieldHelper.renderField(
              { label: "Code", name: "code", isRequired: true, default: "" },
              1, register, control, errors, { size: 12, mdSize: 4 })
            }
            <Grid item xs={4} />
            {fieldHelper.renderField(
              { label: "Due Days (for Action Request)", name: "dueDays", type: "number", isRequired: true, default: "" },
              2, register, control, errors, { size: 12, mdSize: 4 })
            }
            <Grid item xs={8} />
            {fieldHelper.renderField(
              { label: "For Action", name: "forAction", isRequired: false, type: "AutoComplete", options: userNGroup, multiple: true, },
              3, register, control, errors, { size: 8 })
            }
            <Grid item xs={4} />
            {fieldHelper.renderField(
              { label: "For Information", name: "forInfo", isRequired: false, type: "AutoComplete", options: userNGroup, multiple: true, },
              52, register, control, errors, { size: 8 })
            }
            <Grid item xs={4} />

            {fieldHelper.renderField(
              { label: "Description", name: "description", isRequired: false, default: "" },
              6, register, control, errors, { size: 12, mdSize: 12 })
            }
            <Grid item sm={12} />
          </Grid>

          <Grid container item justify="flex-end">
            <RoundedButton backgroundColor="#70BAD3" type="submit">{type === "add" ? "Submit" : "Save"}</RoundedButton>
          </Grid>
        </form>
      </DialogContent>
    </>
  );
}