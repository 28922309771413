import { useParams } from 'react-router';
import { useState, useEffect, Fragment } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { useDispatch } from 'react-redux';
import moment from 'moment/moment';

import EllipseButton from 'Common/EllipseButton';
import SectionTitle from 'Common/SectionTitle';
import { setNotification } from 'redux/slice/notificationSlice';

import CommonContainer from 'EDMS/CommonContainer';
import { useStyles } from "./style";
import CommonDocumentList from 'EDMS/CommonDocumentList';
import edmsRegisterHelper from 'helpers/edmsRegisterHelper';
import ConfirmPopup from 'Common/ConfirmPopup';

export default function UpdateDocumentList() {
  const classes = useStyles();
  const { contractId } = useParams();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [popup, setPopup] = useState({ targetId: null, message: "", type: null });
  const { direction } = useParams();
  const [filter, setFilter] = useState({
    inOut: direction.toUpperCase()
  });
  const [qrcodeList, setQrcodeList] = useState([]);
  const [isMounted, setIsMounted] = useState(false);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    setFilter({ inOut: direction.toUpperCase() })
  }, [direction]);

  function handleClosePopup() {
    setPopup({ ...popup, targetId: null, type: null, data: null })
  }
  async function handleDocumentDelete() {
    try {
      setLoading(true);
      await edmsRegisterHelper.deleteRegisterDocument(popup.targetId);
      setPopup({ targetId: null, type: null });
      setLoading(false);
      setFilter({ inOut: direction.toUpperCase() })
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  function handleDelete(item, e) {
    setAnchorEl(e.currentTarget);
    setPopup({ targetId: item.id, message: "Confirm to delete the record?", });
  }

  async function triggerPrintProcess() {
    try {
      const result = await edmsRegisterHelper.getUnassignedDocuments({ contractId, inOut: direction, orderBy: "logNumber.desc" });
      if (result.error) throw new Error(result.error);
      setQrcodeList(result.file_meta);
      if (result.count === 0) return;
      printQRCode();
    } catch (e) {
      console.log(e);
    }
  }

  function printQRCode() {
    const container = document.getElementById("qrcodeList");
    if (!container) return;
    const width = "1024px";
    const height = "800px";
    const printWindow = window.open('', 'PrintMap', 'width=' + width + ',height=' + height);
    printWindow.document.writeln(container.innerHTML);
    printWindow.document.close();
    printWindow.print();
    setQrcodeList([]);
  }

  async function getExcel() {
    try {
      setLoading(true);
      const result = await edmsRegisterHelper.exportViewRegDocExcel({ contractId, inOut: direction, orderBy: "logNumber.desc" });
      if (result.data?.error) throw new Error(result.data.error.message);
      let url = URL.createObjectURL(result.data);
      const link = document.createElement('a')
      link.href = url;
      link.download = `Update_Docuemt_${direction}_${moment().format('YYYYMMDDHHmm')}.xlsx`
      link.click()
      link.remove();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      dispatch(setNotification({ type: "error", message: e.message }))
    }
  }


  return (
    <>
      {isMounted &&
        <CommonContainer loading={isLoading}>
          <SectionTitle
            title={`Update ${direction === "in" ? "Incoming" : "Outgoing"} Document`}
            extraComponents={
              <div className={classes.buttonsContainer}>
                {<div style={{ marginRight: 8 }}><EllipseButton onClick={getExcel} label="Export" /></div>}
                <EllipseButton onClick={triggerPrintProcess} label={"Print QR Code"} />
              </div>
            }
          />
          <CommonDocumentList
            filter={filter}
            useFilter={true}
            responseKey="file_meta"
            getDataApi={edmsRegisterHelper.getUnassignedDocuments}
            tableType={"updateDocumentList"}
            handleDelete={(item, e) => handleDelete(item, e)}
          />

          <ConfirmPopup
            anchorEl={anchorEl}
            open={popup.targetId || popup.data ? true : false}
            handleAction={handleDocumentDelete}
            handleClose={handleClosePopup}
            targetId={popup.targetId}
            message={popup.message}
          />
          {qrcodeList.length > 0 &&
            <div id={"qrcodeList"} style={{ visibility: "collapse" }}>
              <div style={{ background: "white", color: "black", width: "100%", display: "flex", flexWrap: "wrap" }} >
                {qrcodeList.map((item, idx) =>
                  <Fragment idx={idx}>
                    {idx === 32 && <div style={{ pageBreakAfter: "always", clear: "both" }}></div>}
                    <div key={idx} style={{ display: "flex", alignItems: "center", flexDirection: "column", marginTop: 20, flexGrow: 0, maxWidth: "25%", flexBasis: "25%" }}>
                      <div style={{ display: "flex", alignItems: "end" }}>
                        <QRCodeSVG value={`${document.location.origin}/contract/${contractId}/edms/register-document/${direction}/view/document/${item.id}`} height="74" width="78" />
                        <div style={{ color: "black", fontSize: "8pt" }}>{item.logNumber}</div>
                      </div>
                      <div style={{ color: "black", marginTop: 2, fontSize: "6pt" }}>{item.letterRef ?? "KLCWJV/990/CSF/9999-9999"}</div>
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          }
        </CommonContainer>
      }
    </>
  );
}