const permissionUtils = {
  transformDataToFormData(defaultData) {
    let defaultFormValue = [];
    const users = defaultData.users ?? [];
    const groups = defaultData.groups ?? [];

    users.forEach((user) => {
      defaultFormValue.push({
        target: { id: user.userId, type: "User", label: user.username },
        permissions: user.permissions.map(permission => (permission.permissionId))
      })
    });

    groups.forEach((group) => {
      defaultFormValue.push({
        target: { id: group.groupId, type: "Group", label: group.group },
        permissions: group.permissions.map(permission => (permission.permissionId))
      })
    });

    return defaultFormValue;
  },
  transformFormDataToRequestBody(permission, folderId) {
    let users = [];
    let userIdList = [];
    let groups = [];
    let groupIdList = [];

    permission.forEach((row, idx) => {
      if (row.target.type === "User") {
        users.push({
          userId: row.target.id,
          permissions: row.permissions.map(permission => ({ permissionId: permission }))
        })
        userIdList.push(row.target.id);
      } else if (row.target.type === "Group") {
        groups.push({
          groupId: row.target.id,
          permissions: row.permissions.map(permission => ({ permissionId: permission }))
        })
        groupIdList.push(row.target.id);
      }
    })

    const userIdSet = new Set(userIdList);
    const groupIdSet = new Set(groupIdList);
    // compare the size of array and Set
    if (userIdList.length !== userIdSet.size) {
      throw new Error("Duplicate User Selected");
    }
    if (groupIdList.length !== groupIdSet.size) {
      throw new Error("Duplicate Group Selected")
    }
    let userRequest = {
      users,
    };
    let groupRequest = {
      groups,
    }

    if (folderId) {
      userRequest = {
        fileId: folderId,
        users,
      };
      groupRequest = {
        fileId: folderId,
        groups
      };
    }

    return {
      userRequest,
      groupRequest,
    }
  },
  checkPermission(accessPermission, docType, permissionType) {
    const target = accessPermission?.[docType] ?? false;
    if (target?.permissions) {
      return target.permissions.find(o => o.code === permissionType);
    }
    return false;
  },
}

export default permissionUtils;