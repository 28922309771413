import { TextField, } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';

import { useStyles } from "./style";
export default function CustAutoComplete({
  label,
  field,
  options,
  hideLabel,
  isUppercase,
  multiple,
  defaultValue,
  textSearch,
  freeSolo,
  disabled,
  autoHighlight = true,
}) {
  const classes = useStyles();

  function handleChange(e, newValue) {
    field.onChange(newValue);
  }
  function handleTextChange(e) {
    if (!textSearch) return;
    if (isUppercase) field.onChange(e.target.value.toUpperCase());
    else field.onChange(e.target.value);
  }
  function onClose(e) {
    // if (typeof e.target.value === "string") field.onChange(multiple ? [] : "");
  }
  if (textSearch) {
    return <>
      {hideLabel ? null : <div className={classes.label}>{label}</div>}
      <Autocomplete
        autoHighlight={autoHighlight}
        multiple={multiple}
        options={options ?? []}
        defaultValue={defaultValue ?? undefined}
        onClose={onClose}
        groupBy={(option) => option?.type ?? null}
        getOptionLabel={(option) => option?.label ?? option}
        freeSolo={freeSolo}
        disabled={disabled}
        onChange={(e, newValue) => handleChange(e, newValue)}
        onInput={(e) => handleTextChange(e)}
        renderInput={(params) =>
          <div style={{ display: "flex" }}>
            <TextField
              {...params}
              inputProps={
                {
                  ...params.inputProps,
                  style: isUppercase ? { textTransform: "uppercase" } : {}
                }
              }
            />
          </div>}
      />
    </>
  }
  return (
    <>
      {hideLabel ? null : <div className={classes.label}>{label}</div>}
      <Autocomplete
        autoHighlight={autoHighlight}
        multiple={multiple}
        options={options ?? []}
        value={field?.value ?? (multiple ? [] : null)}
        disableClearable
        onClose={onClose}
        // autoSelect
        freeSolo={freeSolo}
        disabled={disabled}
        groupBy={(option) => option?.type ?? null}
        getOptionLabel={(option) => option?.label ?? option}
        onChange={(e, newValue) => handleChange(e, newValue)}
        onInput={(e) => handleTextChange(e)}
        renderInput={(params) =>
          <div style={{ display: "flex" }}>
            <TextField
              {...params}
              inputProps={
                {
                  ...params.inputProps,
                  style: isUppercase ? { textTransform: "uppercase" } : {}
                }
              }
            />
          </div>}
      />
    </>
  );
}