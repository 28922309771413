import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "0",
  },
  img: {
    height: 25,
    width: 25
  },
  a: {
    height: 25
  },
  cell: {

    fontSize: 12,
    padding: 4,
  },
}));
