export const sortDirectionOptions = [
  {
    label: "Ascendingly",
    value: "asc",
  },
  {
    label: "Descendingly",
    value: "desc"
  }
]

export const photoConfig = () => {
  return [
    { id: "location1", label: "Location", value: "location1" },
    { id: "photoDesc", label: "Description", value: "photoDesc" },
    { id: "takenDatetime", label: "Taken at", isDate: true, value: "takenDatetime" },
    { id: "takenBy", label: "Taken by", value: "takenBy" },
    { id: "uploadBy", label: "Uploaded by", value: "uploadBy" },
    { id: "uploadDatetime", label: "Uploaded on", isDate: true, value: "uploadDatetime" },
  ];
}