import { Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import fieldHelper from "utils/fieldHelper";
import { letterRefFieldConfig } from "./config";
import MultipleSelectList from "Common/MultipleSelectList";

export default function EditDocumentMainForm({
  control, register, errors,
  docTypeOptions, companyOptions, personOptions,
  direction,
  fileOptions,
  subFolderOptions,
  letterRefList,
  userList,
  fileNumberRef,
  setValue,
  repliedBy,
}) {
  const [fileNoOptions, setFileNoOptions] = useState(fileOptions);
  useEffect(() => {
    if (subFolderOptions.length > 0) setFileNoOptions(subFolderOptions)
    else setFileNoOptions(fileOptions)
  }, [fileOptions, subFolderOptions])
  return (
    <>
      {fieldHelper.renderField(
        { label: "Log Number", name: "logNumber", isRequired: true, disabled: true },
        0, register, control, errors)
      }
      {fieldHelper.renderField(
        { label: "Doc Type", name: "docType", isRequired: false, type: "Select", options: docTypeOptions, },
        1, register, control, errors)
      }
      <Grid item xs={3} />

      {fieldHelper.renderField(
        { label: "Letter Date", name: "letterDate", isRequired: true, type: "Date" },
        2, register, control, errors)
      }
      {direction === "in"
        ?
        fieldHelper.renderField(
          { label: "Receive Date", name: "receiveDate", isRequired: false, type: "Date", },
          3, register, control, errors)
        :
        fieldHelper.renderField(
          { label: "Dispatch Date", name: "receiveDate", isRequired: false, type: "Date", },
          3, register, control, errors)
      }
      {fieldHelper.renderField(
        { label: "Filing Date", name: "filingDate", isRequired: false, type: "Date", },
        4, register, control, errors)
      }

      {fieldHelper.renderField(
        { label: "From Company", name: "fromCompany", isRequired: true, type: "AutoComplete", options: companyOptions, multiple: true, freeSolo: true },
        5, register, control, errors)
      }
      {fieldHelper.renderField(
        { label: "From Person", name: "fromPerson", isRequired: false, type: "AutoComplete", options: personOptions, multiple: true, freeSolo: true },
        6, register, control, errors)
      }
      <Grid item xs={3} />
      {fieldHelper.renderField(
        { label: "To Company", name: "toCompany", isRequired: false, type: "AutoComplete", options: companyOptions, multiple: true, freeSolo: true },
        7, register, control, errors)
      }
      {fieldHelper.renderField(
        { label: "To Person", name: "toPerson", isRequired: false, type: "AutoComplete", options: personOptions, multiple: true, freeSolo: true },
        8, register, control, errors)
      }
      <Grid item xs={3} />
      {fieldHelper.renderField(
        { label: "CC To Company", name: "ccToCompany", isRequired: false, type: "AutoComplete", options: companyOptions, multiple: true, freeSolo: true },
        9, register, control, errors)
      }
      <Grid item xs={6} />
      {fieldHelper.renderField(
        { label: "Subject", name: "subject", isRequired: true, },
        10, register, control, errors, { size: 12 })
      }
      {direction === "in" &&
        <>
          {fieldHelper.renderField(
            { label: "Action Required", name: "actionRequired", type: "Checkbox", },
            29, register, control, errors)
          }
          {fieldHelper.renderField(
            { label: "Exclude Outstanding List", name: "excludeOutstandingList", type: "Checkbox", },
            30, register, control, errors)
          }
        </>
      }
      {fieldHelper.renderField(
        {
          label: "Reply To", name: "replyTo", isRequired: false, type: "MultipleSelectList",
          options: letterRefList, config: letterRefFieldConfig, setValue: setValue,
        },
        11, register, control, errors, { size: 12 })
      }
      {fieldHelper.renderField(
        { label: "Letter Ref.", name: "letterRef", isRequired: false, },
        12, register, control, errors, { size: 6 })
      }
      <Grid item xs={6} />
      <div ref={fileNumberRef}></div>
      {fieldHelper.renderField(
        { label: "File No.", name: "fileNumber", isRequired: false, type: "AutoComplete", options: fileNoOptions, isUppercase: true, textSearch: true },
        13, register, control, errors, { size: 6 })
      }
      <Grid item xs={6} />
      {fieldHelper.renderField(
        { label: "CC To File", name: "ccToFile", isRequired: false, },
        14, register, control, errors, { size: 6 })
      }
      <Grid item xs={6} />
      {fieldHelper.renderField(
        { label: "Enclosure", name: "enclosure", type: "Checkbox", },
        15, register, control, errors)
      }
      {fieldHelper.renderField(
        { label: "CD/DVD", name: "cdOrDvd", isRequired: false, },
        17, register, control, errors)
      }
      <Grid item xs={6} />
      {fieldHelper.renderField(
        { label: "Sketch / Drawing No.", name: "a1Drawing", isRequired: false, },
        19, register, control, errors, { size: 12 })
      }
      {fieldHelper.renderField(
        { label: "Others", name: "others", isRequired: false, },
        20, register, control, errors)
      }
      {fieldHelper.renderField(
        { label: "Confidential", name: "confidential", isRequired: false, type: "Checkbox", },
        21, register, control, errors)
      }
      <Grid item xs={3} />
      {fieldHelper.renderField(
        { label: "Prepared By", name: "preparedBy", isRequired: false, },
        22, register, control, errors)
      }
      {fieldHelper.renderField(
        { label: "Drafter", name: "drafter", isRequired: false, type: "AutoComplete", options: personOptions },
        23, register, control, errors)
      }
      <Grid item xs={3} />
      {fieldHelper.renderField(
        { label: "Remarks", name: "remarks", isRequired: false, },
        24, register, control, errors, { size: 6 })
      }
      <Grid item xs={6} />
      {fieldHelper.renderField(
        {
          label: "Related Doc", name: "relatedDocuments", isRequired: false, type: "MultipleSelectList",
          options: letterRefList, config: letterRefFieldConfig, setValue: setValue,
        },
        25, register, control, errors, { size: 12 })
      }

      <Grid item xs={12}>
        {<MultipleSelectList config={letterRefFieldConfig} data={repliedBy} readOnly label={"Replied By"} />}
      </Grid>

      {fieldHelper.renderField(
        { label: "Reply Deadline", name: "replyDeadline", isRequired: false, type: "Date" },
        27, register, control, errors)
      }
      {fieldHelper.renderField(
        { label: "Circulation", name: "circulation", isRequired: false, },
        28, register, control, errors)
      }
    </>
  );
}