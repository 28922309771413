export const tabData = [
  {
    label: "Dashboard",
    key: 0,
    link: "dashboard"
  },
];

export const summaryTableConfig = [
  { id: null, label: "" },
  { id: "label", label: "Document Type" },
  { id: "value", label: "No. of Records" },
];

