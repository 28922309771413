import { permissionHelper } from "helpers";
export const folderGroupMenuOptions = async (handleClose, data, action) => {
	const permission = await permissionHelper.checkPermission({ fileId: data.id, checkFor: "edit" });
	if (permission.pass) {
		return [
			{
				name: "Edit Group",
				onClick: function () {
					action("Group", "Edit", data);
					handleClose();
				}
			},
		]
	} else {
		return [
			{
				name: "No Action",
			}
		]
	}
};

export const folderMenuOptions = async (handleClose, history, contractId, id) => {
	const permission = await Promise.all([
		permissionHelper.checkPermission({ fileId: id, checkFor: "add" }),
		permissionHelper.checkPermission({ fileId: id, checkFor: "edit" })
	]);

	let folderMenuItem = [];
	if (permission?.[0]?.pass) {
		folderMenuItem.push(
			{
				name: "Create Folder",
				onClick: function () {
					handleClose();
					history.push({ pathname: `/contract/${contractId}/edms/folder-create`, search: `?folderId=${id}`, params: { folderId: id } })
				}
			}
		);
	}
	if (permission?.[1]?.pass) {
		folderMenuItem.push(
			{
				name: "Properties",
				onClick: function () {
					handleClose();
					history.push(`/contract/${contractId}/edms/folder/${id}/properties`);
				}
			}
		);
	}
	if (folderMenuItem.length > 0) return folderMenuItem;
	else {
		folderMenuItem.push({ name: "No Action", });
	}
	return folderMenuItem;
}

export const moduleMenuOptions = async (handleClose, history, contractId, moduleType, props) => {
	if (moduleType === "edms") {
		return [
			{
				name: "Inbox",
				type: "group",
			},
			{
				name: "For Action",
				isSub: 1,
				onClick: function () {
					handleClose();
					history.push(`/contract/${contractId}/edms/inbox/action`);
				}
			},
			{
				name: "For Info",
				isSub: 1,
				onClick: function () {
					handleClose();
					history.push(`/contract/${contractId}/edms/inbox/info`);
				}
			},
			{
				name: "Document Registration",
				type: "group",
			},
			{
				name: "Register Incoming Document",
				isSub: 1,
				onClick: function () {
					handleClose();
					history.push(`/contract/${contractId}/edms/register-document/in`);
				}
			},
			{
				name: "Register Outgoing Document",
				isSub: 1,
				onClick: function () {
					handleClose();
					history.push(`/contract/${contractId}/edms/register-document/out`);
				}
			},
			{
				name: "Update Document",
				type: "group",
			},
			{
				name: "View Reg In Document",
				isSub: 1,
				onClick: function () {
					handleClose();
					history.push(`/contract/${contractId}/edms/register-document/in/update`);
				}
			},
			{
				name: "View Reg Out Document",
				isSub: 1,
				onClick: function () {
					handleClose();
					history.push(`/contract/${contractId}/edms/register-document/out/update`);
				}
			},
			{
				name: "View Document",
				type: "group",
			},
			{
				name: "Document In",
				isSub: 1,
				onClick: function () {
					handleClose();
					history.push(`/contract/${contractId}/edms/register-document/in/view`);
				}
			},
			{
				name: "Document Out",
				isSub: 1,
				onClick: function () {
					handleClose();
					history.push(`/contract/${contractId}/edms/register-document/out/view`);
				}
			},
			{
				name: "Report",
				type: "group",
			},
			{
				name: "Unscanned Report",
				isSub: 1,
				onClick: function () {
					handleClose();
					history.push(`/contract/${contractId}/report/unscanned-report`);
				}
			},
			{
				name: "Recently Check Report",
				isSub: 1,
				onClick: function () {
					handleClose();
					history.push(`/contract/${contractId}/report/recently-check-report`);
				}
			},
			// {
			// 	name: "Acknowledge Receive",
			// 	isSub: 1,
			// 	onClick: function () {
			// 		handleClose();
			// 		history.push(`/contract/${contractId}/report/acknowledge-receive`);
			// 	}
			// },
			// {
			// 	name: "From Contractor",
			// 	type: "group",
			// },
			// {
			// 	name: "From Contractor",
			// 	isSub: 1,
			// 	onClick: function () {
			// 		handleClose();
			// 		history.push(`/contract/${contractId}/edms/from-contractor`);
			// 	}
			// },
		]
	}
	else {
		return [
			{
				name: "No Action",
			}
		]
	}
}
