import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message'
import { useParams } from "react-router";
import { DialogTitle, DialogContent, DialogActions, IconButton, Grid, Divider } from "@material-ui/core";
import { useDispatch } from "react-redux";

import { edmsFileHelper } from "helpers";
import fieldHelper from "utils/fieldHelper";
import { setNotification } from "redux/slice/notificationSlice";
import SectionTitle from "Common/SectionTitle";
import Button from "Common/RoundedButton";
import Loading from "Common/Loading";

import CloseIcon from "static/icons/close.svg";


import { useStyles } from "./style";

export default function EditRemarks({ mode, handleClose, reload, defaultData }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { contractId } = useParams();
  const [isLoading, setLoading] = useState(false);
  const { watch, register, handleSubmit, control, formState: { errors }, reset } = useForm({
    defaultValues: {
      ...defaultData
    }
  })

  async function onSubmit(data) {
    try {
      setLoading(true);
      console.log(data);
      if (mode === "Add") {
        const requestVariables = { contractId, ...data };
        const result = await edmsFileHelper.createRemarksLabel(requestVariables);
      } else if (mode === "Edit") {
        const requestVariables = { code: data.code, description: data.description };
        const result = await edmsFileHelper.editRemarksLabel(data.id, requestVariables);
      }
      reload();
      handleClose();
    } catch (e) {
      dispatch(setNotification({ type: "error", message: e.message }));
      setLoading(false);
      console.log(e)
    }
  }

  function onError(data) {
    dispatch(setNotification({ type: "error", message: "Some field(s) are missing." }))
  }

  return (
    <>
      <DialogTitle>
        <SectionTitle
          title={`${mode} Remarks`}
          extraComponents={
            <IconButton onClick={handleClose}>
              <img src={CloseIcon} className={classes.Icon} alt={"document"} />
            </IconButton>
          }
        />
      </DialogTitle>

      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <DialogContent>

          <Grid container spacing={3} className={classes.section}>
            {fieldHelper.renderField(
              { label: "Code", name: "code", isRequired: true, inputProps: { min: 0, max: 9 }, type: "Number" },
              0, register, control, errors)
            }

            {fieldHelper.renderField(
              { label: "Description", name: "description", isRequired: true, },
              1, register, control, errors)
            }

          </Grid>
          <Divider className={classes.formDivider} />

        </DialogContent>
        <Loading isLoading={isLoading} />

        <DialogActions>
          <Button children={mode === "Add" ? "Add" : "Save"} backgroundColor="#70BAD3" type="submit" />
        </DialogActions>
      </form>
    </>
  );
}