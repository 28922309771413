import riscPdf from "static/fallback/RISC_sample.pdf"
import sitePdf from "static/fallback/SiteLabour_sample.pdf"
import safetyPdf from "static/fallback/Safety_Sample.pdf"
import cleansingPdf from "static/fallback/Cleansing_Sample.pdf"
import { documentHelper } from "helpers"
import { setLoading } from "redux/slice/loadingSlice"
import store from "redux/configureStore"

const linkStyle = { cursor: "pointer", textDecoration: "underline", wordBreak: "break-word" };
const refStyle = { cursor: "pointer", textDecoration: "underline", wordBreak: "break-word" };

const pdfHelper = {
  selectSamplePdf(type) {
    switch (type) {
      case "risc":
        return riscPdf;
      case "safety-inspection":
        return safetyPdf;
      case "cleansing-inspection":
        return cleansingPdf;
      case "labour":
        return sitePdf;
      default:
        return sitePdf;
    }
  },
  async openDocInBlankPage(docId) {
    try {
      if (docId === false) {
        alert("User does not have sufficient VIEW permissions");
        return;
      }
      if (!docId) {
        alert("No Document");
        return;
      }
      store.dispatch(setLoading(true));
      const blob = await documentHelper.decryptDocument(docId)
      var url = URL.createObjectURL(blob);
      window.open(url + "#page=1&view=FitH", docId, `popup, width=${window.screen.width * 0.54}, height=${window.screen.width}`);
      store.dispatch(setLoading(false));
    } catch (e) {
      alert('File Not Found');
      store.dispatch(setLoading(false));
    }
  },
  async openDocAsDownload(docId, filename) {
    try {
      if (docId === false) {
        alert("User does not have sufficient VIEW permissions");
        return;
      }
      if (!docId) {
        alert("No Document");
        return;
      }
      store.dispatch(setLoading(true));
      const blob = await documentHelper.decryptDocument(docId)
      var url = URL.createObjectURL(blob);
      const link = document.createElement('a')
      link.href = url;
      link.download = filename;
      link.click()
      link.remove();
      store.dispatch(setLoading(false));
    } catch (e) {
      alert('File Not Found');
      store.dispatch(setLoading(false));
    }
  },
  openPageInWindow(link, id) {
    window.open(link, id, `popup, width=${window.screen.width}, height=${window.screen.width}`);
  },
  renderLetterRefLinkList(items) {
    if (!Array.isArray(items) && items) return items; // One more checking in case data shape is not array
    if (!items?.length > 0) return "-";
    return items.map((item, idx) => {
      const docId = item.documentId;
      const noPermission = !docId && item?.view === false;
      return <div key={idx}>
        <div style={item.documentId ? linkStyle : refStyle} onClick={() => pdfHelper.openDocInBlankPage(noPermission ? false : docId)}>{item.letterRef}</div>
      </div>
    });
  }
}

export default pdfHelper;