const arrayHelper = {
  uniq(a) {
    var seen = {};
    let clean = a.filter(item => !!item);
    return clean.filter(function (item) {
      return seen.hasOwnProperty(item) ? false : (seen[item] = true);
    });
  },
  convertArrayToObject(array, key) {
    const initialValue = {};
    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: "",
        // [item[key]]: item,
      };
    }, initialValue);
  },
  sortByKey(array, key, ordering, isNumber = false) {
    return array.sort(function (a, b) {
      var x = a[key]; var y = b[key];
      if (isNumber) {
        if (ordering === 'asc') return ((Number(x) < Number(y)) ? -1 : ((Number(x) > Number(y)) ? 1 : 0));
        else return ((Number(x) > Number(y)) ? -1 : ((Number(x) < Number(y)) ? 1 : 0));
      }
      if (ordering === 'asc') return ((x < y) ? -1 : ((x > y) ? 1 : 0));
      else return ((x > y) ? -1 : ((x < y) ? 1 : 0));
    });
  },
  shapeFileOptions(folders, prefix, extraKey) {
    if (!folders || folders.length === 0) return [];
    const options = folders.map((item => {
      return {
        label: `${prefix ?? ""}${item.code}`,
        value: item.id,
        [extraKey]: item?.[extraKey],
      }
    }));
    return options;
  },
  shapeOptions(input, labelKey, valueKey) {
    if (!input || input.length === 0) return [];
    const options = input.map((item => {
      return {
        ...item,
        label: item[labelKey],
        value: item[valueKey],
      }
    }));
    return options;
  },
  extractUserGroupInfo(record, userList, groupList) {
    let result = [];
    const userId = record?.userId;
    const groupId = record?.groupId;
    if (userId?.length > 0) {
      const targetArr = userId.map(id => userList.find(item => item.id === id));
      result = result.concat(targetArr);
    }
    if (groupId?.length > 0) {
      const targetArr = groupId.map(id => groupList.find(item => item.id === id));
      result = result.concat(targetArr);
    }
    return result;
  }
}


export default arrayHelper;