const errorHelper = {
  async catchApiError(e) {
    if (e.response) {
      if (e.response.status === 400 || e.response.status === 404 || e.response.status === 500) {
        if (
          e.request.responseType === 'blob' &&
          e.response.data instanceof Blob &&
          e.response.data.type &&
          e.response.data.type.toLowerCase().indexOf('json') != -1
        ) {
          let errorString = JSON.parse(await e.response.data.text());
          return { error: errorString };
        }
        if (e.response.status === 404) return { error: "Not Found" };
        return { error: e.response.data.message };
      }
    }
  },
  apiErrorHandler(e) {
    if (e.response) {
      if (e.response.status === 400 || e.response.status === 500)
        return { error: e.response.data.message };
      if (e.response.status === 404) {
        if (!e.response.data) return { error: "error" };
        return e.response.data;
      }
      return { error: e.response.data }
    }
    return { error: "error" };
  }
}


export default errorHelper;