import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useTheme } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import FilterListIcon from '@material-ui/icons/FilterList';

import { riskHelper } from 'helpers';
import EllipseButton from 'Common/EllipseButton';
import IconButton from "Common/IconButton";
import SectionTitle from 'Common/SectionTitle';
import CommonDocumentList from 'EDMS/CommonDocumentList';
import FilterPage from 'Common/FilterPage';
import Modal from "Common/Modal";
import { riskRegisterTableConfig } from 'EDMS/CommonDocumentList/config';

import { useStyles } from "./style";
import RiskRegisterAddForm from './riskRegisterAddForm';
import { setNotification } from 'redux/slice/notificationSlice';
import { selectContractData } from 'redux/slice/contractSlice';

export default function RiskRegisterList({ selectedData, handleSelect, hideGoToButton }) {
  const theme = useTheme();
  const dispatch = useDispatch()
  const classes = useStyles(theme);
  const { contractId } = useParams();
  const contractData = useSelector(selectContractData);
  const [modalOpen, setModalOpen] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [useFilter, setUseFilter] = useState(false);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState({
  });

  useEffect(() => {
  }, []);

  async function handleFilterChange(e) {
    setPage(1);
    setFilter(e);
  }

  const handleOpenFilter = () => {
    setShowFilter(!showFilter);
  };

  async function getExcel() {
    try {
      const result = await riskHelper.getRiskExcel({ contractId });
      if (result.data.error) throw new Error(result.data.error.message);
      let url = URL.createObjectURL(result.data);
      const contractNumber = contractData.contractNo.replaceAll('/', '');
      const link = document.createElement('a')
      link.href = url;
      link.download = `${contractNumber}_Risk_register${moment().format('YYYYMMDDHHmm')}.xlsx`
      link.click()
      link.remove();
      // window.open(url, '_blank', 'noopener noreferrer');
    } catch (e) {
      dispatch(setNotification({ type: "error", message: e.message }))
    }

  }

  return (
    <>
      <SectionTitle
        title={handleSelect ? "" : "Risk Register List"}
        extraComponents={
          <div className={classes.buttonsContainer}>
            {handleSelect ?? <div style={{ marginRight: 8 }}> <EllipseButton onClick={() => getExcel()} label="Export to Excel" /></div>}
            {handleSelect ?? <EllipseButton onClick={() => setModalOpen(true)} label="+ New Risk Discussed" />}
            <div style={{ marginLeft: "16px" }}>
              <IconButton
                icon={<FilterListIcon />}
                onClick={handleOpenFilter}
              />
            </div>
          </div>
        }
      />
      <CommonDocumentList
        handleSelect={handleSelect}
        selectedData={selectedData}
        tableType={"risk-register"}
        useFilter={useFilter}
        filter={filter}
        page={page}
        getDataApi={riskHelper.getRiskRegisters}
        defaultOrderBy="riskIdReference"
        responseKey={"risk_register"}
        hideGoToButton={hideGoToButton}
      />
      <Drawer
        anchor="right"
        open={showFilter}
        onClose={() => setShowFilter(false)}
        classes={{
          paperAnchorRight: classes.drawer
        }}
      >
        <FilterPage fieldsData={riskRegisterTableConfig} setFilter={handleFilterChange} {...{ filter, setUseFilter, setShowFilter, setPage }} />
      </Drawer>
      <Modal open={modalOpen} handleClose={() => setModalOpen(false)}>
        <RiskRegisterAddForm mode={"Add"} handleClose={() => setModalOpen(false)} />
      </Modal>
    </>
  );
};