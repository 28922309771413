import { useParams } from 'react-router';
import { useState, useEffect } from 'react';

import CommonContainer from 'EDMS/CommonContainer';

import edmsRegisterHelper from 'helpers/edmsRegisterHelper';
import { edmsFileHelper, permissionHelper, setupHelper, documentHelper } from 'helpers';
import FormContainer from './FormContainer';
import arrayHelper from 'utils/arrayHelper';
import docTypeHelper from 'helpers/admin/docTypeHelper';
import PDFViewer from 'Common/PDFViewer';

export default function EditDocumentForm() {
  const { contractId, id } = useParams();
  const [fileOptions, setFileOptions] = useState([]);
  const [personOptions, setPersonOptions] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [docTypeOptions, setDocTypeOptions] = useState([]);
  const [ready, setReady] = useState(false);
  const [userNGroup, setUserNGroup] = useState([]);
  const [userList, setUserList] = useState([]);
  const [userStringList, setUserStringList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [letterRefList, setLetterRefList] = useState([]);
  const [data, setData] = useState({});
  const [pdf, setPdf] = useState();
  const pdfId = data?.documents?.[data.documents.length - 1]?.documentId;

  useEffect(() => {
    async function getUserGroupInfo() {
      try {
        const result = await Promise.all([
          permissionHelper.getUsers({ orderBy: "firstName.asc" }),
          permissionHelper.getGroups({ orderBy: "firstName.asc" }),
        ]);
        const uList = result[0].user.map(v => ({ id: v.id, type: "User", label: `${v.firstName} ${v.lastName}` }));
        const uListString = result[0].user.map(v => `${v.firstName} ${v.lastName}`);
        const gList = result[1].group.map(v => ({ id: v.id, type: "Group", label: v.name }));
        setUserList(uList);
        setUserStringList(uListString);
        setGroupList(gList);
        const combinedArr = uList.concat(gList);
        setUserNGroup(combinedArr);
      } catch (e) {
        console.log(e);
      }
    }
    async function init() {
      try {
        const result = await Promise.all([
          edmsFileHelper.getFileMeta(id),
          // edmsRegisterHelper.getUnassignedDocuments({ contractId, inOut: direction, id: id }),
          edmsRegisterHelper.getAllRootFolders({ contractId, orderBy: "code.asc" }),
          setupHelper.getPersons({ contractId, orderBy: "name.asc" }),
          setupHelper.getCompanies({ contractId, orderBy: "name.asc" }),
          docTypeHelper.getdocTypes({ orderBy: "name.asc" }),
          edmsRegisterHelper.getLetterRef({ contractId, orderBy: "letterRef.asc" }),
        ]);
        if (result[0].error) throw new Error(result[0].error);
        setData(result[0]);
        if (result[1].error) throw new Error(result[1].error);
        const options = arrayHelper.shapeFileOptions(result[1].file, null, "dueDays");
        setFileOptions(options)
        if (result[2].error) throw new Error(result[2].error);
        setPersonOptions(arrayHelper.shapeOptions(result[2].person, "name", "name"));
        if (result[3].error) throw new Error(result[3].error);
        setCompanyOptions(arrayHelper.shapeOptions(result[3].company, "name", "name"));
        if (result[4].error) throw new Error(result[4].error);
        setDocTypeOptions(arrayHelper.shapeOptions(result[4].edms_document_type, "name", "name"));
        if (result[5].error) throw new Error(result[5].error);
        setLetterRefList(arrayHelper.shapeOptions(result[5].file_meta.filter((item => item.letterRef)), "letterRef", "letterRef"));
        setReady(true);
        document.title = `${result[0].logNumber} | ${result[0].letterRef}`
      } catch (e) {
        console.log(e);
      }
    }
    getUserGroupInfo();
    init();
  }, []);

  return (
    <CommonContainer>
      <div style={{ display: "flex" }}>
        <div style={{ width: "57%", position: "fixed", height: "95vh" }}>
          <PDFViewer id={pdfId} filename={data.logNumber} />
        </div>
        <div style={{ width: "60%" }}></div>
        <div style={{ width: "40%", color: "white" }}>
          {ready && <FormContainer
            defaultData={data}
            fileOptions={fileOptions}
            companyOptions={companyOptions}
            personOptions={personOptions}
            docTypeOptions={docTypeOptions}
            userNGroup={userNGroup}
            userList={userList}
            userStringList={userStringList}
            groupList={groupList}
            letterRefList={letterRefList}
          />}
        </div>
      </div>
    </CommonContainer >
  );
}