import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";

import { useStyles } from "./style";

function CustTextField({
  handleChange,
  label,
  props,
  value,
  field,
  icon,
  type,
  step,
  inputProps,
  disabled = false,
  hideLabel = false,
  placeholder,
  defaultValue,
  prefix,
  rows,
  trailingText,
  onKeyDown,
  onBlur,
}) {
  const classes = useStyles();
  function onChange(e) {
    field.onChange(e.target.value);
    if (handleChange) handleChange(e.target.value);
  }
  if (field) {
    return (
      <>
        {hideLabel ? null : <div className={classes.label}>{label}</div>}
        <div style={{ display: "flex" }}>
          {prefix && <span style={{ display: "flex", alignItems: "center", color: "rgb(112, 186, 211)" }}>{prefix}</span>}
          <TextField
            placeholder={placeholder}
            type={type}
            onChange={onChange}
            className={classes.textField}
            inputProps={inputProps}
            value={field.value}
            disabled={disabled}
            rows={rows}
            multiline={rows > 1}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
          />
          {trailingText && <div style={{ margin: "auto" }}>{trailingText}</div>}
          {icon && <div>{icon}</div>}
        </div>
      </>)
  }
  return (
    <>
      {hideLabel ? null : <div className={classes.label}>{label}</div>}
      <div style={{ display: "flex" }}>
        <TextField
          type={type}
          onChange={handleChange}
          className={classes.textField}
          value={value}
          disabled={disabled}
          rows={rows}
          multiline={rows > 1}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          {...props}
        // {...field}
        />
        {trailingText && <div style={{ margin: "auto" }}>{trailingText}</div>}
        <div>{icon ?? null}</div>
      </div>
    </>
  );
}

CustTextField.propTypes = {
  label: PropTypes.string,
  handleChange: PropTypes.func,
  // defaultValue: PropTypes.string
};

export default CustTextField;