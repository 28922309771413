import { useParams } from 'react-router';
import { useState, useEffect } from 'react';

import edmsFileHelper from 'helpers/edmsFileHelper';

import EDMSContainer from '../CommonContainer';
import CommonDocumentList from 'EDMS/CommonDocumentList';
import SectionTitle from 'Common/SectionTitle';

export default function EDMSFolder() {
  const { folderId, code } = useParams();
  const [useFilter, setUseFilter] = useState(true);
  const [filter, setFilter] = useState({ fileId: folderId });
  const [page, setPage] = useState(1);
  const [folderInfo, setFolderInfo] = useState();

  useEffect(() => {
    getDirectory();
  }, [folderId])

  async function getDirectory() {
    try {
      const variable = { fileId: folderId }
      const result = await edmsFileHelper.getFolderFiles(folderId)
      setFolderInfo(result);
      setFilter(variable);
    } catch (e) {
      console.log(e);
    }
  }

  function renderTitle() {
    if (folderInfo?.parentId === null) {
      return "Document Library";
    }
    else return `${folderInfo?.code ?? ""} ${folderInfo?.name ?? ""}`
  }

  return (
    <EDMSContainer>
      <SectionTitle title={renderTitle()} />
      <CommonDocumentList
        tableType={"edms"}
        useFilter={useFilter}
        filter={filter}
        page={page}
        getDataApi={edmsFileHelper.getFileMetas}
        defaultOrderBy="letterDate"
        responseKey={"meta_data"}
      />
    </EDMSContainer>
  );
}