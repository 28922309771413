export const reportConfig = [
  {
    id: "filePath",
    label: "Path",
    render: (folder, row) => {
      let filePath = "";
      if (folder.filePath) {
        folder.filePath.forEach((item, idx) => {
          if (idx === 0) {
            filePath = filePath + item.code;
            return;
          };
          filePath = `${filePath} / ${item.code} ${item.name}`;
        });
      }
      return filePath;
    },
    size: "40%",
  },
  {
    id: "type",
    label: "Group / User",
    render: (folder, row) => {
      return row.groupId ? "Group" : "User";
    },
    size: "15%",
  },
  {
    label: "Name",
    render: (folder, row) => {
      return row.group || row.username;
    },
  },
  {
    label: "Add",
    render: (folder, row) => {
      if (row.permissions) return row.permissions.find(o => o.code === 'add') ? "Y" : "";
    },
    size: "10%",
  },
  {
    label: "Edit",
    render: (folder, row) => {
      if (row.permissions) return row.permissions.find(o => o.code === 'edit') ? "Y" : "";
    },
    size: "10%",
  },
  {
    label: "View",
    render: (folder, row) => {
      if (row.permissions) return row.permissions.find(o => o.code === 'view') ? "Y" : "";
    },
    size: "10%",
  },
];