import { useEffect, useState, Fragment } from "react";
import { useFieldArray, Controller } from "react-hook-form";
import clsx from "clsx";
import { ErrorMessage } from '@hookform/error-message';
import { Grid, Divider, IconButton, TextField, Checkbox } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

import { permissionHelper } from "helpers";
import { useStyles } from "./style";

export default function PermissionField({ control, register, setValue, getValues, name, errors, defaultValue }) {
  const classes = useStyles();
  const [userNGroup, setUserNGroup] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const { fields, remove, append } = useFieldArray({
    control,
    name: `${name}`,
    keyName: "_id",
  });

  useEffect(() => {
    async function getPermissionsInfo() {
      try {
        const result = await Promise.all([
          permissionHelper.getPermissions(),
          permissionHelper.getUsers({ orderBy: "firstName.asc" }),
          permissionHelper.getGroups({ orderBy: "firstName.asc" }),
        ]);

        setPermissions(result[0].permission.filter(permission => permission.type === "file"));
        const combinedArr = result[1].user.map(v => ({ id: v.id, type: "User", label: `${v.firstName} ${v.lastName}` }))
          .concat(result[2].group.map(v => ({ id: v.id, type: "Group", label: v.name })));
        setUserNGroup(combinedArr);
      } catch (e) {
        console.log(e);
      }
    }
    getPermissionsInfo();
  }, []);

  function onChange(e, newValue, field, name) {
    if (!newValue) {
      setValue(`${name}`, null)
    } else {
      setValue(`${name}`, newValue)
    }
  }

  function onChangeCheckbox(e, newValue, field, name, permission) {
    try {
      let value = field.value ?? [];
      if (!newValue) {
        value = value.filter(p => p.toString() !== permission.id.toString());
        setValue(name, value);
      } else {
        value.push(permission.id);
        setValue(name, value);
      }
    } catch (e) {
      console.log(e);
    }
  }

  return (
    userNGroup?.length &&
    <div style={{ width: '100%', marginTop: 12 }}>
      <Grid container item xs={12}>
        <Grid item xs={12} className={classes.label}>Permissions</Grid>
        <Grid item xs={4} sm={2} className={clsx(classes.label, classes.rowBetween)}>User / Group</Grid>
        <Grid item xs={8} sm={4} className={classes.rowBetween}>
          <div className={clsx(classes.label, classes.marginLeft)}>Permissions</div>
          <IconButton
            color="default"
            component="span"
            onClick={() => {
              append({ permissions: [], target: undefined });
            }}>
            <AddCircleRoundedIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12} sm={6}></Grid>
        <Grid item xs={12} sm={6}><Divider className={classes.divider} /></Grid>
        <Grid item xs={12} sm={6}></Grid>

        {fields.length > 0 &&
          fields.map((item, i) => {
            return (
              <Fragment key={item._id}>
                <Grid item xs={4} sm={2}>
                  <Controller
                    name={`${name}[${i}].target`}
                    control={control}
                    rules={{ required: `User / Group is Required` }}
                    defaultValue={defaultValue && defaultValue[i] ? defaultValue[i].target : undefined}
                    render={({ field }) =>
                      <Autocomplete
                        id="combo-box-demo"
                        defaultValue={(defaultValue && defaultValue[i]) ? defaultValue[i].target : undefined}
                        options={userNGroup ?? []}
                        groupBy={(option) => option.type}
                        getOptionLabel={(option) => option.label}
                        getOptionSelected={(option, value) => option.userName === value.userName}
                        onChange={(e, newValue) => onChange(e, newValue, field, `${name}[${i}].target`)}
                        renderInput={(params) =>
                          <div style={{ display: "flex" }}>
                            <TextField
                              {...params}
                              className={classes.textField}
                            />
                          </div>}
                      />}
                  />
                  <ErrorMessage
                    errors={errors}
                    name={`${name}[${i}].target`}
                    render={({ message }) => <p style={{ color: 'red' }}>{message}</p>}
                  />
                </Grid>
                <Grid item xs={8} sm={4} className={classes.rowBetween}>
                  <div className={clsx(classes.label, classes.marginLeft, classes.checkboxSection)}>
                    <Controller
                      rules={{}}
                      name={`${name}[${i}].permissions`}
                      control={control}
                      defaultValue={defaultValue && defaultValue[i] ? defaultValue[i].permissions : undefined}
                      render={({ field }) =>
                        permissions.map((permission, j) =>
                          <div className={classes.checkboxGroup} key={j}>
                            <Checkbox
                              {...field}
                              defaultChecked={defaultValue && defaultValue[i] ? defaultValue[i].permissions.includes(permission.id) : undefined}
                              onChange={(e, newValue) => onChangeCheckbox(e, newValue, field, `${name}[${i}].permissions`, permission)}
                            />
                            <div className={classes.checkboxName}>{permission.name}</div>
                          </div>
                        )
                      }
                    />
                  </div>
                  <div style={{ height: "100%" }}>
                    <IconButton
                      color="default"
                      component="span"
                      onClick={() => {
                        remove(i);
                      }}>
                      <RemoveCircleOutlineIcon />
                    </IconButton>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}></Grid>
                <Grid item xs={12} sm={6}><Divider className={classes.divider} /></Grid>
                <Grid item xs={12} sm={6}></Grid>
              </Fragment>
            )
          })}

      </Grid>

    </div >
  );
};
