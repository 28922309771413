import { Fragment, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Divider, useTheme, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import moment from "moment";

import SectionTitle from "Common/SectionTitle";
import InfoItem from "Common/InfoItem";
import decryptHelper from "utils/decryptHelper";
import RoundedButton from "Common/RoundedButton";

import { useStyles } from "Document/DocumentDecrypted/style";

export default function InboxInfoDetails({ data, permission, pdf }) {
  const { contractId } = useParams();
  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();
  const haveEditRight = permission?.permissions && permission.permissions.find(item => item.code === "edit");
  const [open, setOpen] = useState(false);

  function renderContent(record, row) {
    if (!record && typeof record !== "boolean") return "-"
    if (row.render) return row.render(record);
    if (row.type === "Boolean") if (record) return "Yes"; else return "No";
    if (row.type === "BooleanReverse") if (record) return "No"; else return "Yes";
    if (row.type === "Datetime") return moment(record).format('yyyy-MM-DD HH:mm');
    if (row.type === "Date") return moment(record).format('yyyy-MM-DD');
    if (typeof (record) === typeof ([])) {
      return record.join(",");
    }
    return record;
  }
  function goToEdit(e) {
    e.stopPropagation();
    if (!data.inOut) return;
    history.push(`/contract/${contractId}/edms/register-document/${data?.inOut.toLowerCase()}/update/${data.id}`)
  }
  async function savePdf(e) {
    e.stopPropagation();
    const link = document.createElement('a')
    link.href = pdf;
    link.download = `${data.logNumber}.pdf`;
    link.click()
    link.remove();
  }

  return (
    data && <Accordion expanded={open} className={classes.details}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="details-content"
        id="details-header"
        onClick={() => setOpen(!open)}
        classes={{
          content: classes.spaceBetween
        }}
      >
        <SectionTitle title={"Details"} fontStyle={theme.typography.h4} />
        <div>
          {haveEditRight && <RoundedButton width={100} backgroundColor={theme.palette.primaryLight} onClick={goToEdit}>Edit</RoundedButton>}
          {(pdf) &&
            <RoundedButton width={100} margin="8px 8px" backgroundColor={theme.palette.primaryLight} onClick={savePdf}>
              Save PDF
            </RoundedButton>
          }
        </div>
      </AccordionSummary>
      <AccordionDetails style={{ flexDirection: "column" }}>
        {decryptHelper.selectdecryptedRows(data.inOut === "IN" ? "incomingEdms" : "outgoingEdms").map((row, idx) =>
          <Fragment key={idx}>
            <InfoItem title={row.name} content={renderContent(data[row.key], row)} flexDirection={"row"} />
            <Divider className={classes.divider} />
          </Fragment>
        )}
      </AccordionDetails>
    </Accordion>
  );
}