import DraftsIcon from "@material-ui/icons/Drafts";
import MarkunreadIcon from "@material-ui/icons/Markunread";
import DeleteIcon from "@material-ui/icons/Delete";
import { IconButton } from "@material-ui/core";
import { authHelper } from "helpers";
import tableHelper from "utils/tableHelper";

export function actionHistoryConfig(handleDelete, type) {
  return [
    {
      id: "seen", label: "", type: "Read",
      render: (item) => {
        return item.seen ? <DraftsIcon /> : <MarkunreadIcon />
      },
    },
    {
      label: "Assigned To",
      id: "recipient",
      render: (item) => {
        return tableHelper.renderAssignedToCell(item);
      }
    },
    {
      label: "Requested By",
      id: "fromUser"
    },
    {
      label: "Type",
      id: "for"
    },
    {
      label: "Message",
      id: "content"
    },
    {
      label: "Requested At",
      id: "createdAt",
      type: "Datetime"
    },
    {
      label: "",
      id: "actionRequestMessageId",
      render: (item) => {
        const userId = authHelper.getMyUserId();
        if (userId === item.sentByUserId) {
          return <IconButton onClick={() => handleDelete(item.actionRequestMessageId)}><DeleteIcon /></IconButton>
        }
        return <></>;
      }
    },
  ]
};