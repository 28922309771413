import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import moment from 'moment/moment';
import { useState, useEffect } from 'react';

import EllipseButton from 'Common/EllipseButton';
import SectionTitle from 'Common/SectionTitle';
import { setNotification } from 'redux/slice/notificationSlice';

import CommonContainer from 'EDMS/CommonContainer';
import { useStyles } from "./style";
import CommonDocumentList from 'EDMS/CommonDocumentList';
import { edmsFileHelper, edmsRegisterHelper } from 'helpers';

export default function ViewDocumentList() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { direction, contractId } = useParams();
  const [isLoading, setLoading] = useState();
  const [filter, setFilter] = useState({ inOut: direction });
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    setFilter({ inOut: direction })
  }, [direction]);

  async function getExcel() {
    try {
      setLoading(true);
      const result = await edmsRegisterHelper.exportDocExcel({ contractId, inOut: direction, orderBy: "logNumber.desc" });
      if (result.data?.error) throw new Error(result.data.error.message);
      let url = URL.createObjectURL(result.data);
      const link = document.createElement('a')
      link.href = url;
      link.download = `View_Document_${direction}_${moment().format('YYYYMMDDHHmm')}.xlsx`
      link.click()
      link.remove();
      setLoading(false);
      // window.open(url, '_blank', 'noopener noreferrer');
    } catch (e) {
      setLoading(false);
      dispatch(setNotification({ type: "error", message: e.message }))
    }
  }

  return (
    isMounted &&
    <CommonContainer loading={isLoading}>
      <SectionTitle
        title={`View ${direction === "in" ? "Incoming" : "Outgoing"} Document`}
        extraComponents={
          <div className={classes.buttonsContainer}>
            {<div style={{ marginRight: 8 }}> <EllipseButton onClick={getExcel} label="Export" /></div>}
          </div>
        }
      />
      <CommonDocumentList
        tableType={direction === "in" ? "viewDocumentInList" : "viewDocumentOutList"}
        getDataApi={edmsFileHelper.getFileMetas}
        responseKey="meta_data"
        filter={filter}
        useFilter
      // handleAction={(e) => console.log(e)}
      // actionIcon={<EditIcon fontSize="default" />}
      />
    </CommonContainer>
  );
}