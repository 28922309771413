import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import { Drawer } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useSelector } from 'react-redux';

import { selectContractData } from 'redux/slice/contractSlice';
import { authHelper, photoHelper, documentsHelper } from 'helpers';
import tableHelper from 'utils/tableHelper';

import FilterPage from "Common/FilterPage";
import SectionTitle from 'Common/SectionTitle';
import PhotoList from 'Photos/PhotoList';
import Button from "Common/EllipseButton";
import IconButton from "Common/IconButton";
import Pagination from "Common/Pagination";
import Loading from 'Common/Loading';
import Modal from "Common/Modal";
import Select from "Common/Select";
import PhotoAdd from 'Photos/PhotoAdd';
import { photoConfig, sortDirectionOptions } from './config';
import { useStyles } from "./style";

export default function PhotoGallery({ selectedData, handleSelect, isSelect, externalContractId, contractNo }) {
  const contractData = useSelector(selectContractData);
  const { contractId } = useParams();
  const theme = useTheme();
  const classes = useStyles(theme);
  const noFilter = {}

  const [photos, setPhotos] = useState([]);
  const [photoData, setPhotoData] = useState([]);

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(12);
  const [count, setCount] = useState(12);
  const [showFilter, setShowFilter] = useState(false);
  const [useFilter, setUseFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const [sort, setSort] = useState({
    orderBy: "takenDatetime",
    sortDirection: "desc"
  })
  const [isLoading, setIsLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [isRestricted, setIsRestricted] = useState();
  const maxPage = () => {
    return Math.ceil(count / 12);
  }

  useEffect(() => {
    try {
      let isMounted = true;
      if (isMounted) {
        setIsLoading(true);
        if (useFilter) getPageData(page, size, filter, sort);
        else getPageData(page, size, noFilter, sort);
      }
      return () => { isMounted = false };
    } catch (e) {
      console.log(e);
      setIsLoading(false)
    }
  }, [useFilter, filter, sort, page])

  useEffect(() => {
    async function getPhotosInfo() {
      let photoDocIdArr = photos.map(photo => photo.photoDocument[0] ? photoHelper.getPhotoThumbnail(photo.photoDocument[0].id, 200) : null)
      const result = await Promise.all(photoDocIdArr);
      setPhotoData(result);
      setIsLoading(false);
    }
    try {
      getPhotosInfo();
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  }, [photos]);

  async function getPageData(cPage, cSize, cFilter, cSort) {
    try {
      let filterVariable = { ...cFilter };
      filterVariable.page = cPage;
      filterVariable.items = cSize;
      filterVariable.orderBy = `${cSort.orderBy}.${cSort.sortDirection}`
      filterVariable.contractId = externalContractId ?? contractId;

      const filterKeys = Object.keys(cFilter);
      filterKeys.forEach((key) => {
        if (Array.isArray(cFilter[key])) {
          filterVariable[key] = tableHelper.handleDateTimeFilter(filterVariable[key])
        }
      })

      if (!isSelect) filterVariable.contractId = contractId;
      const result = await documentsHelper.getDocuments({ type: "photos", variable: filterVariable });
      if (result.error) throw new Error(result.error);
      if (!result.photo) throw new Error({ error: "photo data not found" });
      setPhotos(result.photo);
      setIsRestricted(null);
      setCount(result.count);
      return result;
    } catch (e) {
      if (e.message) setIsRestricted(e.message);
      setIsLoading(false);
      console.log(e);
    }
  };

  function handleAddForm() {
    setModalOpen(true);
  };

  const handleOpenFilter = () => {
    setShowFilter(!showFilter);
  };

  async function handlePageChange(_, target) {
    setIsLoading(true);
    setPage(target);
    setIsLoading(false);
  };

  function handleFilterChange(e) {
    setPage(1);
    setFilter(e);
  }

  function handleOrderByChange(e) {
    setSort({ ...sort, orderBy: e.target.value });
  }
  function handleSortDirectionChange(e) {
    setSort({ ...sort, sortDirection: e.target.value });
  }

  function reload() {
    if (useFilter) getPageData(page, size, filter, sort);
    else getPageData(page, size, noFilter, sort);
  }

  function resetFilter() {
    setFilter({})
  }

  return (
    <div className={classes.root}>
      <div className={classes.body}>
        <Loading isLoading={isLoading} />
        <SectionTitle title={handleSelect ? "" : "Site Progress Photo"}
          extraComponents={
            <div className={classes.buttonsContainer}>
              <Select
                handleChange={handleOrderByChange}
                options={photoConfig(contractData.contractNo ?? contractNo).filter(field => !!field.value)}
                value={sort.orderBy}
                label={"Order by"}
                dense
              />
              <div style={{ marginRight: "16px" }}>
                <Select
                  handleChange={handleSortDirectionChange}
                  options={sortDirectionOptions}
                  value={sort.sortDirection}
                  label={"Sort"}
                  dense
                />
              </div>
              <div style={{ marginRight: "16px" }}>
                {handleSelect || !authHelper.checkPermission()
                  ? <div></div>
                  : <Button
                    label="Add Photo"
                    startIcon={<AddIcon />}
                    onClick={handleAddForm}
                  />
                }
              </div>
              <IconButton
                icon={<FilterListIcon />}
                onClick={handleOpenFilter}
              />

            </div>
          }
        />
        <div style={{ marginTop: "16px" }}>
          {tableHelper.renderFilterBy(filter, photoConfig(contractData.contractNo ?? contractNo), resetFilter)}
        </div>
        {isRestricted ??
          <>
            <PhotoList data={photos ?? []} photos={photoData} selectedData={selectedData} handleSelect={handleSelect} />
            {photos.length === 0 && <div className={classes.noResult}>No result found</div>}
          </>
        }

        {photos.length > 0 && <Pagination count={maxPage()} page={page} onChange={handlePageChange} />}
        <Drawer
          anchor="right"
          open={showFilter}
          onClose={() => setShowFilter(false)}
          classes={{
            paperAnchorRight: classes.drawer
          }}
        >
          <FilterPage fieldsData={photoConfig(contractData.contractNo ?? contractNo)} setFilter={handleFilterChange} {...{ filter, setUseFilter, setShowFilter }} />
        </Drawer>
        <Modal open={modalOpen} handleClose={() => setModalOpen(false)}>
          <PhotoAdd handleClose={() => setModalOpen(false)} contract={contractData} reload={reload} />
        </Modal>
      </div>
    </div>
  );
};