import { useTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import { useStyles } from "./style";

export default function InfoItem({ title, content, flexDirection = "column" }) {
  const theme = useTheme();
  const props = { theme, flexDirection };
  const classes = useStyles(props);
  if (title === "") {
    return <Grid className={classes.infoItem} style={{ height: 42 }}></Grid>
  }
  if (flexDirection === "row") {
    return (
      <Grid className={classes.infoItem}>
        <Grid xs={5} item className={classes.rowHeaderText}>{title}</Grid>
        <Grid xs={7} item className={classes.contentText}>{content}</Grid>
      </Grid>
    );
  }
  return (
    <div className={classes.infoItem}>
      <div className={classes.headerText}>{title}</div>
      <div className={classes.contentText}>{content}</div>
    </div>
  );
}

InfoItem.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.any,
  flexDirection: PropTypes.string,
};