import pdfHelper from "utils/pdfHelper";

const linkStyle = { cursor: "pointer", textDecoration: "underline", whiteSpace: "nowrap" };
const refStyle = { cursor: "pointer", textDecoration: "underline", wordBreak: "break-word" };

export const booleanOptions = [
  {
    label: "Y",
    value: true
  },
  {
    label: "N",
    value: false
  }
]

export const letterRefFieldConfig = [
  {
    label: "Letter Reference",
    id: "letterRef",
    type: "AutoComplete",
    xs: 4,
    isInput: true,
    render: (item) => {
      if (!item.documentId) return item.letterRef;
      const docId = item.documentId;
      const noPermission = !docId && item?.view === false;
      return <div style={refStyle} onClick={() => pdfHelper.openDocInBlankPage(noPermission ? false : docId)}>{item.letterRef}</div>;
    }
  },
  {
    label: "Log No.",
    id: "logNumber",
    xs: 2,
    lookupKey: "letterRef",
  },
  {
    label: "File No.",
    id: "fileNumber",
    xs: 2,
    lookupKey: "letterRef",
  },
  {
    label: "Letter Date",
    id: "letterDate",
    xs: 2,
    lookupKey: "letterRef",
  }
]